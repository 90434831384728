import { SERVICE_NAME } from '../../../constants'
import { IAnnouncement } from '../../../models/announcements/Announcement.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { currentAnnouncementsFragment } from '../fragments'

export type ICurrentAnnouncementsResponse = IGenericResponse<'currentAnnouncements', IAnnouncement[]>

export async function currentAnnouncements(
  fragment: string = currentAnnouncementsFragment
): Promise<ICurrentAnnouncementsResponse> {
  const query = `#graphql
    query currentAnnouncements {
      currentAnnouncements {
        ${fragment}
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query) as Promise<ICurrentAnnouncementsResponse>
}
