import { SERVICE_NAME } from '../../constants'
import { IChannelBasic } from '../../models/assets/ChannelBasic.model'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'
import { assignGenresToChannelFragment } from './fragments'

type IAssignGenresToChannelProps = {
  genreUuids: string[]
  channelUuid: string
}

export type IAssignGenresToChannelResponse = IGenericResponse<'assignGenresToChannel', IChannelBasic>

export async function assignGenresToChannel(
  variables: IAssignGenresToChannelProps,
  fragment: string = assignGenresToChannelFragment
): Promise<IAssignGenresToChannelResponse> {
  const query = `
    mutation assignGenresToChannel($genreUuids:	[String!]!, $channelUuid: String!) {
      assignGenresToChannel(genreUuids: $genreUuids, channelUuid: $channelUuid) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IAssignGenresToChannelResponse>
}
