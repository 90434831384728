export enum GA_EVENT_CATEGORY {
  AUTH = 'AUTH',
  USE_FILTER = 'USE_FILTER',
  DOWNLOAD = 'DOWNLOAD',
  RELEASE_CLAIM = 'RELEASE_CLAIM',
  GO_TO_EPIDEMIC = 'GO_TO_EPIDEMIC',
  ERROR_API = 'ERROR_API',
  TABLE_INTERACTION = 'TABLE_INTERACTION',
  DISPLAY_VERSION_MODAL = 'DISPLAY_VERSION_MODAL',
}

export enum GA_EVENT_ACTIONS {
  // AUTH
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SESSION_EXPIRED = 'SESSION_EXPIRED',

  // DOWNLOAD
  DOWNLOAD_CSV = 'DOWNLOAD_CSV',
  DOWNLOAD_LOCAL_CSV = 'DOWNLOAD_LOCAL_CSV',
  DOWNLOAD_PDF = 'DOWNLOAD_CSV',
  DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',

  // TABLE
  SHOW_ADVANCE = 'SHOW_ADVANCE',
  EXPAND = 'EXPAND',
  SORT = 'SORT',

  SHOW = 'SHOW',
}
