import React from 'react'

import { SvgIconProps } from './iconProps'

const IconBankTransfer: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_214_2025)" className="ic-bank">
      <g className="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0.401642L23.5 5.62593V10.14H0.5V5.62593L12 0.401642ZM2.5 6.91406V8.13999H21.5V6.91406L12 2.59834L2.5 6.91406Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 17.68H23.5V23.5H0.5V17.68ZM2.5 19.68V21.5H21.5V19.68H2.5Z"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.41 8.13999V19.68H2.41V8.13999H4.41Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.14 8.13999V19.68H8.14V8.13999H10.14Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.86 8.13999V19.68H13.86V8.13999H15.86Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.59 8.13999V19.68H19.59V8.13999H21.59Z" />
      </g>
    </g>
    <defs>
      <clipPath className="clip0_214_2025">
        <rect width={size} height={size} fill={fill} />
      </clipPath>
    </defs>
  </svg>
)

export default IconBankTransfer
