import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IDeleteTrackProps {
  uuid: string
}

export type IDeleteTrackResponse = IGenericResponse<'deleteTrack', boolean>

export async function deleteTrack(props: IDeleteTrackProps): Promise<IDeleteTrackResponse> {
  const query = `
    mutation deleteTrack ($uuid:String!) {
      deleteTrack (uuid: $uuid)  
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IDeleteTrackResponse>
}
