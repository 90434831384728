export type IAudioFormEntries = {
  name: 'title' | 'artist' | 'album' | 'isrc' | 'rightsHolders' | 'upc' | 'customId'
  label: string
  defaultValue?: string
}[]

export const audioFormEntries: IAudioFormEntries = [
  { name: 'title', label: 'Title (required)' },
  { name: 'artist', label: 'Artist (required)' },
  { name: 'album', label: 'Album' },
  { name: 'isrc', label: 'ISRC' },
  { name: 'upc', label: 'UPC' },
  { name: 'rightsHolders', label: 'Record Label / Rights Holders' },
  { name: 'customId', label: 'Custom ID' },
]

export type IVideoFormEntries = {
  name: 'title' | 'description'
  label: string
  defaultValue?: string
}[]

export const videoFormEntries: IVideoFormEntries = [
  { name: 'title', label: 'Title (required)' },
  { name: 'description', label: 'Description' },
]

export const TABS_OPTIONS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}

export const MANAGE_ASSETS_TABS = [
  {
    eventKey: TABS_OPTIONS.PENDING,
    title: 'Pending Assets',
  },
  {
    eventKey: TABS_OPTIONS.APPROVED,
    title: 'Approved Assets',
  },
  {
    eventKey: TABS_OPTIONS.REJECTED,
    title: 'Rejected Assets',
  },
]

export const RELEASE_FORM_TABS = {
  RELEASE_INFO: 'INFO',
  TRACKS: 'TRACKS',
  DISTRIBUTION_TARGETS: 'TARGET PLATFORMS',
  CONFIRMATION: 'CONFIRMATION',
}

export const RELEASE_FORM_TABS_ORDER = [
  RELEASE_FORM_TABS.RELEASE_INFO,
  RELEASE_FORM_TABS.TRACKS,
  RELEASE_FORM_TABS.DISTRIBUTION_TARGETS,
  RELEASE_FORM_TABS.CONFIRMATION,
]

export type TAutosaveStatusesType = 'IDLE' | 'Saving...' | 'Saved'

type TAutosaveStatusesTypeType = {
  [key: string]: TAutosaveStatusesType
}

export const AUTOSAVE_STATUSES: TAutosaveStatusesTypeType = {
  IDLE: 'IDLE',
  SAVING: 'Saving...',
  SAVED: 'Saved',
}

export const ASSIGN_STEPS = {
  SETUP: 'SETUP',
  USER_ASSIGN: 'USER_ASSIGN',
  SET_REV_SHARE: 'SET_REV_SHARE',
  PROCESSING: 'PROCESSING',
}
