import { Instance, types } from 'mobx-state-tree'

export const BankTransferDetails = types.model({
  name: types.string,
  accountNo: types.string,
  accountHolderAddress: types.maybeNull(types.string),
  bankName: types.maybeNull(types.string),
  bankAddress: types.maybeNull(types.string),
  routingNumber: types.maybeNull(types.string),
  sortSwift: types.maybeNull(types.string),
  intermediateBank: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  other: types.maybeNull(types.string),
})

export type IBankTransferDetails = Instance<typeof BankTransferDetails>
