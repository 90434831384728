import { SERVICE_NAME, USER_GROUP_TYPE } from '../../../constants'
import { IUserGroup } from '../../../models/users/UserGroup.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ICreateUserGroupResponse = IGenericResponse<'createUserGroup', IUserGroup>

export type ICreateUserGroupProps = {
  name: string
  type: USER_GROUP_TYPE
}

export async function createUserGroup(props: ICreateUserGroupProps): Promise<ICreateUserGroupResponse> {
  const query = `
    mutation ($name: String!, $type: String!) {
      createUserGroup (name: $name, type: $type) {
        uuid
        name
        type
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<ICreateUserGroupResponse>
}
