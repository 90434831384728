import { SERVICE_NAME } from '../../../constants'
import { IAnnouncement } from '../../../models/announcements/Announcement.model'
import { IPaginationParams } from '../../../types'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { announcementsFragment } from '../fragments'

export type IAnnouncementResponse = IGenericResponse<'announcements', { total: number; announcements: IAnnouncement[] }>

// add filters

export type IAnnouncementProps = {
  pagination?: IPaginationParams
  filters?: {
    status?: string
    type?: string
    startDate?: string
    endDate?: string
    search?: string
    siteUuid?: string
    isEnabled?: boolean
    currentAndUpcoming?: boolean
  }
}

export async function getAnnouncements(
  props: IAnnouncementProps,
  fragment: string = announcementsFragment
): Promise<IAnnouncementResponse> {
  const query = `#graphql
   query getAnnouncements($filters: AnnouncementsFilters, $pagination: PaginationInput) {
    announcements(filters: $filters, pagination: $pagination) {
        total
        ${fragment}
      }
    }
`

  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<IAnnouncementResponse>
}
