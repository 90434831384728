import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteRoleResponse = IGenericResponse<'deleteRole', boolean>

export async function deleteRole(roleUuid: string): Promise<IDeleteRoleResponse> {
  const query = `
    mutation deleteRole ($roleUuid: String!) {
      deleteRole(roleUuid: $roleUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, { roleUuid })) as IDeleteRoleResponse
}
