import { MICROLICENSING_AUDIO_TYPE, MICROLICENSING_KEY, MICROLICENSING_MOODS, SERVICE_NAME } from '../../../constants'
import { IArtTrackBasic } from '../../../models'
import { IMicrolicensingPropsConfigFile } from '../../../models/assets/MicrolicensingPropsConfigFile.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IEditTrackMetadataProps {
  trackUuid: string
  metadata: {
    moods?: MICROLICENSING_MOODS[] | null
    genres?: string[] | null
    audioType?: MICROLICENSING_AUDIO_TYPE | null
    bpm?: number | null
    key?: MICROLICENSING_KEY | null
    shortDescription?: string | null
    description?: string | null
    files: Pick<IMicrolicensingPropsConfigFile, 'filePath' | 'extension'>[] | null
  }
}

export type IEditTrackMetadataResponse = IGenericResponse<'editTrackMetadata', IArtTrackBasic>

export async function editTrackMetadata({
  trackUuid,
  metadata,
}: IEditTrackMetadataProps): Promise<IEditTrackMetadataResponse> {
  const query = `
    mutation EditTrackMetadata($metadata: MicrolicensingPropsInput!, $trackUuid: String!) {
      editTrackMetadata(metadata: $metadata, trackUuid: $trackUuid) {
        uuid
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { trackUuid, metadata }) as Promise<IEditTrackMetadataResponse>
}
