import { cast, destroy, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { createRole, ICreateRoleResponse } from '../../api/users-api/roles/createRole'
import { deleteRole, IDeleteRoleResponse } from '../../api/users-api/roles/deleteRole'
import { editRole, IEditRoleResponse } from '../../api/users-api/roles/editRole'
import { getRoles, IGetRolesResponse } from '../../api/users-api/roles/getRoles'
import { ISetPermissionsForRoleResponse, setPermissionsForRole } from '../../api/users-api/users/setPermissionsForRole'
import { SortDirection } from '../../constants'
import { Pagination } from '../general/Pagination.model'
import { IRole, Role } from './Role.model'

export const RoleList = types
  .model({
    list: types.array(Role),
  })
  .volatile(() => ({
    loading: true,
    pagination: Pagination.create({ totalItems: 1 }),
  }))
  .views(self => ({
    getRoleByUuid(uuid: string): IRole | undefined {
      return self.list.find(el => el.uuid === uuid)
    },
    getRoleByName(name: string): IRole | undefined {
      return self.list.find(el => el.name === name)
    },

    get filtered() {
      // sort

      const filteredRoles = self.list.filter(() => true)
      if (self.pagination.sort) {
        filteredRoles.sort((a, b) => {
          if (self.pagination.sort === 'name') {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
          }

          if (self.pagination.sort === 'isInternal') {
            if ((a.isInternal || false) < (b.isInternal || false)) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
            if ((a.isInternal || false) > (b.isInternal || false)) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
          }

          if (self.pagination.sort === 'usersInRole') {
            if (self.pagination.sortDirection === SortDirection.DESC) {
              return (a.usersInRole || 0) > (b.usersInRole || 0) ? -1 : 1
            }
            if (self.pagination.sortDirection === SortDirection.ASC) {
              return (a.usersInRole || 0) < (b.usersInRole || 0) ? -1 : 1
            }
          }

          if (self.pagination.sort === 'permissionsEnabled') {
            if (self.pagination.sortDirection === SortDirection.DESC) {
              return (a.permissions?.length || 0) > (b.permissions.length || 0) ? -1 : 1
            }
            if (self.pagination.sortDirection === SortDirection.ASC) {
              return (a.permissions?.length || 0) < (b.permissions.length || 0) ? -1 : 1
            }
          }

          return 0
        })
      }

      return filteredRoles
    },
  }))
  .actions(self => ({
    setRole(updatedRole: IRole) {
      const updatedList = self.list.map(role => {
        return role.uuid !== updatedRole.uuid ? role : updatedRole
      })

      self.list = cast(updatedList)
    },
    setRoles(roles: IRole[]) {
      self.list = cast(roles)
    },
    setLoading(val: boolean) {
      self.loading = val
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetRolesResponse = yield getRoles()

        if (resp && resp.data.data?.roles) {
          self.setRoles(resp.data.data.roles)
        }

        self.loading = false
      } catch (err) {
        self.loading = false
      }
    }),
    updateRoleNameDescription: flow(function* (
      roleUuid: string,
      name: string,
      description: string,
      isInternal: boolean
    ) {
      try {
        self.loading = true

        const resp: IEditRoleResponse = yield editRole({ roleUuid, name, description, isInternal })
        if (resp?.data.data?.editRole.uuid) {
          self.setRole(resp.data.data.editRole)
          toast.success('Role updated')
        }

        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
    updateRolePermissions: flow(function* (roleUuid: string, permissionNames: string[]) {
      const resp: ISetPermissionsForRoleResponse = yield setPermissionsForRole({ roleUuid, permissionNames })
      if (resp?.data.data?.setPermissionsForRole) {
        toast.success('Role set')
      }
    }),
    remove: (uuid: string) => {
      destroy(self.getRoleByUuid(uuid))
    },
    delete: flow(function* (uuid: string) {
      try {
        self.loading = true
        const resp: IDeleteRoleResponse = yield deleteRole(uuid)

        if (resp && resp.data.data?.deleteRole) {
          toast.success('Role successfully deleted')
          self.loading = false
          return true
        }

        self.loading = false
        return false
      } catch (err) {
        self.loading = false
        console.error(err)
        return false
      }
    }),
    create: flow(function* (name: string, description: string, isInternal: boolean) {
      try {
        self.loading = true

        const resp: ICreateRoleResponse = yield createRole({ name, description, isInternal })

        if (resp && resp.data.data?.createRole) {
          toast.success('Role successfully created')

          const updatedList = [...self.list, resp.data.data.createRole]
          self.list = cast(updatedList)

          self.loading = false
          return resp.data.data.createRole.uuid
        }

        self.loading = false
        return ''
      } catch (err) {
        self.loading = false
        console.error(err)
        return ''
      }
    }),
  }))

export type IRoleList = Instance<typeof RoleList>
