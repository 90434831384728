import axios from 'axios'
import ReactGA from 'react-ga4'
import { toast } from 'react-toastify'

// @epic-front/dashboards would be imported as an dependency otherwise
// eslint-disable-next-line import/no-extraneous-dependencies
import { router } from '@epic-front/dashboards/src/routes/Routes'
import {
  GA_EVENT_CATEGORY,
  GA_ID,
  GENERIC_ERROR_MESSAGE,
  MAX_RETRIES,
  SERVICE_NAME,
  SITE_TOKEN,
  TIME_BETWEEN_RETRIES,
  URL_ASSETS_API,
  URL_LOGS_API,
  URL_PAYMENTS_API,
  URL_USERS_API,
} from '../constants'
import { getExpiryDate, getLocalToken } from '../helpers'
import { IGenericResponse } from './api.types'

const GQL_ERROR = 'gql error'

async function clientWrapper(
  api: SERVICE_NAME,
  query: string,
  variables?: unknown,
  retryNr?: number
): Promise<IGenericResponse<string, unknown> | unknown> {
  let retriesSoFar = retryNr || 0
  let hasGqlError = false
  let errorMessagesToDisplay = '' // will concatenate
  let toastErrorMessage = ''
  let cancelAnyRetry = false
  let url = ''

  try {
    // check env variable

    const token = getLocalToken()
    const expiryDate = getExpiryDate()

    if (token && expiryDate && expiryDate < Date.now()) {
      if (window.location.pathname !== '/account/session-expired') {
        localStorage.setItem('from', window.location.pathname)
      }

      router.navigate('/account/session-expired')
      return undefined
    }

    if (URL_ASSETS_API && api === SERVICE_NAME.ASSETS) {
      url = URL_ASSETS_API
    }
    if (URL_USERS_API && api === SERVICE_NAME.USERS) {
      url = URL_USERS_API
    }
    if (URL_PAYMENTS_API && api === SERVICE_NAME.PAYMENTS) {
      url = URL_PAYMENTS_API
    }
    if (URL_LOGS_API && api === SERVICE_NAME.LOGS) {
      url = URL_LOGS_API
    }

    const resp = await axios({
      url: `${url}/graphql`,
      method: 'post',
      headers: {
        sitetoken: SITE_TOKEN || '',
        authorization: token || undefined,
      },
      data: {
        query,
        variables,
      },
    })

    /** Generic error handler */
    resp.data.errors?.forEach((err: { message: string }) => {
      console.error(err.message)

      if (err.message.indexOf('SongMateLoginOnlyException') !== -1) {
        router.navigate('/account/songmate-account-warning')
        cancelAnyRetry = true
        hasGqlError = true
        throw new Error(GQL_ERROR)
      }

      if (err.message.indexOf('Expired token') !== -1) {
        if (window.location.pathname !== '/account/session-expired') {
          localStorage.setItem('from', window.location.pathname)
        }
        router.navigate('/account/session-expired')
        cancelAnyRetry = true
        hasGqlError = true
        throw new Error(GQL_ERROR)
      }

      if (err.message.indexOf('Forbidden') !== -1) {
        router.navigate('/account/logout')
        hasGqlError = true
        // toastErrorMessage = 'Forbidden'
        cancelAnyRetry = true
        throw new Error(GQL_ERROR)
      }

      if (err.message.indexOf('Authorization error') !== -1) {
        hasGqlError = true
        toastErrorMessage = 'Authorization issue'
        cancelAnyRetry = true
        throw new Error(GQL_ERROR)
      }

      errorMessagesToDisplay += `${err.message};\n`
      toastErrorMessage += `${err.message};\n`
    })

    if (resp.data.errors) {
      hasGqlError = true
      throw new Error(errorMessagesToDisplay || GQL_ERROR)
    }

    return resp
  } catch (error) {
    retriesSoFar += 1

    if (GA_ID) {
      ReactGA.event({
        category: GA_EVENT_CATEGORY.ERROR_API,
        action: api,
        label: query,
      })
    }

    if (retriesSoFar <= MAX_RETRIES && !cancelAnyRetry) {
      // sleep between requests
      await new Promise(resolve => setTimeout(resolve, TIME_BETWEEN_RETRIES))
      return await clientWrapper(api, query, variables, retriesSoFar)
    }

    if (hasGqlError && toastErrorMessage) {
      toast.error(toastErrorMessage)
    }

    if (!hasGqlError) {
      const msg = JSON.stringify(error)
      console.error(msg)
      // print a generic error
      toast.error(GENERIC_ERROR_MESSAGE)
    }
    throw new Error((error as Error).message)
  }
}

export default clientWrapper
