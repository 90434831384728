import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IToggleAnnouncementProps = {
  uuid: string
}

export type IToggleAnnouncementResponse = IGenericResponse<'toggleAnnouncementEnablement', { uuid: string }>

export async function toggleAnnouncement({ uuid }: IToggleAnnouncementProps): Promise<IToggleAnnouncementResponse> {
  const query = `
    mutation toggleAnnouncementEnablement($uuid:String!) {
      toggleAnnouncementEnablement (
        uuid: $uuid
      )
      {
        uuid
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { uuid })) as IToggleAnnouncementResponse
}
