import React from 'react'

import { SvgIconProps } from './iconProps'

const IconYoutubeMusic: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_710_18720)">
      <path
        d="M16 0.799988C7.60511 0.799988 0.799988 7.62335 0.799988 16.0403C1.60063 36.2563 30.4022 36.2505 31.2 16.04C31.2 7.62335 24.3945 0.799988 16 0.799988ZM16 24.7677C11.1885 24.7677 7.27359 20.8531 7.27359 16.0413C7.51039 10.0694 11.9629 7.35839 15.9997 7.35839C20.0368 7.35871 24.4902 10.0697 24.7257 16.0217C24.7264 20.8534 20.8115 24.7677 16 24.7677Z"
        fill="#FF0000"
      />
      <path
        d="M16.0006 8.35295C10.8979 8.35263 8.41535 12.3485 8.26783 16.0611C8.26815 20.3049 11.7366 23.7734 16 23.7734C20.2633 23.7734 23.7318 20.3049 23.7318 16.0416C23.5856 12.3491 21.1033 8.35359 16.0006 8.35295Z"
        fill="#FF0000"
      />
      <path
        d="M13.1376 16.8089C13.1373 17.6329 13.1379 19.3264 13.1376 20.1561L20.1142 15.8627L13.1376 11.927V16.8089Z"
        fill="#F1F1F1"
      />
    </g>
    <defs>
      <clipPath id="clip0_710_18720">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconYoutubeMusic
