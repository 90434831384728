import classNames from 'classnames'

type FeaturedIconProps = {
  className?: string
  icon?: JSX.Element
  size?: 'sm' | 'md' | 'lg' | 'xl'
  color?: 'primary' | 'light' | 'warning' | 'gray' | 'info'
}

/**
 * FeaturedIcon
 */
const FeaturedIcon = ({ className = ' ', icon, size = 'lg', color = 'light' }: FeaturedIconProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'iconfeatured',
        className,
        size ? `iconfeatured-${size}` : '',
        size ? `iconfeatured-${color}` : ''
      )}
    >
      {icon && <div>{icon}</div>}
    </div>
  )
}

export default FeaturedIcon
