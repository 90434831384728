export const Valid = (): JSX.Element => {
  return (
    <span className="bg-success">
      <i className="uil uil-check"> </i>
    </span>
  )
}

export const Invalid = (): JSX.Element => {
  return (
    <span className="bg-warning">
      <i className="uil uil-times"> </i>
    </span>
  )
}

export const Required = (): JSX.Element => {
  return <span className="ms-1 text-danger">*</span>
}
