import { Instance, types } from 'mobx-state-tree'

export const TransferWiseDetails = types.model({
  accountHolderName: types.string,
  abartn: types.maybeNull(types.string),
  accountNumber: types.maybeNull(types.string),
  accountType: types.maybeNull(types.string),
  addresscity: types.maybeNull(types.string),
  addresscountry: types.maybeNull(types.string),
  addressfirstLine: types.maybeNull(types.string),
  addresspostCode: types.maybeNull(types.string),
  bankcode: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  currencyCode: types.maybeNull(types.string),
  legalType: types.maybeNull(types.string),
  swiftCode: types.maybeNull(types.string),
  taxId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  sortCode: types.maybeNull(types.string),
  IBAN: types.maybeNull(types.string),
  BIC: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  address: types.maybeNull(
    types.model({
      city: types.maybeNull(types.string),
      country: types.maybeNull(types.string),
      firstLine: types.maybeNull(types.string),
      postCode: types.maybeNull(types.string),
    })
  ),
})

export type ITransferWiseDetails = Instance<typeof TransferWiseDetails>
