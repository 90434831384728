import { APPROVAL_STATES, SERVICE_NAME } from '../../../constants'
import { IReleaseBasic } from '../../../models'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

type TChangeReleaseStatesInput = {
  itemId: string
  targetState: APPROVAL_STATES
  comment?: string
}

export type IChangeReleaseStatesResponse = IGenericResponse<'changeReleasesState', IReleaseBasic[]>

export async function changeReleasesState(
  changeStateInputs: TChangeReleaseStatesInput[]
): Promise<IChangeReleaseStatesResponse> {
  const props = { changeStateInputs }
  const query = `
    mutation changeReleasesState (
      $changeStateInputs: [ChangeStateInput!]!
    ){
      changeReleasesState ( changeStateDataList: $changeStateInputs){
        uuid
        assetState
 
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IChangeReleaseStatesResponse>
}
