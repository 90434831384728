import {
  ACTIVE_REFERENCE_QUERY_NAME,
  ADMIN_QUERY_NAME,
  AFTER_DATE_QUERY_NAME,
  APPEAL_EXPLANATION_QUERY_NAME,
  ASSET_QUERY_NAME,
  ASSIGNMENT_QUERY_NAME,
  CHANNEL_QUERY_NAME,
  CLIENT_STATUS_QUERY_NAME,
  CMS_QUERY_NAME,
  COMPOSITION_QUERY_NAME,
  CONTRACT_STATUS_QUERY_NAME,
  COUNTRY_QUERY_NAME,
  DEFAULT_PER_PAGE_VALUE,
  DEFAULT_SORT_DIRECTION,
  DISPUTE_NOTE_QUERY_NAME,
  DISPUTE_REASON_QUERY_NAME,
  DO_NOT_PAY_QUERY_NAME,
  ELIGIBLE_QUERY_NAME,
  GENRE_QUERY_NAME,
  HISTORY_EVENT_TYPE_QUERY_NAME,
  INTERNAL_STATUS_QUERY_NAME,
  IS_COVER_QUERY_NAME,
  IS_CREATED_BY_US_QUERY_NAME,
  IS_EARLY_QUERY_NAME,
  IS_ENABLED_QUERY_NAME,
  IS_INCOMPLETE_METHOD_QUERY_NAME,
  IS_PRIVATE_VIDEO_QUERY_NAME,
  IS_TAKE_ACTION_EXPIRED,
  IS_UNASSIGNED_QUERY_NAME,
  LABELS_QUERY_NAME,
  LICENSE_REQUESTED_QUERY_NAME,
  METHOD_QUERY_NAME,
  MONTH_QUERY_NAME,
  MULTIPLE_IDS_QUERY_NAME,
  ORDER_QUERY_NAME,
  PAGE_QUERY_NAME,
  PAYMENT_STATUS_QUERY_NAME,
  PERPAGE_QUERY_NAME,
  PITCH_QUERY_NAME,
  PLATFORM_QUERY_NAME,
  RELEASE_UUID_QUERY_NAME,
  REQUESTED_STATUS_QUERY_NAME,
  ROLES_QUERY_NAME,
  SECTION_QUERY_NAME,
  SERVICE_QUERY_NAME,
  SITES_QUERY_NAME,
  SORT_COL_QUERY_NAME,
  SORT_DIRECTION_QUERY_NAME,
  SortDirection,
  SPLIT_QUERY_NAME,
  STATES_QUERY_NAME,
  STATUS_QUERY_NAME,
  STATUSES_QUERY_NAME,
  STORES_QUERY_NAME,
  TAKE_ACTION_BY,
  TEMPLATES_TITLES_QUERY_NAME,
  TEXT_QUERY_NAME,
  TYPES_QUERY_NAME,
  UPC_QUERY_NAME,
  USER_QUERY_NAME,
  UUID_QUERY_NAME,
} from '@epic-front/common/src/constants'
import { IPagination } from '@epic-front/common/src/models/general/Pagination.model'
import { isArray } from 'lodash'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useQuery from './useQuery'

interface IProps {
  onParamChange?: () => void
  blockTrigger?: boolean
  textFilter?: string | null
  multipleIdsFilter?: string[] | null
  siteFilter?: string | null | string[]
  cmsFilter?: string | null
  eligibleFilter?: boolean | null
  isCoverFilter?: boolean | null
  isCreatedByUsFilter?: boolean | null
  isUnassignedFilter?: boolean | null
  compositionFilter?: boolean | null
  hasActiveReferenceId?: boolean | null
  assignmentFilter?: string | null
  splitFilter?: string | null
  monthFilter?: string | null
  clientStatusFilter?: string | null
  internalStatusFilter?: string | null
  statusFilter?: string | null
  statusesFilter?: string[] | null
  statesFilter?: string[] | null
  methodFilter?: string | null
  labelsFilter?: string[] | null
  rolesFilter?: string[] | null
  userUuidFilter?: string | null
  genreUuidFilter?: string | null
  assetUuidFilter?: string | null
  orderUuidFilter?: string | null
  countryFilter?: string | null
  channelUuidFilter?: string | string[] | null
  isEarlyFilter?: boolean | null
  isEnabled?: boolean | null
  incompletePaymentFilter?: boolean | null
  doNotPayFilter?: boolean | null
  pagination?: IPagination | null
  pitchFilter?: string | null
  platformFilter?: string | null
  typesFilter?: string[] | null
  serviceFilter?: string | null
  section?: string | null
  appealExplanationFilter?: string | null
  disputeNoteFilter?: string | null
  disputeReasonFilter?: string | null
  historyEventTypeFilter?: string | null
  isPrivateVideoFilter?: boolean | null
  isTakeActionExpiredFilter?: boolean | null
  takeActionByFilter?: string
  afterDateFilter?: string | null
  upcFilter?: string | null
  contractStatusFilter?: string | null
  paymentStatusFilter?: string | null
  templateTitlesFilter?: string[] | null
  storesFilter?: string[] | null
  releaseUuidFilter?: string | null
  uuidFilter?: string | null
  requestedStatusFilter?: string | null
  adminFilter?: string | null
  isLicenseRequestedFilter?: boolean | null
}

const useFilterQueryParams = ({
  onParamChange,
  blockTrigger,
  eligibleFilter,
  isCoverFilter,
  isPrivateVideoFilter,
  isTakeActionExpiredFilter,
  takeActionByFilter,
  isCreatedByUsFilter,
  isUnassignedFilter,
  isEnabled,
  compositionFilter,
  hasActiveReferenceId,
  assignmentFilter,
  splitFilter,
  siteFilter,
  cmsFilter,
  monthFilter,
  clientStatusFilter,
  contractStatusFilter,
  paymentStatusFilter,
  internalStatusFilter,
  statusFilter,
  methodFilter,
  textFilter,
  upcFilter,
  multipleIdsFilter,
  labelsFilter,
  rolesFilter,
  userUuidFilter,
  isEarlyFilter,
  incompletePaymentFilter,
  doNotPayFilter,
  pitchFilter,
  pagination,
  section,
  channelUuidFilter,
  assetUuidFilter,
  orderUuidFilter,
  countryFilter,
  genreUuidFilter,
  statusesFilter,
  storesFilter,
  statesFilter,
  platformFilter,
  typesFilter,
  serviceFilter,
  appealExplanationFilter,
  disputeNoteFilter,
  disputeReasonFilter,
  historyEventTypeFilter,
  afterDateFilter,
  templateTitlesFilter,
  releaseUuidFilter,
  uuidFilter,
  requestedStatusFilter,
  adminFilter,
  isLicenseRequestedFilter,
}: IProps): void => {
  const navigate = useNavigate()

  const query = useQuery()
  useEffect(() => {
    if (pagination) {
      const pageFilter = query.get(PAGE_QUERY_NAME)
      const perPageFilter = query.get(PERPAGE_QUERY_NAME)
      const sortDirection = query.get(SORT_DIRECTION_QUERY_NAME)
      const sortCol = query.get(SORT_COL_QUERY_NAME)

      if (pageFilter) pagination.setPage(Number(pageFilter))
      if (perPageFilter) pagination.setPerPage(Number(perPageFilter))
      if (sortCol) pagination.setSort(sortCol)
      if (sortDirection) pagination.setSortDirection(sortDirection as SortDirection)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()

    if (textFilter) {
      params.append(TEXT_QUERY_NAME, textFilter)
    } else {
      params.delete(TEXT_QUERY_NAME)
    }

    if (releaseUuidFilter) {
      params.append(RELEASE_UUID_QUERY_NAME, releaseUuidFilter)
    } else {
      params.delete(RELEASE_UUID_QUERY_NAME)
    }
    if (uuidFilter) {
      params.append(UUID_QUERY_NAME, uuidFilter)
    } else {
      params.delete(UUID_QUERY_NAME)
    }
    if (adminFilter) {
      params.append(ADMIN_QUERY_NAME, adminFilter)
    } else {
      params.delete(ADMIN_QUERY_NAME)
    }
    if (multipleIdsFilter && multipleIdsFilter.length > 0) {
      params.append(MULTIPLE_IDS_QUERY_NAME, multipleIdsFilter.toString())
    } else {
      params.delete(MULTIPLE_IDS_QUERY_NAME)
    }
    if (pagination?.page && pagination.page !== 1) {
      params.append(PAGE_QUERY_NAME, String(pagination.page))
    } else {
      params.delete(PAGE_QUERY_NAME)
    }
    if (pagination?.perPage && pagination.perPage !== DEFAULT_PER_PAGE_VALUE) {
      params.append(PERPAGE_QUERY_NAME, String(pagination.perPage))
    } else {
      params.delete(PERPAGE_QUERY_NAME)
    }

    if (pagination?.sort) {
      params.append(SORT_COL_QUERY_NAME, String(pagination.sort))
    } else {
      params.delete(SORT_COL_QUERY_NAME)
    }

    if (pagination?.sortDirection && pagination.sortDirection !== DEFAULT_SORT_DIRECTION) {
      params.append(SORT_DIRECTION_QUERY_NAME, String(pagination?.sortDirection))
    } else {
      params.delete(SORT_DIRECTION_QUERY_NAME)
    }

    if (eligibleFilter) {
      params.append(ELIGIBLE_QUERY_NAME, String(eligibleFilter))
    } else {
      params.delete(ELIGIBLE_QUERY_NAME)
    }

    if (isCoverFilter) {
      params.append(IS_COVER_QUERY_NAME, String(isCoverFilter))
    } else {
      params.delete(IS_COVER_QUERY_NAME)
    }

    if (isEnabled) {
      params.append(IS_ENABLED_QUERY_NAME, String(isEnabled))
    } else {
      params.delete(IS_ENABLED_QUERY_NAME)
    }

    if (isPrivateVideoFilter !== null && isPrivateVideoFilter !== undefined) {
      params.append(IS_PRIVATE_VIDEO_QUERY_NAME, String(isPrivateVideoFilter))
    } else {
      params.delete(IS_PRIVATE_VIDEO_QUERY_NAME)
    }

    if (isTakeActionExpiredFilter) {
      params.append(IS_TAKE_ACTION_EXPIRED, String(isTakeActionExpiredFilter))
    } else {
      params.delete(IS_TAKE_ACTION_EXPIRED)
    }

    if (isLicenseRequestedFilter) {
      params.append(LICENSE_REQUESTED_QUERY_NAME, String(isLicenseRequestedFilter))
    } else {
      params.delete(LICENSE_REQUESTED_QUERY_NAME)
    }

    if (takeActionByFilter) {
      params.append(TAKE_ACTION_BY, String(takeActionByFilter))
    } else {
      params.delete(TAKE_ACTION_BY)
    }

    if (isCreatedByUsFilter) {
      params.append(IS_CREATED_BY_US_QUERY_NAME, String(isCreatedByUsFilter))
    } else {
      params.delete(IS_CREATED_BY_US_QUERY_NAME)
    }

    if (isUnassignedFilter) {
      params.append(IS_UNASSIGNED_QUERY_NAME, 'true')
    } else {
      params.delete(IS_UNASSIGNED_QUERY_NAME)
    }

    if (typeof compositionFilter === 'boolean') {
      params.append(COMPOSITION_QUERY_NAME, String(compositionFilter))
    } else {
      params.delete(COMPOSITION_QUERY_NAME)
    }

    if (typeof hasActiveReferenceId === 'boolean') {
      params.append(ACTIVE_REFERENCE_QUERY_NAME, String(hasActiveReferenceId))
    } else {
      params.delete(ACTIVE_REFERENCE_QUERY_NAME)
    }

    if (assignmentFilter) {
      params.append(ASSIGNMENT_QUERY_NAME, assignmentFilter)
    } else {
      params.delete(ASSIGNMENT_QUERY_NAME)
    }

    if (splitFilter) {
      params.append(SPLIT_QUERY_NAME, splitFilter)
    } else {
      params.delete(SPLIT_QUERY_NAME)
    }

    if (monthFilter) {
      params.append(MONTH_QUERY_NAME, monthFilter)
    } else {
      params.delete(MONTH_QUERY_NAME)
    }

    if (clientStatusFilter) {
      params.append(CLIENT_STATUS_QUERY_NAME, clientStatusFilter)
    } else {
      params.delete(CLIENT_STATUS_QUERY_NAME)
    }

    if (contractStatusFilter) {
      params.append(CONTRACT_STATUS_QUERY_NAME, contractStatusFilter)
    } else {
      params.delete(CONTRACT_STATUS_QUERY_NAME)
    }

    if (paymentStatusFilter) {
      params.append(PAYMENT_STATUS_QUERY_NAME, paymentStatusFilter)
    } else {
      params.delete(PAYMENT_STATUS_QUERY_NAME)
    }

    if (internalStatusFilter) {
      params.append(INTERNAL_STATUS_QUERY_NAME, internalStatusFilter)
    } else {
      params.delete(INTERNAL_STATUS_QUERY_NAME)
    }

    if (statusFilter) {
      params.append(STATUS_QUERY_NAME, statusFilter)
    } else {
      params.delete(STATUS_QUERY_NAME)
    }

    if (requestedStatusFilter) {
      params.append(REQUESTED_STATUS_QUERY_NAME, requestedStatusFilter)
    } else {
      params.delete(REQUESTED_STATUS_QUERY_NAME)
    }

    if (afterDateFilter) {
      params.append(AFTER_DATE_QUERY_NAME, afterDateFilter)
    } else {
      params.delete(AFTER_DATE_QUERY_NAME)
    }
    if (section) {
      params.append(SECTION_QUERY_NAME, section)
    } else {
      params.delete(SECTION_QUERY_NAME)
    }

    if (statusesFilter && statusesFilter.length > 0) {
      params.append(STATUSES_QUERY_NAME, statusesFilter.toString())
    } else {
      params.delete(STATUSES_QUERY_NAME)
    }

    if (storesFilter && storesFilter.length > 0) {
      params.append(STORES_QUERY_NAME, storesFilter.toString())
    } else {
      params.delete(STORES_QUERY_NAME)
    }

    if (statesFilter && statesFilter.length > 0) {
      params.append(STATES_QUERY_NAME, statesFilter.toString())
    } else {
      params.delete(STATES_QUERY_NAME)
    }

    if (methodFilter) {
      params.append(METHOD_QUERY_NAME, methodFilter)
    } else {
      params.delete(METHOD_QUERY_NAME)
    }

    if (userUuidFilter) {
      params.append(USER_QUERY_NAME, userUuidFilter)
    } else {
      params.delete(USER_QUERY_NAME)
    }

    if (assetUuidFilter) {
      params.append(ASSET_QUERY_NAME, assetUuidFilter)
    } else {
      params.delete(ASSET_QUERY_NAME)
    }

    if (orderUuidFilter) {
      params.append(ORDER_QUERY_NAME, orderUuidFilter)
    } else {
      params.delete(ORDER_QUERY_NAME)
    }

    if (countryFilter) {
      params.append(COUNTRY_QUERY_NAME, countryFilter)
    } else {
      params.delete(COUNTRY_QUERY_NAME)
    }

    if (genreUuidFilter) {
      params.append(GENRE_QUERY_NAME, genreUuidFilter)
    } else {
      params.delete(GENRE_QUERY_NAME)
    }

    if (
      (isArray(channelUuidFilter) && channelUuidFilter.length > 0) ||
      (!isArray(channelUuidFilter) && channelUuidFilter)
    ) {
      params.append(CHANNEL_QUERY_NAME, channelUuidFilter.toString())
    } else {
      params.delete(CHANNEL_QUERY_NAME)
    }

    if (isEarlyFilter) {
      params.append(IS_EARLY_QUERY_NAME, '1')
    } else {
      params.delete(IS_EARLY_QUERY_NAME)
    }

    if (incompletePaymentFilter) {
      params.append(IS_INCOMPLETE_METHOD_QUERY_NAME, '1')
    } else {
      params.delete(IS_INCOMPLETE_METHOD_QUERY_NAME)
    }

    if (doNotPayFilter) {
      params.append(DO_NOT_PAY_QUERY_NAME, '1')
    } else {
      params.delete(DO_NOT_PAY_QUERY_NAME)
    }

    if (labelsFilter && labelsFilter.length > 0) {
      params.append(LABELS_QUERY_NAME, labelsFilter.toString())
    } else {
      params.delete(LABELS_QUERY_NAME)
    }
    if (rolesFilter && rolesFilter.length > 0) {
      params.append(ROLES_QUERY_NAME, rolesFilter.toString())
    } else {
      params.delete(ROLES_QUERY_NAME)
    }

    if (typesFilter && typesFilter.length > 0) {
      params.append(TYPES_QUERY_NAME, typesFilter.toString())
    } else {
      params.delete(TYPES_QUERY_NAME)
    }

    if ((isArray(siteFilter) && siteFilter.length > 0) || (!isArray(siteFilter) && siteFilter)) {
      params.append(SITES_QUERY_NAME, siteFilter.toString())
    } else {
      params.delete(SITES_QUERY_NAME)
    }

    if (cmsFilter) {
      params.append(CMS_QUERY_NAME, cmsFilter)
    } else {
      params.delete(CMS_QUERY_NAME)
    }

    if (pitchFilter) {
      params.append(PITCH_QUERY_NAME, pitchFilter)
    } else {
      params.delete(PITCH_QUERY_NAME)
    }

    if (platformFilter) {
      params.append(PLATFORM_QUERY_NAME, platformFilter)
    } else {
      params.delete(PLATFORM_QUERY_NAME)
    }

    if (serviceFilter) {
      params.append(SERVICE_QUERY_NAME, serviceFilter)
    } else {
      params.delete(SERVICE_QUERY_NAME)
    }

    if (appealExplanationFilter) {
      params.append(APPEAL_EXPLANATION_QUERY_NAME, appealExplanationFilter)
    } else {
      params.delete(APPEAL_EXPLANATION_QUERY_NAME)
    }

    if (disputeNoteFilter) {
      params.append(DISPUTE_NOTE_QUERY_NAME, disputeNoteFilter)
    } else {
      params.delete(DISPUTE_NOTE_QUERY_NAME)
    }

    if (disputeReasonFilter) {
      params.append(DISPUTE_REASON_QUERY_NAME, disputeReasonFilter)
    } else {
      params.delete(DISPUTE_REASON_QUERY_NAME)
    }

    if (historyEventTypeFilter) {
      params.append(HISTORY_EVENT_TYPE_QUERY_NAME, historyEventTypeFilter)
    } else {
      params.delete(HISTORY_EVENT_TYPE_QUERY_NAME)
    }

    if (onParamChange && !blockTrigger) {
      onParamChange()
    }

    if (upcFilter) {
      params.append(UPC_QUERY_NAME, upcFilter)
    } else {
      params.delete(UPC_QUERY_NAME)
    }

    if (templateTitlesFilter && templateTitlesFilter.length > 0) {
      params.append(TEMPLATES_TITLES_QUERY_NAME, templateTitlesFilter.toString())
    } else {
      params.delete(TEMPLATES_TITLES_QUERY_NAME)
    }

    navigate({ search: params.toString() }, { replace: true })
  }, [
    eligibleFilter,
    isCoverFilter,
    isPrivateVideoFilter,
    isTakeActionExpiredFilter,
    takeActionByFilter,
    isUnassignedFilter,
    compositionFilter,
    hasActiveReferenceId,
    assignmentFilter,
    splitFilter,
    onParamChange,
    siteFilter,
    cmsFilter,
    monthFilter,
    statusFilter,
    clientStatusFilter,
    contractStatusFilter,
    paymentStatusFilter,
    internalStatusFilter,
    statusesFilter,
    storesFilter,
    statesFilter,
    methodFilter,
    textFilter,
    multipleIdsFilter,
    pitchFilter,
    labelsFilter,
    rolesFilter,
    userUuidFilter,
    assetUuidFilter,
    orderUuidFilter,
    countryFilter,
    channelUuidFilter,
    genreUuidFilter,
    isEarlyFilter,
    isEnabled,
    incompletePaymentFilter,
    doNotPayFilter,
    platformFilter,
    typesFilter,
    serviceFilter,
    pagination?.page,
    pagination?.perPage,
    pagination?.sortDirection,
    pagination?.sort,
    section,
    appealExplanationFilter,
    disputeNoteFilter,
    disputeReasonFilter,
    historyEventTypeFilter,
    afterDateFilter,
    upcFilter,
    navigate,
    contractStatusFilter,
    templateTitlesFilter,
    releaseUuidFilter,
    uuidFilter,
    requestedStatusFilter,
    adminFilter,
    isLicenseRequestedFilter,
  ])
}

export default useFilterQueryParams
