import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { extendedUserFragment } from '../fragments'

export type ILoginProps = {
  email: string
  password: string
}

export type ILoginResponse = IGenericResponse<
  'login',
  {
    token: string
    user: IUserBasic
  }
>

export async function login(props: ILoginProps): Promise<ILoginResponse> {
  const query = `
    mutation login ($password: String!, $email: String!) {
      login(
        password:$password
        email:$email
        rememberMe: false
      ) {
        token
        user {
          ${extendedUserFragment}
        }
      }
    }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<ILoginResponse>
}
