import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IEditContactRequestResponse = IGenericResponse<'editContactRequest', { uuid: string }>

export type IEditContactRequestProps = {
  uuid: string
  status?: string
  subjectType?: string
}

export async function editContactRequest(props: IEditContactRequestProps): Promise<IEditContactRequestResponse> {
  const query = `#graphql
   mutation editContactRequest($data: ContactFormEditInput!) {
       editContactRequest(data:$data)
       {
          uuid
       }
      
  }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, { data: props }) as Promise<IEditContactRequestResponse>
}
