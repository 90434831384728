import { IArtTrack } from '@epic-front/common/src/models'
import { IAsset } from '@epic-front/common/src/models/assets/Asset.model'
import { AudioPlayer } from './AudioPlayer'
import PlayAssetCanvas from './PlayAssetCanvas'

interface IAudioCanvas {
  show: boolean
  setShow: (value: boolean) => void
  audio?: IAsset | IArtTrack
  assetUrl?: string
  startMoment?: number
}

export const AudioCanvas = ({ show, setShow, audio, assetUrl, startMoment }: IAudioCanvas): JSX.Element => {
  if (!show || !audio) return <></>

  return (
    <PlayAssetCanvas show={show} close={() => setShow(false)}>
      <AudioPlayer audio={audio} assetUrl={assetUrl} startMoment={startMoment} showSpectrum />
    </PlayAssetCanvas>
  )
}
