import { Instance, types } from 'mobx-state-tree'

export const ClaimEmailTemplate = types.model({
  uuid: types.identifier,
  title: types.maybeNull(types.string),
  subject: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  defaultTemplate: types.maybeNull(types.boolean),
})

export type IClaimEmailTemplate = Instance<typeof ClaimEmailTemplate>
