import { Instance, types, flow, cast } from 'mobx-state-tree'

import { Cms, ICms } from './Cms.model'
import { IGetCmsesResponse, getCmses } from '../../api/assets-api/contentId/getCmses'

export const CmsList = types
  .model({
    list: types.array(Cms),
  })
  .volatile(() => ({
    loading: true,
  }))
  .views(self => ({
    getCmsByContentOwner(onBehalfOfContentOwner: string): ICms | undefined {
      return self.list.find(el => el.onBehalfOfContentOwner === onBehalfOfContentOwner)
    },
  }))
  .actions(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.uuid === uuid)
    },
    setLoading(val: boolean) {
      self.loading = val
    },
    load: flow(function* () {
      try {
        self.loading = true

        const resp: IGetCmsesResponse = yield getCmses()
        if (resp?.data.data?.cmses) {
          self.list = cast(resp.data.data.cmses)
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))

export type ICmsList = Instance<typeof CmsList>
