import { types, Instance } from 'mobx-state-tree'

export const AssetUser = types.model({
  userUuid: types.maybeNull(types.string),
  revSplitUuid: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  isOwner: types.maybeNull(types.boolean),
})

export type IAssetUser = Instance<typeof AssetUser>
