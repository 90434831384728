import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteLabelProps = {
  labelUuid: string
}

export type IDeleteLabelResponse = IGenericResponse<'deleteLabel', boolean>

export async function deleteLabel({ labelUuid }: IDeleteLabelProps): Promise<IDeleteLabelResponse> {
  const query = `
    mutation deleteLabel($labelUuid:String!) {
      deleteLabel (
        labelUuid: $labelUuid
      )
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { labelUuid })) as IDeleteLabelResponse
}
