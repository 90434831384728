import { participantsFragment } from './fragments'

export const microlicensingPropsFragment = `
  microlicensingProps {
    waves
    shopifyProductId
    moods
    salesNumber
    licenses
    key
    genreUuids
    bpm
    audioType
    shortDescription
    description
    files {
      filePath
      extension
      downloadUrl
    }
  }
`

export const artTrackFragment = `
    uuid
    assetId
    title
    titleVersion
    sites
    isrc
    artist
    createdAt
    createdBy
    updatedAt
    updatedBy
    genreUuids
    users {
      userUuid
      revSplitUuid
      percentage
      isOwner
    }
    album
    isrc
    upc
    isCover
    storagePath
    downloadUrl
    fileName
    fileSizeInBytes
    participants {
      ${participantsFragment}
    }
    srAssetId
    srAssetUuid
    previewStart
`
export const artTracksFragment = `
  tracks {
    ${artTrackFragment}
  }
`

export const artTrackFragmentWithValidationErrors = `
    ${artTrackFragment}
    validationErrors   {
      field
      message
    }
`

export const loadReleaseTracksFragment = `
  uuid
  tracks {
    ${artTrackFragmentWithValidationErrors}
  }
`

export const loadReleaseTracksFragmentWithMicrolicensing = `
  uuid
  tracks {
    ${artTrackFragment}
    ${microlicensingPropsFragment}
  }
`

export const channelVideoLineFragment = `
    channelId
    channelUuid
    cmsUuid
    createdAt
    deletedAt
    description
    publishDate
    sites
    title
    totalViews
    updatedAt
    users {
      userUuid
      revSplitUuid
      percentage
      isOwner
    } 
    uuid
    videoAssetId
    videoAssetUuid
    videoId
    videoPrivacyStatus
    channel {
      name
      uuid
    }
`

export const channelVideosFragment = `
  channelVideos {
     ${channelVideoLineFragment}
  }
`

export const assetFullLineFragment = `
      uuid
      assetId
      title
      sites
      isrc
      assetState
      artist
      assetTypes 
      createdAt
      createdBy
      updatedAt
      updatedBy
      approvalComment
      clientStatus
      isCompositionShare
      users {
        userUuid
        revSplitUuid
        percentage
        isOwner
      }
      album
      isrc
      iswc
      customId
      label
      upc
      isCover
      storagePath
      downloadUrl
      fileName
      fileSizeInBytes
      description
      releaseDate
      publishDate
      originalReleaseDate
      metadataLanguage
      audioLanguage
      advisory
      rightsHolders
      uploadFinished
      forceAssignIsrc
`

export const assetReportingFragment = `
  assets {
  ${assetFullLineFragment}
  totalViews
  totalClaims
}
`

export const assetFullFragment = `
  assets{
    ${assetFullLineFragment}
  }
`

export const assetFullRawFragment = `assets {
    ${assetFullLineFragment}
    asJson
  }`

// checked
export const minimalAssetsFragment = `
assets {
  uuid
  title
  artist
}
`

// new not used yet
export const releaseFragment = `
  uuid
  title
  titleVersion
  sites
  artist
  createdAt
  createdBy
  updatedAt
  updatedBy
  users {
    userUuid
    revSplitUuid
    percentage
    isOwner
  }
  approvalComment
  upc
  isCover
  downloadUrl
  releaseDate
  originalReleaseDate
  clientStatus
  assetState
  preorderDate
  releaseFormat
  label
  displayImagePath
  generalInfoStepPassed
  trackInfoStepPassed
  targetPlatformStepPassed
  metadataLanguage
  audioLanguage
  genreUuids
  advisory
  cInfo
  cYear
  pInfo
  pYear
  rightsHolders
  tracks {
    uuid
  }
  images 
  {
    uuid
    fileName
    releaseUuid 
    storagePath
    downloadUrl
    type
  }
 
  platforms {
    name
    state
  }
  stores {
    id
    name
  }
  participants {
  ${participantsFragment}
  }
  ${microlicensingPropsFragment}
`

export const releaseFragmentWithFullArtTracks = `
  uuid
  title
  titleVersion
  sites
  artist
  createdAt
  createdBy
  updatedAt
  updatedBy
  users {
    userUuid
    revSplitUuid
    percentage
    isOwner
  }
  approvalComment
  upc
  isCover
  downloadUrl
  releaseDate
  originalReleaseDate
  clientStatus
  assetState
  preorderDate
  releaseFormat
  label
  displayImagePath
  generalInfoStepPassed
  trackInfoStepPassed
  targetPlatformStepPassed
  metadataLanguage
  audioLanguage
  genreUuids
  advisory
  cInfo
  cYear
  pInfo
  pYear
  rightsHolders
  tracks {
    ${artTrackFragment}

  }
  images 
  {
    uuid
    fileName
    releaseUuid 
    storagePath
    downloadUrl
    type
  }
 
  platforms {
    name
    state
  }
  stores {
    id
    name
  }
  participants {
  ${participantsFragment}
  }
`

export const releaseFragmentWithValidationErrors = `
  ${releaseFragment}
  validationErrors   {
    field
    message
  }
`

export const releasesFragment = `
  releases {
    ${releaseFragment}
  }
`
