import { Instance, types } from 'mobx-state-tree'

const LongestMatch = types.model({
  durationSecs: types.maybeNull(types.string),
  userVideoOffset: types.maybeNull(types.string),
  referenceOffset: types.maybeNull(types.string),
})

const TotalMatch = types.model({
  userVideoDurationSecs: types.maybeNull(types.string),
  referenceDurationSecs: types.maybeNull(types.string),
})

const Segment = types.model({
  kind: types.maybeNull(types.string),
  start: types.maybeNull(types.string),
  duration: types.maybeNull(types.string),
})

const MatchSegment = types.model({
  channel: types.maybeNull(types.string),
  video_segment: types.maybeNull(Segment),
  reference_segment: types.maybeNull(Segment),
})

export type IMatchSegment = Instance<typeof MatchSegment>

export const ClaimMatchInfo = types.model({
  referenceId: types.string,
  longestMatch: types.maybeNull(LongestMatch),
  totalMatch: types.maybeNull(TotalMatch),
  matchSegments: types.array(MatchSegment),
})

export type IClaimMatchInfo = Instance<typeof ClaimMatchInfo>
