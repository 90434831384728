import { Instance, types } from 'mobx-state-tree'

import { APPROVAL_STATES } from '../../../constants'
import { AssetUser } from '../AssetUser.model'

export const ChannelVideoBasic = types.model({
  uuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  users: types.array(AssetUser),
  videoId: types.maybeNull(types.string),
  assetId: types.maybeNull(types.string),
  publishDate: types.maybeNull(types.string),
  sites: types.array(types.string),
  channel: types.maybeNull(
    types.model({
      name: types.string,
      uuid: types.string,
    })
  ),
  channelUuid: types.maybeNull(types.string),
  assetState: types.maybeNull(types.enumeration(Object.values(APPROVAL_STATES))),
  approvedAt: types.maybeNull(types.string),
  rejectedAt: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  totalViews: types.maybeNull(types.number),
  // asJson: types.maybeNull(types.string),
})

export type IChannelVideoBasic = Instance<typeof ChannelVideoBasic>
