import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { IMenuItem } from '../../constants/menus/menu.types'
import { useStore } from '../../hooks'

interface IProps {
  item: IMenuItem
  className?: string
}

const MenuItemLink = ({ item, className }: IProps): JSX.Element => {
  const store = useStore()
  if (!item.url) {
    /** if there is no url prop we can't display a link */
    return <></>
  }
  if (item.isExternalLink) {
    return (
      <a
        href={item.url}
        target={item.target}
        className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)}
        data-menu-key={item.key}
      >
        {item.icon && <i className={item.icon} />}
        {item.badge && (
          <span className={`badge badge-${item.badge.variant} rounded-pill font-10 float-end`}>{item.badge.text}</span>
        )}
        <span> {item.name} </span>
      </a>
    )
  }

  return (
    <Link
      to={item.url}
      target={item.target}
      className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)}
      data-menu-key={item.key}
    >
      {item.icon && <i className={item.icon} />}
      {item.IconElement}
      {item.badge && (
        <span className={`badge badge-${item.badge.variant} rounded-pill font-10 float-end`}>{item.badge.text}</span>
      )}
      <span> {item.name} </span>
      {!!item.AfterIcon && item.AfterIcon}
      {!!item.LineEndElement && <item.LineEndElement store={store} />}
    </Link>
  )
}

export default observer(MenuItemLink)
