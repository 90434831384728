import { SERVICE_NAME } from '../../constants'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

export type IGetAssetLifetimeEarningsResponse = IGenericResponse<'assetLifetimeEarnings', number>

export async function getAssetLifetimeEarnings(assetUuid: string): Promise<IGetAssetLifetimeEarningsResponse> {
  const variables = { assetUuid }
  const query = `
    query assetLifetimeEarnings ($assetUuid: String!) {
      assetLifetimeEarnings(assetUuid:$assetUuid)
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IGetAssetLifetimeEarningsResponse>
}
