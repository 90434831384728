import { Instance, types } from 'mobx-state-tree'

export const PitchGroup = types.model({
  uuid: types.string,
  pitcherFirstName: types.maybeNull(types.string),
  pitcherLastName: types.maybeNull(types.string),
  pitcherOrganizationName: types.maybeNull(types.string),
  pitcherEmail: types.string,
  pitcherUuid: types.maybeNull(types.string),
  eSignCompletedAt: types.maybeNull(types.Date),
  createdAt: types.maybeNull(types.Date),
  status: types.maybeNull(types.string),

  pitches: types.array(types.string),
})

export type IPitchGroup = Instance<typeof PitchGroup>
