import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'
import { IBulkClaimResponse } from './bulkUpdateClaimIssuesStatus'
import { claimRequestFragment } from './fragments'

export type IRequestLicensesByEmailProps = {
  claimIds: string[]
  email: string
  subject: string
  emailTemplateUuid: string
  body: string
}

export type IRequestLicensesByEmailResponse = IGenericResponse<'requestLicensesByEmail', IBulkClaimResponse[]>

export async function requestLicensesByEmail(
  input: IRequestLicensesByEmailProps,
  fragment: string = claimRequestFragment
): Promise<IRequestLicensesByEmailResponse> {
  const query = `
    mutation RequestLicensesByEmail($input: RequestLicensesFilterInput!) {
      requestLicensesByEmail(input: $input) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { input }) as Promise<IRequestLicensesByEmailResponse>
}
