export type IEarningAffiliation = {
  earning: number
  percentage: number
  userUuid: string
  type?: string
  fromChannelUuid?: string
}

export const parseAffiliations = (
  affiliations: string
): { userAffiliations: IEarningAffiliation[]; channelAffiliations: IEarningAffiliation[] } => {
  try {
    const parsedAffiliations = JSON.parse(affiliations)

    const userAffiliations = parsedAffiliations.filter(
      (aff: IEarningAffiliation) => !aff.fromChannelUuid && aff.userUuid
    )
    const channelAffiliations = parsedAffiliations.filter(
      (aff: IEarningAffiliation) => aff.fromChannelUuid && aff.userUuid
    )

    return { userAffiliations, channelAffiliations }
  } catch (e) {
    console.error(e)
    return { userAffiliations: [], channelAffiliations: [] }
  }
}
