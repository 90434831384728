import { Instance, types } from 'mobx-state-tree'

const Duration = types.model({
  low: types.maybeNull(types.number),
  high: types.maybeNull(types.number),
})

const RequiredTerritory = types.model({
  type: types.maybeNull(types.string),
  territories: types.maybeNull(types.array(types.string)),
})

const Condition = types.model({
  contentMatchType: types.maybeNull(types.array(types.string)),
  requiredTerritories: types.maybeNull(RequiredTerritory),
  matchDuration: types.maybeNull(types.array(Duration)),
  matchPercent: types.maybeNull(types.array(Duration)),
  referenceDuration: types.maybeNull(types.array(Duration)),
  referencePercent: types.maybeNull(types.array(Duration)),
})

const ClaimIssueRule = types.model({
  action: types.maybeNull(types.string),
  subaction: types.maybeNull(types.array(types.string)),
  conditions: types.maybeNull(Condition),
})

export type IClaimIssueRule = Instance<typeof ClaimIssueRule>

export const ClaimPolicy = types.model({
  kind: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  timeUpdated: types.maybeNull(types.string),
  rules: types.maybeNull(types.array(ClaimIssueRule)),
})

export type IClaimPolicy = Instance<typeof ClaimPolicy>
