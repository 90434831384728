import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { IPaginationParams } from '../../../types/common'
import { IIsrcEmbed } from '../../../models/isrc/IsrcEmbed.model'
import { SERVICE_NAME } from '../../../constants'
import { isrcEmbedsFragment } from '../fragments'

export type IGetIsrcEmbedsResponseBody = {
  total: number
  isrcEmbeds: IIsrcEmbed[]
}

export type IGetIsrcEmbedsProps = {
  pagination?: IPaginationParams
  filters?: {
    isrc?: string
    youTubeVideoId?: string
  }
}

export type IGetIsrcEmbedsResponse = IGenericResponse<'isrcEmbeds', IGetIsrcEmbedsResponseBody>

export async function getIsrcEmbeds(
  variables: IGetIsrcEmbedsProps,
  fragment: string = isrcEmbedsFragment
): Promise<IGetIsrcEmbedsResponse> {
  const query = `
    query getIsrcEmbeds ($pagination: PaginationInput, $filters: IsrcEmbedFiltersInput!) {
      isrcEmbeds(pagination: $pagination, filters: $filters) {
        total
        isrcEmbeds {
          ${fragment}
        }
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IGetIsrcEmbedsResponse>
}
