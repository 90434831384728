import React from 'react'

import { SvgIconProps } from './iconProps'

const IconTransferwise: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.838 2H21.5918L11.747 22.434L9.5 21.566L17.963 4H6.77738L11.0626 8.15074L5.92307 12.1333H10.8462V14.1333H0.0769196L8.01436 7.98259L1.838 2Z"
    />
  </svg>
)

export default IconTransferwise
