import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { rolesExtended } from '../fragments'

export type IToggleRoleProps = {
  userUuid: string
  roleUuid: string
}

export type IToggleRoleResponse = IGenericResponse<'toggleRole', IUserBasic>

export async function toggleRole(
  { userUuid, roleUuid }: IToggleRoleProps,
  fragment: string = rolesExtended
): Promise<IToggleRoleResponse> {
  const query = `
  mutation toggleRole($userUuid: String!, $roleUuid: String!) {
    toggleRole(
        userUuid: $userUuid
        roleUuid: $roleUuid
      ) {
				uuid
				${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid, roleUuid })) as IToggleRoleResponse
}
