import { PARTICIPANT_ROLES, SERVICE_NAME } from '../../../constants'
import { IArtTrackBasic } from '../../../models'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { artTrackFragment } from '../fragmentsAssets'

export interface ICreateUpdateTrackProps {
  continue?: boolean
  uuid?: string
  title?: string
  assetType?: string
  userUuid?: string | null
  genreUuids?: string[]
  participantUuidsWithRoles?: { participantUuid: string; role: PARTICIPANT_ROLES }[]
  rightsHolders?: string
  label?: string | null
  upc?: string | null
  fileName?: string | null
  fileSizeInBytes?: number | null
  bpm?: number | null
  uploadFinished?: boolean
  titleVersion?: string | null
  recordingLocation?: string | null
  releaseDate?: string | null
  audioLanguage?: string | null
  metadataLanguage?: string | null
  displayImagePath?: string | null
  advisory?: string | null
  description?: string | null
  releaseUuid?: string | null
  assetId?: string | null
  customId?: string | null
  isrc?: string | null
  iswc?: string | null
  artist?: string | null
  album?: string | null
  genre?: string | null
  originalReleaseDate?: string | null
  preorderDate?: string | null
  isCover?: boolean | null
  previewStart?: number | null
  stores?: string[] | null
  srCmsDestination?: string | null
  trackOrder?: string[]
  forceAssignIsrc?: boolean
}

export type ICreateUpdateTrackResponse = IGenericResponse<'createOrUpdateTrack', IArtTrackBasic>

export async function createOrUpdateTrack(
  props: ICreateUpdateTrackProps,
  fragment: string = artTrackFragment
): Promise<ICreateUpdateTrackResponse> {
  const variables = {
    patch: props,
  }
  const query = `
    mutation createOrUpdateTrack($patch: AssetInput!) {
      createOrUpdateTrack (
        patch: $patch
      ) {
        ${fragment} 
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<ICreateUpdateTrackResponse>
}
