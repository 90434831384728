import { SERVICE_NAME } from '../../../constants'
import { IDailyVideoPitchEarnings } from '../../../models'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { songpitchEstimatedEarningsPerUserFragment } from '../fragments'

interface IDailySongpitchEstimatedEarningsPerUseProps {
  startDate: string
  endDate: string
  userUuid: string
}

export type IDailySongpitchEstimatedEarningsPerUseResponse = IGenericResponse<
  'dailySongpitchEstimatedEarningsPerUser',
  IDailyVideoPitchEarnings[][]
>

export async function dailySongpitchEstimatedEarningsPerUser(
  props: IDailySongpitchEstimatedEarningsPerUseProps,
  fragment: string = songpitchEstimatedEarningsPerUserFragment
): Promise<IDailySongpitchEstimatedEarningsPerUseResponse> {
  const variables = {
    filters: { ...props },
  }
  const query = `
   query DailySongpitchEstimatedEarningsPerUser($filters: DailySongpitchEstimatedEarningsPerUserFilters!) {
    dailySongpitchEstimatedEarningsPerUser(filters: $filters) {
      ${fragment}
    }
  }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IDailySongpitchEstimatedEarningsPerUseResponse>
}
