import { observer } from 'mobx-react-lite'
import { Row, Col } from 'react-bootstrap'
import { VERSION, PLATFORM_NAME } from '@epic-front/common/src/constants'

const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear()
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              {currentYear} © {PLATFORM_NAME} - v{VERSION}
            </Col>
          </Row>
        </div>
      </footer>
    </>
  )
}

export default observer(Footer)
