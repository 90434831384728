import { SERVICE_NAME } from '../../../constants'
import { IRelease } from '../../../models'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { releaseFragmentWithFullArtTracks } from '../fragmentsAssets'

export type IDdexUploadCsvProps = {
  csvString: string
  audios: string[]
  images: string[]
}

export type IDdexUploadCsvResponse = IGenericResponse<'ddexUploadCsv', { releases: IRelease[]; errors: string[] }>

export async function ddexUploadCsv(
  { csvString, audios, images }: IDdexUploadCsvProps,
  fragment: string = releaseFragmentWithFullArtTracks
): Promise<IDdexUploadCsvResponse> {
  const query = `
      mutation ddexUploadCsv($csvString: String!, $audios: [String!]!, $images: [String!]!) {
      ddexUploadCsv(csvString: $csvString, audios: $audios, images: $images) {
        releases {
          ${fragment}
        }
        errors 
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { csvString, audios, images })) as IDdexUploadCsvResponse
}
