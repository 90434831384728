import { SERVICE_NAME } from '../../../constants'
import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

export type IDebugAutoRelaseResponse = IGenericResponse<'debugAutoRelase', string>

export async function debugAutoRelase({ claimId }: { claimId: string }): Promise<IDebugAutoRelaseResponse> {
  const query = `
    query Query($claimId: String!) {
      debugAutoRelase(claimId: $claimId)
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { claimId }) as Promise<IDebugAutoRelaseResponse>
}
