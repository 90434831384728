import { Instance, types } from 'mobx-state-tree'

import { AssetUser } from '../AssetUser.model'

import { Image } from '../../general/Image.model'
import { MicrolicensingPropsConfig } from '../MicrolicensingPropsConfig.model'
import { ParticipantWithRole } from '../ParticipantWithRole.model'

export const ArtTrackBasic = types.model({
  uuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  upc: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  users: types.array(AssetUser),
  isrc: types.maybeNull(types.string),
  artist: types.maybeNull(types.string),
  participants: types.maybeNull(types.array(ParticipantWithRole)),
  genreUuids: types.maybeNull(types.array(types.string)),
  rightsHolders: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  assetId: types.maybeNull(types.string),
  customId: types.maybeNull(types.string),
  csAssetId: types.maybeNull(types.string),
  csCustomId: types.maybeNull(types.string),
  csSyncedAt: types.maybeNull(types.string),
  publishDate: types.maybeNull(types.string),
  sites: types.array(types.string),
  fileName: types.maybeNull(types.string),
  storagePath: types.maybeNull(types.string),
  uploadFinished: types.maybeNull(types.boolean),
  importSource: types.maybeNull(types.string),
  approvalComment: types.maybeNull(types.string), // ?
  approvedAt: types.maybeNull(types.string), // ?
  rejectedAt: types.maybeNull(types.string), // ?
  approvalEmailSentAt: types.maybeNull(types.string), // ?
  titleVersion: types.maybeNull(types.string),
  recordingLocation: types.maybeNull(types.string),
  originalReleaseDate: types.maybeNull(types.string),
  preorderDate: types.maybeNull(types.string), // ?
  releaseDate: types.maybeNull(types.string), // ?
  metadataLanguage: types.maybeNull(types.string),
  displayImagePath: types.maybeNull(types.string), // ?
  bpm: types.maybeNull(types.number),
  iswc: types.maybeNull(types.string),
  audioLanguage: types.maybeNull(types.string),
  album: types.maybeNull(types.string),
  isCover: types.maybeNull(types.boolean),
  releaseUuid: types.maybeNull(types.string),
  srExist: types.maybeNull(types.boolean),
  images: types.optional(types.array(Image), []),
  description: types.maybeNull(types.string), // ?
  downloadUrl: types.maybeNull(types.string),
  fileSizeInBytes: types.maybeNull(types.number),
  isCompositionShare: types.maybeNull(types.boolean), // ?
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  advisory: types.maybeNull(types.string),
  previewStart: types.maybeNull(types.number),
  duration: types.maybeNull(types.number),
  validationErrors: types.maybeNull(
    types.array(
      types.model({
        field: types.string,
        message: types.string,
      })
    )
  ),
  ddexTakedown: types.maybeNull(types.boolean), // ?
  asJson: types.maybeNull(types.string),
  forceAssignIsrc: types.maybeNull(types.boolean),
  microlicensingProps: types.maybeNull(MicrolicensingPropsConfig),
  srAssetUuid: types.maybeNull(types.string),
  srAssetId: types.maybeNull(types.string),
})

export type IArtTrackBasic = Instance<typeof ArtTrackBasic>
