import { Instance, cast, detach, flow, types } from 'mobx-state-tree'

import { APPROVAL_STATES, ASSET_UUID_REGEX, ASSIGNMENT_STATUSES, SPLIT_STATUSES } from '../../../constants'

import { IGetChannelVideosResponse, getChannelVideos } from '../../../api/assets-api/assets/getChannelVideos'
import { Pagination } from '../../general/Pagination.model'
import { ChannelVideo } from './ChannelVideo.model'

export const ChannelVideoList = types
  .model({
    list: types.array(ChannelVideo),
  })
  .volatile(() => ({
    loading: true,
    pagination: Pagination.create({ totalItems: 0 }),
    assetStatesFilter: [] as APPROVAL_STATES[],
    textFilter: '',
    userUuidFilter: '',
    siteUuidFilter: '',
    assignmentStatusFilter: '',
    splitStatusFilter: '',
    compositionShareFilter: null as null | boolean,
    defaultAssetsMode: true, // ignore any client filters for the be when getting assets
    hasDefaultPlatformStates: true, // if true will send All approve state filter instead of null
    selectedAssets: [],
  }))
  .actions(self => ({
    setAssetStatesFilter(states: APPROVAL_STATES[]) {
      self.assetStatesFilter = states
    },

    setTextFilter(text: string) {
      self.textFilter = text
    },

    setSiteFilter(site: string) {
      self.siteUuidFilter = site
    },
    setUserUuidFilter(userUuid: string | null) {
      self.userUuidFilter = userUuid || ''
    },
    setAssignmentFilter(assignment: string) {
      self.assignmentStatusFilter = assignment
    },
    setSplitFilter(split: string) {
      self.splitStatusFilter = split
    },
    setCompositionShareFilter(state: boolean | null) {
      self.compositionShareFilter = state
    },

    load: flow(function* (loadFragment?: string) {
      try {
        self.loading = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else param = 'search'

        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [param]: self.textFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.assignmentStatusFilter && {
              isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
            }),
            ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
          },
        }

        const resp: IGetChannelVideosResponse = yield getChannelVideos(variables, loadFragment)

        if (resp && resp.data.data?.channelVideos) {
          detach(self.list)
          self.list = cast(resp.data.data.channelVideos.channelVideos)
          self.pagination.setTotalItems(resp.data.data.channelVideos.total)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))
  .views(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.uuid === uuid)
    },
  }))

export type IChannelVideoList = Instance<typeof ChannelVideoList>
