import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { observer } from 'mobx-react-lite'
import './richTextStyle.scss'

const extensions = [StarterKit]

const RichTextPreview = ({ content }: { content: string }) => {
  const editor = useEditor(
    {
      extensions,
      content,
      editable: false,
    },
    [content]
  )

  return <EditorContent editor={editor} />
}

export default observer(RichTextPreview)
