import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IAssignUserToAssetsProps {
  userUuid: string
  assetUuids: string[]
}

export type IAssignUserToAssetsResponse = IGenericResponse<'assignUserToAssets', boolean>

export async function assignUserToAssets(props: IAssignUserToAssetsProps): Promise<IAssignUserToAssetsResponse> {
  const query = `
    mutation assignUserToAssets ($userUuid: String!, $assetUuids: [String!]!) {
      assignUserToAssets (
        userUuid: $userUuid,
        assetUuids: $assetUuids
      ) 
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IAssignUserToAssetsResponse>
}
