import { Instance, types } from 'mobx-state-tree'

export const Label = types
  .model({
    uuid: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
  })
  .named('Label')

export type ILabel = Instance<typeof Label>
