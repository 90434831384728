import moment from 'moment'
import { customAlphabet } from 'nanoid'

import {
  getUploadUrl,
  IGetUploadUrlObjectResponse,
  IGetUploadUrlProps,
  IGetUploadUrlResponse,
} from '../api/assets-api/getUploadUrl'
import { ASSET_TYPE, AUDIO_FILE_EXTENSIONS, VIDEO_FILE_EXTENSIONS } from '../constants'
import { IAsset } from '../models/assets/Asset.model'

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${(bytes / k ** i).toFixed(dm)} ${sizes[i]}`
}

export const generateUuid = (prefix = ''): string => {
  const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)
  return `${prefix}-${nanoid()}`
}

export function getPresentableImgUrl(url: string): string {
  return url.match(/user.*/)?.[0] || ''
}

export function stripS3PreSignedFromUrlIfPresent(url: string): string {
  return url?.split('?X-Amz-Algorithm')[0]
}

interface IAssetOrFile {
  asset?: IAsset
  file?: File
}

//! check use after track separation
export function isAudio({ asset, file }: IAssetOrFile): boolean {
  if (file) {
    return AUDIO_FILE_EXTENSIONS.map(extension => new RegExp(`${extension}$`))
      .map(pattern => !!file.name.match(pattern))
      .reduce((arr, total) => arr || total, false)
  }
  if (asset && [ASSET_TYPE.SOUND_RECORDING].some(item => asset.assetTypes?.includes(item))) {
    return true
  }
  return false
}

export function isVideo({ asset, file }: IAssetOrFile): boolean {
  if (file) {
    return VIDEO_FILE_EXTENSIONS.map(extension => new RegExp(`${extension}$`))
      .map(pattern => !!file.name.match(pattern))
      .reduce((arr, total) => arr || total, false)
  }
  if (asset && [ASSET_TYPE.WEB].some(item => asset.assetTypes?.includes(item))) {
    return true
  }
  return false
}

export function iconCreator({ asset, file }: IAssetOrFile): string {
  if (!asset?.fileName && !file?.name) {
    return 'uil-file-times'
  }
  if (isAudio({ asset, file })) {
    return 'uil-music'
  }
  if (isVideo({ asset, file })) {
    return 'uil-film'
  }
  return 'uil-file-times'
}

export const rangeDesc = (start: number, end: number): number[] => {
  const result = []
  for (let i = start; i >= end; i -= 1) {
    result.push(i)
  }
  return result
}

export const getUploadDetails = async (props: IGetUploadUrlProps): Promise<IGetUploadUrlObjectResponse> => {
  try {
    const resp: IGetUploadUrlResponse = await getUploadUrl(props)
    return resp?.data.data?.getUploadUrl ?? null
  } catch (e) {
    console.error(e)
    return null
  }
}

export function getLabelFromAssetState(state: string) {
  const text = state.replaceAll('_', ' ').toLowerCase()
  return text[0].toUpperCase() + text.slice(1)
}

export function getClaimExpiryIn(expiresAt: string | null): string {
  if (!expiresAt) return '-'

  const expiresAtDate = moment(expiresAt).utc().startOf('day')
  const currentDate = moment().utc().startOf('day')

  if (expiresAtDate.isSame(currentDate, 'day')) return 'Today'

  return expiresAtDate.from(currentDate)
}
