import { Instance, cast, detach, flow, types } from 'mobx-state-tree'
import moment from 'moment'
import { ArtTrackList, ReleaseList } from '..'
import {
  ANALYTICS_GROUP_BY,
  IGetAnalyticsProps,
  IGetAnalyticsResponse,
  getAnalyticsData,
} from '../../api/assets-api/analytics/getAnalyticsData'
import {
  IGetTopStreamedReleasesProps,
  IGetTopStreamedReleasesResponse,
  getTopStreamedReleases,
} from '../../api/assets-api/analytics/getTopStreamedReleases'
import {
  IGetTopStreamedSongsProps,
  IGetTopStreamedSongsResponse,
  getTopStreamedSongs,
} from '../../api/assets-api/analytics/getTopStreamedSongs'
import { STORES, SortDirection } from '../../constants'
import { Pagination } from '../general/Pagination.model'
import { Analytics } from './Analytics.model'

export const AnalyticsList = types
  .model({
    list: types.array(Analytics),
  })
  .volatile(() => ({
    loading: false,
    loadingDetails: false,
    userUuidFilter: '',
    assetUuidFilter: '',
    storesNameFilter: '',
    countryFilter: '',
    dailyStartDate: '',
    dailyEndDate: '',
    pagination: Pagination.create({ totalItems: 0, perPage: 20 }),
  }))
  .actions(self => ({
    setLoadingDetails(value: boolean) {
      self.loadingDetails = value
    },
    setUserUuidFilter(text: string) {
      self.userUuidFilter = text.toLowerCase()
    },
    setAssetFilter(assetUuid: string) {
      self.assetUuidFilter = assetUuid.toLowerCase()
    },
    setCountryFilter(countryCode: string) {
      self.countryFilter = countryCode
    },
    setStoresFilter(storeName: string) {
      self.storesNameFilter = storeName
    },
    setDailyStartDate(date: moment.Moment) {
      self.dailyStartDate = moment(date).format('YYYY-MM-DD')
    },
    setDailyEndDate(date: moment.Moment) {
      self.dailyEndDate = moment(date).format('YYYY-MM-DD')
    },
  }))

  .views(self => ({
    get totalStreams() {
      return self.list.map(data => data.streams).reduce((acc, newVal) => (acc || 0) + (newVal || 0), 0)
    },
    get totalEarnings() {
      return self.list.map(data => data.earningEstimate).reduce((acc, newVal) => (acc || 0) + (newVal || 0), 0)
    },
    getByUuid(uuid: string) {
      return self.list.find(item => item.assetUuid === uuid)
    },

    getStoreOptions() {
      const storesList = [STORES.Amazon, STORES.Apple, STORES.Spotify, STORES.Deezer, STORES.Pandora, STORES.SoundCloud]

      const usedStoresValues = self.list.map(data => data.store)

      const isDisabled = (store: STORES): boolean => {
        if (self.storesNameFilter === null) return false
        if (self.storesNameFilter.length > 0) return false
        if (usedStoresValues.length === 0) return false

        return !usedStoresValues.includes(store)
      }

      const storesOptions = storesList.map(store => ({
        label: store,
        value: store,
        isdisabled: isDisabled(store),
      }))

      return storesOptions
    },
    getDatesArray(format = 'YYYY-MM-DD') {
      const datesArray: string[] = []
      const daysBetweenDates = Math.abs(moment(self.dailyStartDate).diff(self.dailyEndDate, 'days')) + 1

      for (let i = 0; i < daysBetweenDates; i += 1) {
        datesArray.push(moment(self.dailyStartDate).add(i, 'days').format(format))
      }

      return datesArray
    },
  }))
  .views(self => ({
    getEarningsChartData() {
      const chartData = {
        categories: [] as string[],
        data: [] as { name: string; data: number[] }[],
      }

      // Categories
      const datesArray = self.getDatesArray()
      chartData.categories = datesArray.map(date => moment(date).format('DD-MMM'))

      // Data
      const earningData = {
        name: 'Distribution Estimated Earnings',
        data: [...new Array(datesArray.length).fill(0)],
      }

      self.list.forEach(entry => {
        const formattedDate = moment(entry.date).format('YYYY-MM-DD')
        if (!self.getDatesArray().includes(formattedDate)) return

        const dateIndex = Math.abs(moment(entry.date).diff(self.dailyStartDate, 'days'))
        earningData.data[dateIndex] += entry.earningEstimate ?? 0
      })

      chartData.data.push(earningData)

      return chartData
    },
    getChartData() {
      const chartData = {
        categories: [] as string[],
        data: [] as { name: string; data: number[] }[],
      }

      // Categories
      const datesArray = self.getDatesArray()
      chartData.categories = datesArray.map(date => moment(date).format('DD-MMM'))

      // Data
      const streamsData = {
        name: 'Streams',
        data: [...new Array(datesArray.length).fill(0)],
      }
      const offlinePlaysData = {
        name: 'Offline Plays',
        data: [...new Array(datesArray.length).fill(0)],
      }
      const totalData = {
        name: 'Total Views',
        data: [...new Array(datesArray.length).fill(0)],
      }

      self.list.forEach(entry => {
        const formattedDate = moment(entry.date).format('YYYY-MM-DD')
        if (!self.getDatesArray().includes(formattedDate)) return

        const dateIndex = Math.abs(moment(entry.date).diff(self.dailyStartDate, 'days'))

        streamsData.data[dateIndex] += entry.streams ?? 0
        offlinePlaysData.data[dateIndex] += entry.offlinePlays ?? 0
        totalData.data[dateIndex] += entry.totalPlays ?? 0
      })

      chartData.data.push(totalData, streamsData, offlinePlaysData)

      return chartData
    },
  }))
  .views(self => ({
    get listPaged() {
      const list = [...self.list]
      if (self.pagination.sort) {
        list.sort((a, b) => {
          if (self.pagination.sort === 'totalPlays') {
            if (Number(a.totalPlays) < Number(b.totalPlays)) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
            if (Number(a.totalPlays) > Number(b.totalPlays)) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
          }

          if (self.pagination.sort === 'earningEstimate') {
            if (Number(a.earningEstimate) < Number(b.earningEstimate)) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
            if (Number(a.earningEstimate) > Number(b.earningEstimate)) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
          }
          return 0
        })
      }

      return list.slice(
        (self.pagination.page - 1) * self.pagination.perPage,
        self.pagination.page * self.pagination.perPage
      )
    },
  }))
  .actions(self => ({
    loadArtTrackDetails: flow(function* () {
      try {
        self.loadingDetails = true
        const fragment = `
          tracks {
            uuid
            title
            artist
            users {
              userUuid
              revSplitUuid
              percentage
              isOwner
            }
            releaseUuid
          }
        `
        const multipleIdsFilter = self.listPaged
          .map(analytics => analytics.assetUuid ?? '')
          .filter(uuid => uuid !== '' && uuid !== 'true')

        if (multipleIdsFilter.length < 1) return

        const artTrackList = ArtTrackList.create()
        artTrackList.setMultipleIdsFilter(multipleIdsFilter)
        artTrackList.pagination.setPerPage(100)

        yield artTrackList.load(fragment)

        self.list.forEach(analytics => {
          const asset = artTrackList.byUuid(analytics.assetUuid)
          if (asset?.title) analytics.setTitle(asset.title)
          if (asset?.artist) analytics.setArtist(asset.artist)
          if (asset?.getOwnerUuid()) analytics.setUserUuid(asset.getOwnerUuid() || '')
          if (asset?.releaseUuid) analytics.setReleaseUuid(asset.releaseUuid)
        })
        self.loadingDetails = false
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingDetails = false
      }
    }),

    loadReleaseDetails: flow(function* () {
      try {
        self.loadingDetails = true
        const fragment = `
          releases {
            uuid
            title
            artist
            users {
              userUuid
              revSplitUuid
              percentage
              isOwner
            }
            images {
               thumbnailDownloadUrl
            }
           
          }
        `
        const multipleIdsFilter = self.listPaged
          .map(analytics => analytics.releaseUuid ?? '')
          .filter(uuid => uuid !== '' && uuid !== 'true')

        if (multipleIdsFilter.length < 1) return

        const releaseList = ReleaseList.create()
        releaseList.setMultipleIdsFilter(multipleIdsFilter)
        releaseList.pagination.setPerPage(100)

        yield releaseList.load(fragment)

        self.list.forEach(analytics => {
          const asset = releaseList.byUuid(analytics.releaseUuid)
          if (asset?.title) analytics.setTitle(asset.title)
          if (asset?.artist) analytics.setArtist(asset.artist)
          if (asset?.getOwnerUuid()) analytics.setUserUuid(asset.getOwnerUuid() || '')
          if (asset?.images?.length && asset.images[0].thumbnailDownloadUrl)
            analytics.setThumbnail(asset.images[0].thumbnailDownloadUrl)
        })
        self.loadingDetails = false
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingDetails = false
      }
    }),
  }))
  .actions(self => ({
    getTopStreamedSongs: flow(function* () {
      try {
        self.loading = true
        const variables: IGetTopStreamedSongsProps = {
          filters: {
            ...(self.dailyStartDate && { startDate: self.dailyStartDate }),
            ...(self.dailyEndDate && { endDate: self.dailyEndDate }),
          },
          limit: 5,
        }
        const resp: IGetTopStreamedSongsResponse = yield getTopStreamedSongs(variables)
        if (resp && resp.data.data) {
          detach(self.list)
          self.list = cast(resp.data.data.topStreamedSongs)
        }
        self.loading = false
      } catch (e) {
        self.loading = false
        console.error(e)
      }
    }),
    getTopStreamedReleases: flow(function* () {
      try {
        self.loading = true
        const variables: IGetTopStreamedReleasesProps = {
          filters: {
            ...(self.dailyStartDate && { startDate: self.dailyStartDate }),
            ...(self.dailyEndDate && { endDate: self.dailyEndDate }),
          },
          limit: 5,
        }
        const resp: IGetTopStreamedReleasesResponse = yield getTopStreamedReleases(variables)
        if (resp && resp.data.data) {
          detach(self.list)
          self.list = cast(resp.data.data.topStreamedReleases)
        }
        self.loading = false
      } catch (e) {
        self.loading = false
        console.error(e)
      }
    }),
    getAnalytics: flow(function* (groupByList: ANALYTICS_GROUP_BY[] = []) {
      try {
        detach(self.list)

        self.list = cast([])
        self.loading = true

        const params: IGetAnalyticsProps = {
          filters: {
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.assetUuidFilter && { assetUuid: self.assetUuidFilter }),
            ...(self.storesNameFilter && { store: self.storesNameFilter }),
            ...(self.countryFilter && { countryCode: self.countryFilter }),
            ...(self.dailyStartDate && { startDate: self.dailyStartDate }),
            ...(self.dailyEndDate && { endDate: self.dailyEndDate }),
          },
          groupBy: groupByList,
        }

        const resp: IGetAnalyticsResponse = yield getAnalyticsData(params)

        if (resp && resp.data.data) {
          detach(self.list)
          self.list = cast(resp.data.data.analyticsData)
          self.pagination.setTotalItems(self.list.length)
        }

        if (groupByList.includes(ANALYTICS_GROUP_BY.RELEASE_UUID)) {
          self.loadReleaseDetails()
        }

        if (groupByList.includes(ANALYTICS_GROUP_BY.ASSET_UUID)) {
          yield self.loadArtTrackDetails()
        }

        self.loading = false
      } catch (e) {
        self.loading = false
        console.error(e)
      }
    }),
  }))

export type IAnalyticsList = Instance<typeof AnalyticsList>
