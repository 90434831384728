import { Instance, types } from 'mobx-state-tree'

export const ClaimManager = types.model({
  uuid: types.identifier,
  default: types.maybeNull(types.boolean),
  managerUuid: types.maybeNull(types.string),
  userUuid: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export type IClaimManager = Instance<typeof ClaimManager>
