import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { ANALYTICS_ASSET_TYPE, SERVICE_NAME, TOP_ASSETS_TYPE } from '../../../constants'
import { IAssetBasic } from '../../../models'

const defaultFragment = `
    asset  
    {
      uuid
      title
      releaseUuids
    }
    release {
      uuid
      title
    }
    channelVideo {
      uuid
      title
    }
    track {
      uuid
      title
      releaseUuid
    }
  
    claims  
    earnings 
    views  
`

export interface IGetTopStreamedSongsProps {
  sortBy: TOP_ASSETS_TYPE
  limit: number
  type: ANALYTICS_ASSET_TYPE
  userUuid?: string
}

export type IGetTopStreamedSongsResponse = IGenericResponse<
  'topAssets',
  {
    asset: IAssetBasic
    claims?: number | null
    earnings?: number | null
    views?: number | null
  }[]
>

export async function getTopAssets(
  props: IGetTopStreamedSongsProps,
  fragment: string = defaultFragment
): Promise<IGetTopStreamedSongsResponse> {
  const query = `
    query topAssets( 
            $limit: Float!,
            $sortBy: TopAssetsSortBy!,
            $type: AnalyticsAssetType!,
            $userUuid: String
) {
        topAssets(
          limit:  $limit,
          sortBy: $sortBy,
          type: $type,
          userUuid: $userUuid
          ) {
            ${fragment}
        }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { ...props }) as Promise<IGetTopStreamedSongsResponse>
}
