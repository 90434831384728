import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ISetPermissionsForRoleResponse = IGenericResponse<'setPermissionsForRole', boolean>

interface ISetPermissionsForRoleProps {
  roleUuid: string
  permissionNames: string[]
}

export async function setPermissionsForRole(
  variables: ISetPermissionsForRoleProps
): Promise<ISetPermissionsForRoleResponse> {
  const query = `
    mutation setPermissions ($roleUuid: String!, $permissionNames:[String!]!) {
      setPermissionsForRole(roleUuid: $roleUuid, permissionNames: $permissionNames)
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, variables)) as ISetPermissionsForRoleResponse
}
