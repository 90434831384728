import { cast, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { AdminShift } from '..'
import { getAdminShifts, IGetAdminShiftsResponse } from '../../api/users-api/adminShifts/getAdminShifts'
import { IStartShiftResponse, startShift } from '../../api/users-api/adminShifts/startShift'
import { Pagination } from '../general/Pagination.model'

export const AdminShiftList = types
  .model({
    list: types.array(AdminShift),
  })
  .volatile(() => ({
    loading: false,
    pagination: Pagination.create({ totalItems: 1 }),
    userUuidFilter: '',
    siteUuidFilter: '',
    statusFilter: '',
  }))
  .actions(self => ({
    getByUuid(uuid: string | null | undefined) {
      if (uuid) {
        return self.list.find(user => user.uuid === uuid)
      }
      return null
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const resp: IGetAdminShiftsResponse = yield getAdminShifts({
          filters: {
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.statusFilter && { status: self.statusFilter }),
          },
          pagination: self.pagination,
        })

        if (resp && resp.data.data && resp.data.data.adminShifts) {
          self.list = cast(resp.data.data.adminShifts)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    startShift: flow(function* () {
      try {
        self.loading = true
        const response: IStartShiftResponse = yield startShift()

        if (response?.data.data?.startShift) {
          toast.success('The shift has been successfully started!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IAdminShiftList = Instance<typeof AdminShiftList>
