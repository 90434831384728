import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'

import clientWrapper from '../../clientWrapper'

export interface IDeactivateUserProps {
  userUuid: string
}

export type IDeactivateUserResponse = IGenericResponse<'deactivateUser', boolean>

export async function deactivateUser(props: IDeactivateUserProps): Promise<IDeactivateUserResponse> {
  const query = `
   mutation deactivateUser($userUuid: String!) {
    deactivateUser(userUuid: $userUuid)
  }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<IDeactivateUserResponse>
}
