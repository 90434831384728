import { Instance, types } from 'mobx-state-tree'

import { DELIVERY_PLATFORM } from '../../constants'

export interface IParticipantExternalLinkFormData {
  platformName: DELIVERY_PLATFORM
  platformUrl: string
  imageUrl?: string
}

export interface IParticipantFormData {
  uuid?: string
  name?: string
  externalLinksToStores?: IParticipantExternalLinkFormData[]
}

export const ParticipantBasic = types.model({
  uuid: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  externalLinksToStores: types.maybeNull(
    types.array(
      types.model({
        platformName: types.enumeration(Object.values(DELIVERY_PLATFORM)),
        platformUrl: types.string,
        imageUrl: types.maybeNull(types.string),
      })
    )
  ),
})

export type IParticipantBasic = Instance<typeof ParticipantBasic>
