import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { IClaim } from '../../../models/claims/Claim.model'

import { SERVICE_NAME } from '../../../constants'
import { claimRequestBasicFragment } from './fragments'

export interface IBulkClaimProps {
  claimIds: string[]
  newStatus: string
}

export interface IBulkClaimResponse {
  claimId: string
  isUpdated: boolean
  claim?: IClaim
  error?: string
}

export type IBulkUpdateClaimIssueStatusResponse = IGenericResponse<'bulkUpdateClaimIssuesStatus', IBulkClaimResponse[]>

export async function bulkUpdateClaimIssuesStatus(
  { claimIds, newStatus }: IBulkClaimProps,
  fragment: string = claimRequestBasicFragment
): Promise<IBulkUpdateClaimIssueStatusResponse> {
  const query = `
    mutation bulkUpdateClaimIssuesStatus ($newStatus: String!, $claimIds: [String!]!) {
      bulkUpdateClaimIssuesStatus (newStatus: $newStatus, claimIds: $claimIds) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, {
    newStatus,
    claimIds,
  }) as Promise<IBulkUpdateClaimIssueStatusResponse>
}
