import { SERVICE_NAME } from '../../constants'
import { IAsset } from '../../models'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

type ISpotifyCreateAssetsFromAlbumsProps = {
  userUuid?: string
  albumIdUrl: string[] // ! this should updated on the BE to "albumId"
}

export type ISpotifyCreateAssetsFromAlbumsResponse = IGenericResponse<'spotifyCreateAssetsFromAlbums', IAsset[]>

export async function spotifyCreateAssetsFromAlbums(
  variables: ISpotifyCreateAssetsFromAlbumsProps
): Promise<ISpotifyCreateAssetsFromAlbumsResponse> {
  const query = `
    mutation spotifyCreateAssetsFromAlbums($userUuid: String, $albumIdUrl: [String!]!) {
      spotifyCreateAssetsFromAlbums(albumIdUrl: $albumIdUrl, userUuid: $userUuid) {
        uuid
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<ISpotifyCreateAssetsFromAlbumsResponse>
}
