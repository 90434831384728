import { Instance, types } from 'mobx-state-tree'
import { REVENUE_SHARE_TYPE } from '../../constants/payments'

export const RevenueShare = types
  .model({
    uuid: types.maybeNull(types.string),
    entityUuid: types.maybeNull(types.string),
    siteUuid: types.maybeNull(types.string),
    type: types.enumeration(Object.values(REVENUE_SHARE_TYPE)),
    percentage: types.maybeNull(types.number),
    bonusPercentage: types.maybeNull(types.number),
    futurePercentage: types.maybeNull(types.number),
    transactionPercentage: types.maybeNull(types.number),
    progressiveThreshold: types.maybeNull(types.number),
    fromDate: types.maybeNull(types.string),
  })
  .actions(self => ({
    setType(t: REVENUE_SHARE_TYPE) {
      self.type = t
    },
    setSiteUuid(site: string | null) {
      self.siteUuid = site
    },
    setEntityUuid(uuid: string) {
      self.entityUuid = uuid
    },
  }))

export type IRevenueShare = Instance<typeof RevenueShare>
