import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { ENV, EnvLocations } from '@epic-front/common/src/constants'
import { getSiteLogoLight } from '@helpers'

import ProfileDropdown from '../blocks/ProfileDropdown'
import defaultProfilePic from '../assets/images/account.svg'

import { useStore } from '../hooks/useStore'
import { Modal } from '../blocks'
import ResetPassword from '../pages/profile/ResetPassword'

// get the profile menu
const ProfileMenus = [
  {
    label: 'My Account',
    icon: 'uil-user-circle',
    redirectTo: '/account/my-account',
  },

  {
    label: 'Logout',
    icon: 'uil-exit',
    redirectTo: '/account/logout',
  },
]

// get the notifications
/*
const Notifications = [
  {
    id: 1,
    text: 'Comment title or notification goes here',
    subText: '10/12/2021',
    icon: 'uil-bell',
    bgColor: 'primary',
  },
  {
    id: 2,
    text: 'Warning or important notification',
    subText: '10/12/2021',
    icon: 'uil-bell',
    bgColor: 'warning',
  },
  {
    id: 3,
    text: 'Confirmation notification with success',
    subText: '10/12/2021',
    icon: 'uil-bell',
    bgColor: 'success',
  },
  {
    id: 4,
    text: 'Comment title or notification goes here',
    subText: '10/12/2021',
    icon: 'uil-bell',
    bgColor: 'primary',
  },
]
*/

type TopbarProps = {
  hideLogo?: boolean
  navCssClasses?: string
  openLeftMenuCallBack?: () => void
}

const Topbar = ({ hideLogo, navCssClasses, openLeftMenuCallBack }: TopbarProps): JSX.Element => {
  // const { layout} = useStore()
  const { auth } = useStore()

  const navbarCssClasses = navCssClasses || ''
  const containerCssClasses = !hideLogo ? 'container-fluid' : ''

  const envString = ' Environment'

  /**
   * Toggle the left menu when having mobile screen
   */
  const handleLeftMenuCallBack = () => {
    if (openLeftMenuCallBack) openLeftMenuCallBack()
  }

  /**
   * Toggles the right sidebar
   */
  // const handleRightSideBar = () => {
  //   layout.toggleRightSidebar(true)
  // }

  return (
    <>
      <div className="navbar-custom">
        {ENV === EnvLocations.STG || ENV === EnvLocations.DEV ? (
          <div
            className={classNames(
              'position-absolute top-50 start-0 translate-middle-y ms-5 ms-lg-3 badge fs-6 px-2 p-0 rounded-pill d-hidden overflow-hidden lh-lg shadow-sm',
              navbarCssClasses,
              {
                'bg-success': ENV === EnvLocations.STG,
                'bg-warning': ENV === EnvLocations.DEV,
              }
            )}
          >
            {ENV + envString}
          </div>
        ) : (
          <Link to="/" className="position-absolute top-50 start-0 translate-middle-y ms-5 ms-lg-3 d-lg-none">
            <img src={getSiteLogoLight()} alt="logo" height="50" />
          </Link>
        )}

        <div className={containerCssClasses}>
          <ul className="list-unstyled topbar-menu float-end mb-0">
            {/* Temporally disable until these are ready */}
            {/* <li className="notification-list">
              <button
                type="button"
                className="nav-link dropdown-toggle end-bar-toggle arrow-none btn btn-link shadow-none position-relative"
                onClick={handleRightSideBar}
              >
                <i className="dripicons-bell noti-icon" />
                <span className="position-absolute mt-n2 top-50 start-100 translate-middle badge rounded-pill bg-warning">
                  4<span className="visually-hidden">unread messages</span>
                </span>
              </button>
            </li> */}
            {/* <li className="dropdown notification-list ms-2">
              <NotificationDropdown notifications={Notifications} />
            </li> */}

            <li className="dropdown notification-list">
              <ProfileDropdown
                profilePic={auth.user?.userImage || defaultProfilePic}
                menuItems={ProfileMenus}
                username={auth.user?.firstLastName || ''}
                //  userTitle={capitalize(auth.user?.roles.map(role => role.displayName)[0] || '')}
              />
            </li>
          </ul>

          <button type="button" className="button-menu-mobile open-left disable-btn" onClick={handleLeftMenuCallBack}>
            <i className="uil-bars" />
          </button>
        </div>
      </div>

      <Modal size="lg" title="Update your password" onHide={() => {}} isOpen={!!auth.user?.shouldChangePassword}>
        <div className="mb-n3">
          <h5 className="text-center mb-4">
            <i className=" uil-shield-exclamation display-6 text-warning" />
            <br />
            Your password is too weak!
            <br />
            Please update it.
          </h5>
          <ResetPassword btnClass="w-100" />
        </div>
      </Modal>
    </>
  )
}

export default observer(Topbar)
