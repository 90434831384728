import {
  ADMIN,
  ELITE_ALLIANCE,
  ELITE_ALLIANCE_MUSIC,
  EPIC_ELITE,
  PLATFORM,
  SONGMATE,
  SONGPITCH,
} from '@epic-front/common/src/constants'
import { GenreList } from '@epic-front/common/src/models/assets'
import { ChannelList } from '@epic-front/common/src/models/assets/ChannelList.model'
import { CmsList } from '@epic-front/common/src/models/assets/CmsList.model'
import { ContentIdClaimList } from '@epic-front/common/src/models/claims/ContentIdClaimList.model'
import { PaymentMethodList } from '@epic-front/common/src/models/payment/PaymentMethodList.model'
import { Site } from '@epic-front/common/src/models/sites/Site.model'
import { SiteList } from '@epic-front/common/src/models/sites/SiteList.model'
import Auth from '@epic-front/common/src/models/users/Auth.model'
import { LabelList } from '@epic-front/common/src/models/users/LabelList.model'
import { RoleList } from '@epic-front/common/src/models/users/RoleList.model'
import { UserGroupList } from '@epic-front/common/src/models/users/UserGroupList.model'
import { UserList } from '@epic-front/common/src/models/users/UserList.model'
import { onSnapshot } from 'mobx-state-tree'
import { Layout } from './models/Layout.model'
import RootStore from './models/RootStore.model'

// Store the Auth object in localstorage
let initialAuthState = {}
if (localStorage.getItem('auth')) {
  const jsonAuth = JSON.parse(localStorage.getItem('auth') || '{}')
  if (Auth.is(jsonAuth)) {
    initialAuthState = jsonAuth
  }
}

const auth = Auth.create(initialAuthState)
// hydrate the localstorage auth object
onSnapshot(auth, snapshot => {
  localStorage.setItem('auth', JSON.stringify(snapshot))
})

if (auth.isAdminOnClientSite) {
  auth.logout()
}

let initStoreProps

const initStorePropsDefault = {
  auth,
  layout: Layout.create(),
  site: Site.create(),
}

switch (PLATFORM) {
  case ADMIN:
    initStoreProps = {
      ...initStorePropsDefault,
      labels: LabelList.create(),
      userGroups: UserGroupList.create(),
      sites: SiteList.create(),
      cmses: CmsList.create(),
      roles: RoleList.create(),
      users: UserList.create(),
      channels: ChannelList.create(),
      paymentMethods: PaymentMethodList.create(),
      genres: GenreList.create(),
    }
    break
  case SONGMATE:
    initStoreProps = {
      ...initStorePropsDefault,
      channels: ChannelList.create(),
      genres: GenreList.create(),
      paymentMethods: PaymentMethodList.create(),
      activeClaims: ContentIdClaimList.create(),
    }
    break
  case EPIC_ELITE:
    initStoreProps = {
      ...initStorePropsDefault,
      channels: ChannelList.create(),
      paymentMethods: PaymentMethodList.create(),
      genres: GenreList.create(),
    }
    break
  case ELITE_ALLIANCE:
    initStoreProps = {
      ...initStorePropsDefault,
      channels: ChannelList.create(),
      paymentMethods: PaymentMethodList.create(),
    }
    break
  case ELITE_ALLIANCE_MUSIC:
    initStoreProps = {
      ...initStorePropsDefault,
      paymentMethods: PaymentMethodList.create(),
    }
    break
  case SONGPITCH:
    initStoreProps = {
      ...initStorePropsDefault,
      genres: GenreList.create(),
      channels: ChannelList.create(),
      paymentMethods: PaymentMethodList.create(),
    }
    break
  default:
    console.error('SITE IS NOT SETUP')
    break
}

const store = RootStore.create(initStoreProps)

export default store
