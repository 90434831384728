import { Instance, types, flow, cast } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { SpotifyAlbum } from './SpotifyAlbum.model'
import { ISpotifyGetAlbumsResponse, spotifyGetAlbums } from '../../api/assets-api/spotifyGetAlbums'
import {
  ISpotifyCreateAssetsFromAlbumsResponse,
  spotifyCreateAssetsFromAlbums,
} from '../../api/assets-api/spotifyCreateAssetsFromAlbums'

export const SpotifyAlbumList = types
  .model({
    list: types.array(SpotifyAlbum),
  })
  .volatile(() => ({
    filterUrl: '',
    loading: false,
    creatingAssets: false,
    selectedItemsIds: [] as string[],
  }))
  .named('SpotifyAlbumList')
  .actions(self => ({
    setFilterUrl: (url: string) => {
      self.filterUrl = url
    },
    toggleAll: () => {
      self.selectedItemsIds = self.list.map(album => album.id)
    },
    toggleNone: () => {
      self.selectedItemsIds = []
    },
    toggleSelected: (itemId: string) => {
      let updatedSelection = [...self.selectedItemsIds]
      const i = self.selectedItemsIds.indexOf(itemId)
      if (i > -1) {
        updatedSelection = updatedSelection.filter(item => item !== itemId)
      }
      if (i === -1) {
        updatedSelection = [...updatedSelection, itemId]
      }

      self.selectedItemsIds = updatedSelection
    },
  }))
  .actions(self => ({
    findAlbums: flow(function* () {
      try {
        self.loading = true

        const resp: ISpotifyGetAlbumsResponse = yield spotifyGetAlbums({ artistAlbumUrl: self.filterUrl })
        if (resp && resp.data.data?.spotifyGetAlbums) {
          self.list = cast(resp.data.data.spotifyGetAlbums)
          self.toggleAll()
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
    createAssetsFromAlbum: flow(function* (forUser?: string) {
      try {
        self.creatingAssets = true

        const resp: ISpotifyCreateAssetsFromAlbumsResponse = yield spotifyCreateAssetsFromAlbums({
          albumIdUrl: self.selectedItemsIds,
          userUuid: forUser || undefined,
        })
        if (resp && resp.data.data?.spotifyCreateAssetsFromAlbums) {
          toast.success(`${resp.data.data?.spotifyCreateAssetsFromAlbums.length} assets created.`)
          self.toggleNone()
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.creatingAssets = false
      }
    }),
  }))

export type ISpotifyAlbumList = Instance<typeof SpotifyAlbumList>
