import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IAssignOwnerToReleasesProps {
  ownerUuid: string
  releaseUuids: string[]
}

export type IAssignOwnerToReleasesResponse = IGenericResponse<'assignOwnerToReleases', boolean>

export async function assignOwnerToReleases(
  props: IAssignOwnerToReleasesProps
): Promise<IAssignOwnerToReleasesResponse> {
  const query = `
    mutation assignOwnerToReleases ($ownerUuid: String!, $releaseUuids: [String!]!) {
      assignOwnerToReleases (
        ownerUuid: $ownerUuid,
        releaseUuids: $releaseUuids
      ) 
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IAssignOwnerToReleasesResponse>
}
