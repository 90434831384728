import { createElement } from 'react'
import { Resolver, SubmitHandler, useForm } from 'react-hook-form'

type VerticalFromProps = {
  defaultValues?: { [x: string]: string | number } | undefined
  resolver?: Resolver<
    {
      [x: string]: string | number
    },
    Record<string, unknown>
  >
  children?: JSX.Element[] | JSX.Element
  onSubmit: SubmitHandler<{
    [x: string]: string | number
  }>
  formClass?: string
}

const VerticalForm = ({ defaultValues, resolver, children, onSubmit, formClass }: VerticalFromProps): JSX.Element => {
  /*
   * form methods
   */
  const methods = useForm({ defaultValues, resolver })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formClass} noValidate>
      {Array.isArray(children)
        ? children.map(child => {
            return child.props && child.props.name
              ? createElement(child.type, {
                  ...{
                    ...child.props,
                    register,
                    key: child.props.name,
                    errors,
                    control,
                  },
                })
              : child
          })
        : children}
    </form>
  )
}

export default VerticalForm
