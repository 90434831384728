import { observer } from 'mobx-react-lite'
import { useState, useCallback, useEffect, createContext, ReactNode } from 'react'

export const WizardContext = createContext({
  section: 1,
  next: () => {},
  previous: () => {},
})

type IWizard = {
  children: ReactNode
  defaultSection?: number
}

const Wizard = (props: IWizard): JSX.Element => {
  const [section, setSection] = useState<number>(props.defaultSection || 1)

  useEffect(() => {
    setSection(props.defaultSection || 1)
  }, [props.defaultSection])
  const next = useCallback(() => {
    setSection(oldValue => oldValue + 1)
  }, [])

  const previous = useCallback(() => {
    setSection(oldValue => oldValue - 1)
  }, [])

  return (
    <>
      <WizardContext.Provider value={{ section, next, previous }}>{props.children}</WizardContext.Provider>
    </>
  )
}

export default observer(Wizard)
