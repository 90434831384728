import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IUploadToShopifyResponse = IGenericResponse<'uploadToShopify', boolean>

export async function uploadToShopify(assetUuid: string): Promise<IUploadToShopifyResponse> {
  const query = `
    mutation uploadToShopify ($assetUuid:String!) {
      uploadToShopify (assetUuid: $assetUuid)  
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { assetUuid }) as Promise<IUploadToShopifyResponse>
}
