import { SERVICE_NAME } from '../../constants'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

interface IGetUploadUrlForDDEXProps {
  userUuid: string
  releaseUuid: string
  csvFileName: string
  wavFileNames: string[]
  jpegFileNames: string[]
}

export interface FilePath {
  url: string
  file: string
}

export type IGetUploadUrlForDDEXResponse = IGenericResponse<
  'getUploadUrlForDDEX',
  { csvPath: string; wavPaths: FilePath[]; jpegPaths: FilePath[] }
>

export async function getUploadUrlForDDEX(props: IGetUploadUrlForDDEXProps): Promise<IGetUploadUrlForDDEXResponse> {
  const query = `
    query getUploadUrlForDDEX ($userUuid: String!, $releaseUuid: String!, $csvFileName: String!, $wavFileNames: [String!]!, $jpegFileNames: [String!]!) {
      getUploadUrlForDDEX (userUuid: $userUuid, releaseUuid: $releaseUuid, csvFileName: $csvFileName, wavFileNames: $wavFileNames, jpegFileNames: $jpegFileNames) {
        csvPath
        wavPaths {
          url
          file
        }
        jpegPaths {
          url
          file
        }
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetUploadUrlForDDEXResponse>
}
