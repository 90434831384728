import { SERVICE_NAME } from '../../../../constants'
import { IClaimEmailTemplate } from '../../../../models/claims/ClaimEmailTemplate.model'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IGetClaimEmailTemplatesResponse = IGenericResponse<'claimEmailTemplates', IClaimEmailTemplate[]>

export async function getClaimEmailTemplates(): Promise<IGetClaimEmailTemplatesResponse> {
  const query = `
    query claimEmailTemplates{
      claimEmailTemplates {
        uuid
        title
        subject
        body
        createdAt
        updatedBy
        updatedAt
        defaultTemplate
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {})) as IGetClaimEmailTemplatesResponse
}
