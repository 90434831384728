import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IDeleteAssetProps {
  uuid: string
}

export type IDeleteAssetResponse = IGenericResponse<'deleteAsset', boolean>

export async function deleteAsset(props: IDeleteAssetProps): Promise<IDeleteAssetResponse> {
  const query = `
    mutation deleteAsset ($uuid:String!) {
      deleteAsset (uuid: $uuid)  
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IDeleteAssetResponse>
}
