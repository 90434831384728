import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteGenreProps = {
  genreUuid: string
}

export type IDeleteGenreResponse = IGenericResponse<'deleteGenre', boolean>

export async function deleteGenre({ genreUuid }: IDeleteGenreProps): Promise<IDeleteGenreResponse> {
  const query = `
    mutation ($genreUuid:String!) {
      deleteGenre (
        genreUuid: $genreUuid
      )
    }
  `

  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { genreUuid })) as IDeleteGenreResponse
}
