import { SERVICE_NAME } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IDeleteClaimEmailTemplateResponse = IGenericResponse<'deleteClaimEmailTemplate', boolean>

export type IDeleteClaimEmailTemplateProps = {
  uuid: string
}

export async function deleteClaimEmailTemplate({
  uuid,
}: IDeleteClaimEmailTemplateProps): Promise<IDeleteClaimEmailTemplateResponse> {
  const mutation = `
    mutation deleteClaimEmailTemplate($uuid: String!) {
      deleteClaimEmailTemplate(uuid: $uuid) 
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, mutation, { uuid })) as IDeleteClaimEmailTemplateResponse
}
