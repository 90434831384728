import { IMAGE_TYPE, SERVICE_NAME } from '../../../constants'

import { IImageBasic } from '../../../models/general/ImageBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IGetImageUploadUrlProps {
  fileName: string
  type: IMAGE_TYPE
}

export type IGetImageUploadUrlResponse = IGenericResponse<'getImageUploadUrl', IImageBasic>

export async function getImageUploadUrl(props: IGetImageUploadUrlProps): Promise<IGetImageUploadUrlResponse> {
  const query = `
    query getImageUploadUrl ($type: ImageType!,$fileName: String!) {
      getImageUploadUrl (type: $type, fileName: $fileName) {
        uuid
        uploadUrl
        type
      }
    }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<IGetImageUploadUrlResponse>
}
