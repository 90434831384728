import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { IPitchChannelVideo } from '../../../models/songpitch/PitchChannelVideo.model'
import { IPaginationParams } from '../../../types/common'
import { SERVICE_NAME } from '../../../constants'
import { pitchVideosFragment } from '../fragments'

type IGetPitchVideosBody = {
  total: number
  pitchVideos: IPitchChannelVideo[]
}

export type IGetPitchVideosProps = {
  pagination?: IPaginationParams
  filters?: {
    channelUuid?: string
    videoId?: string
    assetUuid?: string
    pitcherUuid?: string
    pitchUuid?: string
    status?: string // ChannelPitchStatus
  }
}

export type IGetPitchVideosResponse = IGenericResponse<'pitchVideos', IGetPitchVideosBody>

// video pitch
export async function getPitchVideos(
  { filters, pagination }: IGetPitchVideosProps,
  fragment: string = pitchVideosFragment
): Promise<IGetPitchVideosResponse> {
  const query = `
   query PitchVideos($pagination: PaginationInput, $filters: PitchVideosFiltersInput!,) {
    pitchVideos(pagination: $pagination, filters: $filters, ) {
      total
      pitchVideos {
        ${fragment}
      }
    }
  }
`
  const promise = clientWrapper(SERVICE_NAME.ASSETS, query, { filters, pagination }) as Promise<IGetPitchVideosResponse>
  return promise
}
