import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { IPitchBasic } from '../../../models/songpitch/PitchBasic.model'
import { SERVICE_NAME } from '../../../constants'
import { pitchFragment } from '../fragments'

type IGetPitchBody = {
  total: number
  pitches: IPitchBasic[]
}

export type IGetPitchProps = {
  uuid: string
}

export type IGetPitchResponse = IGenericResponse<'pitch', IGetPitchBody>

export async function getPitch(
  variables: IGetPitchProps,
  fragment: string = pitchFragment
): Promise<IGetPitchResponse> {
  const query = `
  query pitch($uuid: String!) {
    pitch(uuid: $uuid) {
      ${fragment}
    }
  }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IGetPitchResponse>
}
