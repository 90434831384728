import { SERVICE_NAME } from '../../constants'
import { IReleaseBasic } from '../../models'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'
import { releaseFragment } from './fragmentsAssets'

export type IFindReleaseByUuidResponse = IGenericResponse<'findReleaseByUuid', IReleaseBasic>

export async function findReleaseByUuid(
  uuid: string,
  fragment: string = releaseFragment
): Promise<IFindReleaseByUuidResponse> {
  const variables = { uuid }
  const query = `
    query findReleaseByUuid ($uuid: String!) {
      findReleaseByUuid(uuid:$uuid) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IFindReleaseByUuidResponse>
}
