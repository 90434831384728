export const basicInfo = `
  uuid
  firstName
  lastName
  email
  parentClientUuid
  clientTier
  doNotPay
  deletedAt
  createdAt
  children {
    uuid
  }
  taxFormCountryCode
`

const labels = `
  labels {
    name
    uuid 
  }
`
const userGroups = `
  userGroups {
    name
    uuid 
    type
  }
`

export const userSitesBasic = `
  userSites {
    uuid
    siteUuid
    isMain
  }
`

export const userSitesExtended = `
  userSites {
    uuid
    siteUuid
    isMain
    site {
      uuid
      name
      slug
      url
    }
  }
`

const earlyPayment = `
  isEarly
  isEarlyPercentage
`

const contact = `
   cityName
   contactNumber
   addressLine1
   addressLine2
   postCode
   countryCode
   billingName
`

const extendedInfo = `
   referenceNo
   joiningDate
   inboundSponsorshipPercentage
   outboundSponsorshipPercentage
   createdAt
   deletedAt
   displayName
   userImage
   phone
   ${contact}
`

const financialInfo = `
  effectMonth
  isAffiliate
  paypalEmail
`

export const permissions = `
  permissions {
    name
    description
    detailedDescription
    active
  }
`

export const rolesBasic = `
  roles {
    uuid
    name
    description
    isInternal
  }
`

export const roleFragment = `
  uuid
  name
  description
  isInternal
  usersInRole
  ${permissions}
`

export const rolesExtended = `
  roles {
    ${roleFragment}
  }
`

export const userFragmentBasic = `
  ${basicInfo}
  ${earlyPayment}
  ${labels}
  ${userGroups}
  ${userSitesBasic}
  ${rolesBasic}
`

export const extendedUserFragment = `
  ${basicInfo}
  ${earlyPayment}
  ${labels}
  ${userGroups}
  ${userSitesExtended}
  ${extendedInfo}
  ${financialInfo}
  ${rolesExtended}
  
`

export const notesFragment = `
  uuid
  content
  userUuid
  createdAt
  content
  type
  createdBy
  images {
    uuid
    downloadUrl
    type
  }
`

export const smartlinkUrlFragment = `
  uuid
  url
  userUuid
  userSlug
  platformSlug
`

export const smartlinkProfileFragment = `
  uuid
  userUuid
  slug
  name
  bio
  profileImage {
    uuid
    downloadUrl
    type
  }
  bannerImage
  {
    uuid
    downloadUrl
    type
  }
  platformUrls {
    ${smartlinkUrlFragment}
  }
`

export const announcementsFragment = `
  announcements {
    createdAt
    createdBy
    updatedAt
    updatedBy
    endDate
    siteUuids
    startDate
    status
    type
    text
    title
    updatedAt
    uuid
    isEnabled
  }
`
export const contactRequestFragment = `
    content
    createdAt
    email
    name
    siteUuid
    status
    subjectType
    updatedAt
    updatedBy
    uuid
`

export const currentAnnouncementsFragment = `
  endDate 
  startDate 
  text
  title
  type
  uuid
`

export const contractTypesFragment = `
  templateTitle
  title
  siteSlug
  companyEmail
  type
  includeChannel
  includeBonusPercentage
  dateFormat
  details
`

export const documentFragment = `
  uuid
  siteUuid
  userUuid
  createdBy
  signatureRequestId
  companyStatus
  clientName
  clientEmail
  clientStatus
  status
  templateTitle
  title
  percentage
  bonusPercentage
  transactionPercentage
  channelPartnershipPercentage
  rightsManagementPercentage
  distributionPercentage
  distributionYoutubePercentage
  microLicensingPercentage
  type
  documentDate
  templateId
  createdAt
  updatedAt
  reason
  countryCode
`

export const documentSignStatusesFragment = `
  uuid
  signatureRequestId
  clientStatus
  companyStatus
`

export const taxFormsTypesFragment = `
  templateTitle
  title
  isUSA
  individual
`

export const invitationBasicFragment = `
  email
  firstName
  lastName
  status
  isSplitGuest
`

export const invitationsFragment = `
  uuid
  token
  status
  userUuid
  siteUuid
  createdAt
  acceptedAt
`

export const siteFragment = `
  uuid
  name
  slug
  url
`
export const siteExtendedFragment = `
  ${siteFragment}
  commitHash
  version
`

export const queryParamFragment = `
    uuid
    type
    title
    query
    userUuid
    createdAt
`

export const revenueSplitInvitationFragment = `
    uuid
    email
    firstName
    lastName
    splitPercentage
    entityUuids
    type
    siteUuid
    ownerUuid
    status
    createdAt
    updatedAt
`
export const legalInformationFragment = `
  uuid
  userUuid
  clientName
  businessName
  address
  cityStateZip
  date
  dateOfBirth
  employerIdentificationNumber
  entityType
  exemptionFromFatcaReportingCode
  exemptPayeeCode
  federalTaxClassification
  foreignTin
  fTinRequired
  socialSecurityNumber
  updatedAt
` // taxFormId - ! removed to fix error temp

export const adminShiftFragmentLines = `
  uuid
  adminUuid
  startDate
  endDate
`
