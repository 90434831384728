import { SERVICE_NAME } from '../../../../constants'
import { IClaimEmailTemplate } from '../../../../models/claims/ClaimEmailTemplate.model'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type ICreateClaimEmailTemplateResponse = IGenericResponse<'createClaimEmailTemplate', IClaimEmailTemplate[]>

export type ICreateClaimEmailTemplateProps = {
  title: string
  body: string
  defaultTemplate: boolean
}

export async function createClaimEmailTemplate(
  input: ICreateClaimEmailTemplateProps
): Promise<ICreateClaimEmailTemplateResponse> {
  const mutation = `
    mutation createClaimEmailTemplate($input: CreateClaimEmailTemplateFilterInput!) {
      createClaimEmailTemplate(input: $input) {
        uuid
        body
        title
        createdAt
        updatedBy
        updatedAt
        defaultTemplate
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, mutation, { input })) as ICreateClaimEmailTemplateResponse
}
