import { Instance, types } from 'mobx-state-tree'

export const Cms = types.model({
  uuid: types.identifier,
  name: types.string,
  siteUuid: types.string,
  onBehalfOfContentOwner: types.string,
})

export type ICms = Instance<typeof Cms>
