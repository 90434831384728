import { useEffect } from 'react'

export type IUseBlockLeave = {
  shouldBlock: boolean
  message?: string
}

export const useBlockLeave = ({ shouldBlock, message }: IUseBlockLeave) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      // event.returnValue = message
      return message
    }

    if (shouldBlock) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [shouldBlock])
}
