import { IGenericResponse } from '../../api.types'

import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'

export interface IUpdateBrandSponsorshipPercentagesProps {
  userUuid: string
  outboundSponsorshipPercentage?: number | null
  inboundSponsorshipPercentage?: number | null
}

export type IUpdateBrandSponsorshipPercentagesResponse = IGenericResponse<
  'updateBrandSponsorshipPercentages',
  IUserBasic
>

export async function updateBrandSponsorshipPercentages(
  props: IUpdateBrandSponsorshipPercentagesProps
): Promise<IUpdateBrandSponsorshipPercentagesResponse> {
  const query = `
   mutation updateBrandSponsorshipPercentages($input: BrandSponsorshipPercentagesInput!) {
    updateBrandSponsorshipPercentages(input: $input) {
      uuid
      inboundSponsorshipPercentage
      outboundSponsorshipPercentage
    }
  }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, {
    input: props,
  }) as Promise<IUpdateBrandSponsorshipPercentagesResponse>
}
