import { SERVICE_NAME } from '../../../../constants'
import { IClaimManager } from '../../../../models/claims/ClaimManager.model'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type ISetDefaultClaimManagerResponse = IGenericResponse<'setDefaultClaimManager', IClaimManager[]>

export type ISetDefaultClaimManagerProps = {
  managerUuid: string
}

export async function setDefaultClaimManager({
  managerUuid,
}: ISetDefaultClaimManagerProps): Promise<ISetDefaultClaimManagerResponse> {
  const mutation = `
    mutation SetDefaultClaimManager($managerUuid: String!) {
      setDefaultClaimManager(managerUuid: $managerUuid) {
        uuid
        userUuid
        managerUuid
        default
        createdAt
        updatedAt
        updatedBy
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, mutation, { managerUuid })) as ISetDefaultClaimManagerResponse
}
