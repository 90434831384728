import { flow, Instance, applySnapshot } from 'mobx-state-tree'
import { getPitch, IGetPitchResponse } from '../../api/assets-api/songpitch/getPitch'

import { PitchBasic } from './PitchBasic.model'

export const Pitch = PitchBasic.volatile(() => ({
  loading: false,
})).actions(self => ({
  load: flow(function* (uuid: string) {
    try {
      self.loading = true

      const resp: IGetPitchResponse = yield getPitch({ uuid })
      if (resp && resp.data.data?.pitch) {
        applySnapshot(self, resp.data.data?.pitch)
      }
    } catch (err) {
      console.error(err)
    } finally {
      self.loading = false
    }
  }),
}))

export type IPitch = Instance<typeof Pitch>
