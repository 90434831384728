import { lazy } from 'react'
import { ISiteRouteList, RouteType } from './types'

/**
 * LAZY LOAD ALL THE PAGES
 */
const ErrorPageNotFound = lazy(() => import('../pages/errors/ErrorPageNotFound'))
const PaymentBeneficiaryTermsPage = lazy(() => import('../pages/terms/PaymentBeneficiaryTermsPage'))
const RevenueSplittingTermsPage = lazy(() => import('../pages/terms/RevenueSplittingTermsPage'))

export const commonPublicRoutes: ISiteRouteList = {
  ERROR_404: {
    url: '/error-404',
    name: 'Error - 404',
    element: <ErrorPageNotFound />,
    routeType: RouteType.PUBLIC,
    key: 'ERROR_404',
  },
  REVENUE_SPLITTING_TERMS: {
    url: '/terms/terms-conditions-rs',
    name: 'Terms and Conditions',
    element: <RevenueSplittingTermsPage />,
    routeType: RouteType.PUBLIC,
    key: 'REVENUE_SPLITTING_TERMS',
  },
  PAYMENT_BENEFICIARY_TERMS: {
    url: '/terms/terms-conditions-pb',
    name: 'Terms and Conditions',
    element: <PaymentBeneficiaryTermsPage />,
    routeType: RouteType.PUBLIC,
    key: 'PAYMENT_BENEFICIARY_TERMS',
  },
}
