import { PERMISSIONS } from 'e2e-utils'
import { ELITE_ALLIANCE_ROUTES } from '../../routes/platformRoutes/eliteAllianceRoutes'
import { IMenuItem } from './menu.types'

const MENU_ITEMS_ELITE_ALLIANCE: IMenuItem[] = [
  { key: 'elite-alliance-dashboard', name: 'Elite Alliance Dashboard', isTitle: true },
  {
    ...ELITE_ALLIANCE_ROUTES.DASHBOARD,
    icon: 'uil-home-alt',
  },

  {
    ...ELITE_ALLIANCE_ROUTES.VIDEOS,
    icon: 'uil-video',
  },
  {
    key: 'contentId',
    name: 'Content ID',
    icon: 'uil-wallet',
    permissionsOr: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    children: [
      ELITE_ALLIANCE_ROUTES.ASSETS_CONTENT_ID,
      ELITE_ALLIANCE_ROUTES.CLAIMS_CONTENT_ID,
      // ELITE_ALLIANCE_ROUTES.DISPUTED_CLAIMS_CONTENT_ID,
    ],
  },
  {
    ...ELITE_ALLIANCE_ROUTES.CHANNEL_LIST,

    name: 'Channels',
    icon: 'uil-tv-retro',
  },

  {
    key: 'epidemic',
    name: 'Epidemic Sound',
    icon: 'uil-music',
    url: 'https://www.epidemicsound.com/accounts/redirect/elitealliance',
    target: '_blank',
    isExternalLink: true,
  },

  {
    key: 'payment',
    name: 'Payments',
    icon: 'uil-money-bill',
    permissionsOr: [PERMISSIONS.EARNING.REPORT_EXPORT, PERMISSIONS.EARNING.LIST_SELF],
    children: [ELITE_ALLIANCE_ROUTES.MONTHLY_REPORT, ELITE_ALLIANCE_ROUTES.TAX_FORMS],
  },
]

export default MENU_ITEMS_ELITE_ALLIANCE
