import React from 'react'

import { SvgIconProps } from './iconProps'

const IconApple: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.27417 11.7633C1.56886 16.4579 3.28868 23.5821 6.28031 27.9018C7.77361 30.0614 9.28547 32 11.3562 32C11.3953 32 11.4344 31.9993 11.4745 31.9977C12.4474 31.9589 13.1504 31.6593 13.8302 31.3699C14.5934 31.0446 15.3827 30.7083 16.6207 30.7083C17.7985 30.7083 18.5502 31.0337 19.277 31.3482C19.9934 31.6582 20.7313 31.9788 21.8216 31.9583C24.1541 31.9149 25.5863 29.823 26.8498 27.9776C28.1683 26.0508 28.8297 24.18 29.0529 23.4651L29.0619 23.4367C29.1044 23.3096 29.0444 23.1709 28.9226 23.115C28.9192 23.1134 28.9068 23.1083 28.9034 23.1068C28.4926 22.9389 24.8895 21.3404 24.8516 16.9838C24.8165 13.4443 27.5546 11.5699 28.1038 11.2302L28.1294 11.2143C28.1889 11.176 28.2303 11.1152 28.2444 11.0458C28.2584 10.9764 28.2437 10.9042 28.2039 10.8459C26.3139 8.08004 23.4171 7.6632 22.2508 7.61305C22.0816 7.59616 21.9069 7.58766 21.7315 7.58766C20.3617 7.58766 19.0495 8.10501 17.9952 8.5207C17.2675 8.80765 16.639 9.05547 16.2054 9.05547C15.7183 9.05547 15.0862 8.80461 14.3542 8.5142C13.3753 8.12568 12.2658 7.68545 11.0912 7.68545C11.0632 7.68545 11.0356 7.68576 11.0084 7.68629C8.27742 7.72647 5.69686 9.28891 4.27417 11.7633Z"
      fill="black"
    />
    <path
      d="M22.2532 0.000201593C20.5994 0.067559 18.6155 1.08558 17.4297 2.47448C16.422 3.64191 15.4376 5.58835 15.6969 7.55031C15.7132 7.67307 15.8131 7.76781 15.9365 7.77736C16.0482 7.78606 16.1621 7.79047 16.2756 7.79058C17.8927 7.79058 19.6372 6.89594 20.8286 5.45541C22.0824 3.93431 22.7165 1.98221 22.5247 0.233644C22.5096 0.0966212 22.389 -0.00514923 22.2532 0.000201593Z"
      fill="black"
    />
  </svg>
)

export default IconApple
