import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ITakedownShopifyResponse = IGenericResponse<'takedownShopify', boolean>

export async function takedownShopify(releaseUuid: string): Promise<ITakedownShopifyResponse> {
  const query = `
    mutation takedown($releaseUuid: String!) {
      takedownShopify(releaseUuid: $releaseUuid)
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { releaseUuid }) as Promise<ITakedownShopifyResponse>
}
