import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { userSitesExtended } from '../fragments'

export type IToggleSiteProps = {
  userUuid: string
  siteUuid: string
}

export type IToggleSiteResponse = IGenericResponse<'toggleUserSite', IUserBasic>

export async function toggleSite(
  { userUuid, siteUuid }: IToggleSiteProps,
  fragment: string = userSitesExtended
): Promise<IToggleSiteResponse> {
  const query = `
  mutation toggleUserSite($userUuid: String!, $siteUuid: String!) {
      toggleUserSite(userUuid: $userUuid, siteUuid: $siteUuid) {
          ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid, siteUuid })) as IToggleSiteResponse
}
