import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { SERVICE_NAME } from '../../../constants'

export type ILatestEstimatedEarningsProps = {
  filters: {
    assetIds: string[]
  }
}

export type ILatestEstimatedEarningsResponse = IGenericResponse<
  'latestEstimatedEarnings',
  { assetId: string; totalRevenue: number }[]
>

export async function latestEstimatedEarnings({
  filters,
}: ILatestEstimatedEarningsProps): Promise<ILatestEstimatedEarningsResponse> {
  const query = `
    query latestEstimatedEarnings ($filters: LatestEstimatedEarningFilterInput!) {
      latestEstimatedEarnings (filters: $filters) {
        assetId
        totalRevenue
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { filters })) as ILatestEstimatedEarningsResponse
}
