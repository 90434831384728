import { SERVICE_NAME } from '../../../constants'
import { ISite } from '../../../models/sites/Site.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { siteFragment } from '../fragments'

export type IGetSitesResponse = IGenericResponse<'response', ISite[]>

export async function getSites(fragment: string = siteFragment): Promise<IGetSitesResponse> {
  const query = `
    query getSites {
      response: sites {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query)) as IGetSitesResponse
}
