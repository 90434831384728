import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IToggleUserGroupProps {
  userGroupUuid: string
  userUuid: string
}

export type IToggleUserGroupResponse = IGenericResponse<'toggleUserGroup', IUserBasic>

export async function toggleUserGroup({
  userGroupUuid,
  userUuid,
}: IToggleUserGroupProps): Promise<IToggleUserGroupResponse> {
  const query = `
    mutation ($userGroupUuid:String!, $userUuid: String!) {
      toggleUserGroup (
        userGroupUuid: $userGroupUuid
        userUuid: $userUuid
      ) {
        userGroups {
          uuid
          name
        }
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, { userGroupUuid, userUuid }) as Promise<IToggleUserGroupResponse>
}
