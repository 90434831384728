import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IAffiliateBasic } from '../../../models/assets/AffiliateBasic.model'
import { affiliatesFragment } from '../fragments'

interface IEditAffiliateProps {
  type: string
  siteUuid: string
  percentage: number
  affiliateUserUuid: string
  entityUuid: string
  uuid: string
  lastAffiliationMonth?: string | null
}

export type IEditAffiliateResponse = IGenericResponse<'editAffiliate', IAffiliateBasic>

export async function editAffiliate(
  props: IEditAffiliateProps,
  fragment: string = affiliatesFragment
): Promise<IEditAffiliateResponse> {
  const query = `
    mutation editAffiliate(
        $type: AffiliateType!
        $siteUuid: String!
        $percentage: Float!
        $affiliateUserUuid: String!
        $entityUuid: String!
        $uuid: String
        $lastAffiliationMonth: String
    ) {
      editAffiliate(
        type: $type
        siteUuid: $siteUuid
        percentage: $percentage
        affiliateUserUuid: $affiliateUserUuid
        entityUuid: $entityUuid
        uuid: $uuid
        lastAffiliationMonth: $lastAffiliationMonth
      ) {
        ${fragment}  
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IEditAffiliateResponse>
}
