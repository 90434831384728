import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteTracksResponse = IGenericResponse<'deleteTracks', boolean>

export async function deleteTracks(uuids: string[]): Promise<IDeleteTracksResponse> {
  const variables = {
    uuids,
  }
  // update here
  const query = `
    mutation deleteTracks ($uuids: [String!]!) {
      deleteTracks (uuids: $uuids) 
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IDeleteTracksResponse>
}
