import { cast, flow, Instance, types } from 'mobx-state-tree'
import { getPaymentMethods, IGetPaymentMethodsResponse } from '../../api/payments-api/payments/getPaymentMethods'
import { PaymentMethod } from './PaymentMethod.model'

export const PaymentMethodList = types
  .model({
    list: types.array(PaymentMethod),
  })
  .volatile(() => ({
    loading: false,
  }))
  .views(self => ({
    methodBySlug(slug: string) {
      return self.list.find(el => el.slug === slug)
    },
    getByUuid(uuid: string) {
      return self.list.find(item => item.uuid === uuid)
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetPaymentMethodsResponse = yield getPaymentMethods()
        if (resp && resp.data.data?.paymentMethods) {
          self.list = cast(resp.data.data.paymentMethods)
        }
        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))

export type IPaymentMethodList = Instance<typeof PaymentMethodList>
