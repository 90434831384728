import { SERVICE_NAME } from '../../../constants'
import { IDistributionStore } from '../../../models/assets/DistributionStore.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { storeFragment } from '../fragments'

export type IGetStoresResponse = IGenericResponse<'getStores', IDistributionStore[]>

export async function getStores(fragment: string = storeFragment): Promise<IGetStoresResponse> {
  const query = `
    query getStores {
      getStores{
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query) as Promise<IGetStoresResponse>
}
