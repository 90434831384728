import React from 'react'
import { UpArrow, ArrowDown, MinimizeArrowUpDown } from '@vectopus/atlas-icons-react'

interface PercentageVariationBadgeProps {
  value: number
}

const PercentageVariationBadge: React.FC<PercentageVariationBadgeProps> = ({ value }) => {
  const variant = (() => {
    if (value === 0 || Number.isNaN(value)) {
      return 'equal'
    }
    if (value > 0) {
      return 'up'
    }
    return 'down'
  })()

  const getIcon = () => {
    switch (variant) {
      case 'up':
        return <UpArrow weight="bold" />
      case 'down':
        return <ArrowDown weight="bold" />
      case 'equal':
        return <MinimizeArrowUpDown weight="bold" />
      default:
        return null
    }
  }

  return (
    <div className={`badge badge-percentage percentage-${variant}`}>
      {getIcon()}
      {Number.isNaN(value) ? '-  -' : `${value.toFixed(2)}%`}
    </div>
  )
}

export default PercentageVariationBadge
