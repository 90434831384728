import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { IPaginationParams } from '../../../types/common'
import { IChannelBasic } from '../../../models/assets/ChannelBasic.model'

import { channelBasicFragment } from '../fragments'
import { SERVICE_NAME } from '../../../constants'

export type IGetChannelsResponse = IGenericResponse<
  'channels',
  {
    channels: IChannelBasic[]
    total: number
  }
>

export type IGetChannelsProps = {
  pagination?: IPaginationParams
  filters?: {
    userUuid?: string
    siteUuid?: string
    channelId?: string
    status?: string
    isSplit?: boolean
    name?: string
    uuid?: string
    genres?: string[]
    onSongpitchFilter?: boolean
  }
}

export async function getChannels(
  { pagination, filters }: IGetChannelsProps,
  fragment: string = channelBasicFragment
): Promise<IGetChannelsResponse> {
  const query = `
    query channels ($pagination: PaginationInput, $filters: ChannelsFilterInput) {
      channels (pagination: $pagination, filters: $filters) {
        total
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IGetChannelsResponse
}
