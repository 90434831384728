import { SERVICE_NAME } from '../../../constants'
import { IParticipant } from '../../../models'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { participantsFragment } from '../fragments'

export type IParticipantsGetAllForCurrentUserResponse = IGenericResponse<
  'Participant_getAllForCurrentUser',
  IParticipant[]
>

export async function participantsGetAllForCurrentUser(
  fragment: string = participantsFragment
): Promise<IParticipantsGetAllForCurrentUserResponse> {
  const query = `
    query getAllParticipantsForCurrentUser {
      Participant_getAllForCurrentUser {
        ${fragment}
      }
    }
  `

  return clientWrapper(SERVICE_NAME.ASSETS, query) as Promise<IParticipantsGetAllForCurrentUserResponse>
}
