import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IToggleDoNotPayResponse = IGenericResponse<'toggleDoNotPay', boolean>

export async function toggleDoNotPay(userUuid: string): Promise<IToggleDoNotPayResponse> {
  const query = `
  mutation toggleDoNotPay($userUuid: String!) {
    toggleDoNotPay(userUuid: $userUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid })) as IToggleDoNotPayResponse
}
