import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IAffiliateBasic } from '../../../models/assets/AffiliateBasic.model'
import { affiliatesFragment } from '../fragments'

interface ICreateAffiliateProps {
  type: string
  siteUuid: string
  percentage: number
  affiliateUserUuid: string
  lastAffiliationMonth?: string | null
  entityUuid: string
}

export type ICreateAffiliateResponse = IGenericResponse<'createAffiliate', IAffiliateBasic>

export async function createAffiliate(
  props: ICreateAffiliateProps,
  fragment: string = affiliatesFragment
): Promise<ICreateAffiliateResponse> {
  const query = `
    mutation createAffiliate(
      $type: AffiliateType!,
      $siteUuid: String!,
      $percentage: Float!,
      $affiliateUserUuid: String!,
      $entityUuid: String!,
      $lastAffiliationMonth: String
    ) {
      createAffiliate(
        type: $type,
        siteUuid: $siteUuid,
        percentage: $percentage,
        affiliateUserUuid: $affiliateUserUuid,
        entityUuid: $entityUuid,
        lastAffiliationMonth: $lastAffiliationMonth
      ) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<ICreateAffiliateResponse>
}
