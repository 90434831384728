import { Instance, types } from 'mobx-state-tree'

import { Participant } from './Participant.model'
import { PARTICIPANT_ROLES } from '../../constants'

export const ParticipantWithRole = types.compose(
  Participant,
  types.model({
    role: types.enumeration(Object.values(PARTICIPANT_ROLES)),
  })
)

export type IParticipantWithRole = Instance<typeof ParticipantWithRole>
