import classNames from 'classnames'
import { IMenuItem } from '../../constants/menus/menu.types'
import MenuItemLink from './MenuItemLink'

interface IProp {
  item: IMenuItem
  className?: string
  linkClassName?: string
}

const MenuItem = ({ item, className, linkClassName }: IProp): JSX.Element => {
  return (
    <>
      {item.isTitle ? (
        <li className={classNames(' side-nav-title ', className)}>{item.name}</li>
      ) : (
        <li className={classNames('side-nav-item', className)}>
          <MenuItemLink item={item} className={linkClassName} />
        </li>
      )}
    </>
  )
}

export default MenuItem
