import React from 'react'

import { SvgIconProps } from './iconProps'

const IconSoundCloud: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.66663C11.7364 8.66663 12.3334 9.26358 12.3334 9.99996V24C12.3334 24.7363 11.7364 25.3333 11 25.3333C10.2636 25.3333 9.66669 24.7363 9.66669 24V9.99996C9.66669 9.26358 10.2636 8.66663 11 8.66663ZM6.50002 11.7777C7.2364 11.7777 7.83335 12.3747 7.83335 13.1111V24C7.83335 24.7363 7.2364 25.3333 6.50002 25.3333C5.76364 25.3333 5.16669 24.7363 5.16669 24V13.1111C5.16669 12.3747 5.76364 11.7777 6.50002 11.7777ZM2.00002 18C2.7364 18 3.33335 18.5969 3.33335 19.3333V22.4444C3.33335 23.1808 2.7364 23.7777 2.00002 23.7777C1.26364 23.7777 0.666687 23.1808 0.666687 22.4444V19.3333C0.666687 18.5969 1.26364 18 2.00002 18Z"
      fill="#FD561F"
    />
    <path
      d="M23.7778 14.4H25.3334C27.48 14.4 30 16.4384 30 19.2C30 21.8512 27.6667 24 25.3334 24H16V7.99996C20.6667 7.99996 23 10.4 23.7778 14.4Z"
      fill="#FD561F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6667 7.99996C14.6667 7.26358 15.2636 6.66663 16 6.66663C18.5652 6.66663 20.6558 7.33061 22.2226 8.6942C23.5174 9.8211 24.3454 11.3313 24.8322 13.0666H25.3334C26.8046 13.0666 28.2882 13.7492 29.3908 14.7984C30.5092 15.8626 31.3334 17.3969 31.3334 19.2C31.3334 22.5968 28.3938 25.3333 25.3334 25.3333H16C15.2636 25.3333 14.6667 24.7363 14.6667 24V7.99996ZM17.3334 9.41422V22.6666H25.3334C26.9396 22.6666 28.6667 21.1056 28.6667 19.2C28.6667 18.2414 28.2309 17.3757 27.5526 16.7303C26.8585 16.0699 26.0087 15.7333 25.3334 15.7333H23.7778C23.1395 15.7333 22.5908 15.281 22.469 14.6545C22.1166 12.8424 21.4431 11.551 20.4719 10.7057C19.7302 10.0602 18.7213 9.59355 17.3334 9.41422Z"
      fill="#FD561F"
    />
  </svg>
)

export default IconSoundCloud
