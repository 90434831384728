import { Instance, types } from 'mobx-state-tree'

export const SiteBasic = types.model({
  uuid: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  slug: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  commitHash: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
})

export type ISiteBasic = Instance<typeof SiteBasic>
