import { Instance, types } from 'mobx-state-tree'
import { STORES } from '../../constants'

export const DistributionStore = types.model({
  uuid: types.string,
  id: types.string,
  name: types.maybeNull(types.string),
  storeKey: types.maybeNull(types.enumeration(Object.values(STORES))),
})

export type IDistributionStore = Instance<typeof DistributionStore>
