import { applySnapshot, cast, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import {
  createClaimEmailTemplate,
  ICreateClaimEmailTemplateProps,
} from '../../api/assets-api/contentId/claimEmailTemplate/createClaimEmailTemplate'
import {
  deleteClaimEmailTemplate,
  IDeleteClaimEmailTemplateProps,
  IDeleteClaimEmailTemplateResponse,
} from '../../api/assets-api/contentId/claimEmailTemplate/deleteClaimEmailTemplate'
import {
  editClaimEmailTemplate,
  IEditClaimEmailTemplateProps,
  IEditClaimEmailTemplateResponse,
} from '../../api/assets-api/contentId/claimEmailTemplate/editClaimEmailTemplate'
import {
  getClaimEmailTemplates,
  IGetClaimEmailTemplatesResponse,
} from '../../api/assets-api/contentId/claimEmailTemplate/getClaimEmailTemplates'
import { ClaimEmailTemplate } from './ClaimEmailTemplate.model'
import {
  IPreviewClaimsEmailProps,
  IPreviewClaimsEmailResponse,
  previewClaimsEmail,
} from '../../api/assets-api/contentId/claimEmailTemplate/previewClaimsEmail'

export const ClaimEmailTemplateList = types
  .model({
    list: types.array(ClaimEmailTemplate),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    getByUuid(uuid: string | null | undefined) {
      if (uuid) {
        return self.list.find(user => user.uuid === uuid)
      }
      return null
    },
    getDefaultEmailTemplate() {
      return self.list.find(item => item.defaultTemplate === true)
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetClaimEmailTemplatesResponse = yield getClaimEmailTemplates()

        if (resp && resp.data.data && resp.data.data.claimEmailTemplates) {
          self.list = cast(resp.data.data.claimEmailTemplates)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    deleteClaimEmailTemplate: flow(function* (props: IDeleteClaimEmailTemplateProps) {
      try {
        self.loading = true
        const response: IDeleteClaimEmailTemplateResponse = yield deleteClaimEmailTemplate(props)

        if (response?.data.data?.deleteClaimEmailTemplate) {
          toast.success('The claim email template has been successfully deleted!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    createClaimEmailTemplate: flow(function* (props: ICreateClaimEmailTemplateProps) {
      try {
        self.loading = true
        yield createClaimEmailTemplate(props)

        toast.success('The claim email template has been created successfully!')
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    editClaimEmailTemplate: flow(function* (props: IEditClaimEmailTemplateProps) {
      try {
        self.loading = true
        const updatedTemplate: IEditClaimEmailTemplateResponse = yield editClaimEmailTemplate(props)

        const template = self.getByUuid(props.uuid)
        if (template && updatedTemplate?.data.data) {
          applySnapshot(template, { ...template, ...updatedTemplate.data.data.editClaimEmailTemplate })
        }

        toast.success('Claim email template updated successfully!')
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    previewClaimsEmail: flow(function* (input: IPreviewClaimsEmailProps) {
      try {
        self.loading = true
        const preview: IPreviewClaimsEmailResponse = yield previewClaimsEmail(input)

        if (!preview || !preview?.data.data) return null

        return preview?.data.data?.previewClaimsEmail
      } catch (err) {
        console.error(err)
        return null
      } finally {
        self.loading = false
      }
    }),
  }))

export type IClaimEmailTemplateList = Instance<typeof ClaimEmailTemplateList>
