import { types, Instance } from 'mobx-state-tree'

export const DailyAssetEarning = types.model({
  earningDate: types.string,
  estimatedAdRevenue: types.number,
  estimatedRedPartnerRevenue: types.number,
  adjustmentAdRevenue: types.number,
  adjustmentRedRevenue: types.number,
  isOfficial: types.boolean,
  totalEarning: types.number,
})

export type IDailyAssetEarning = Instance<typeof DailyAssetEarning>
