import { GENERIC_ERROR_MESSAGE } from '@epic-front/common/src/constants'
import { IArtTrack } from '@epic-front/common/src/models'
import { IAsset } from '@epic-front/common/src/models/assets/Asset.model'
import { useEffect, useRef, useState } from 'react'
import AudioSpectrum from 'react-audio-spectrum'
import ReactAudioPlayer from 'react-h5-audio-player'
import { toast } from 'react-toastify'

interface IAudioPlayer {
  audio?: IAsset | IArtTrack
  assetUrl?: string
  startMoment?: number
  showSpectrum?: boolean
}

export const AudioPlayer = ({ audio, assetUrl, startMoment, showSpectrum }: IAudioPlayer): JSX.Element => {
  const [spectrum, setSpectrum] = useState<boolean>(false)
  const reactAudio = useRef<ReactAudioPlayer>(null)
  const [src, setSrc] = useState<string>(assetUrl || audio?.downloadUrl || '')

  useEffect(() => {
    setSrc(assetUrl || audio?.downloadUrl || '')
  }, [audio, assetUrl])

  useEffect(() => {
    if (reactAudio?.current?.audio.current && startMoment) {
      reactAudio.current.audio.current.currentTime = startMoment
    }
  }, [reactAudio.current?.audio])

  return (
    <div className="container-md">
      <div className="position-relative bg-white" style={{ borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }}>
        <div className="audioplayer__header">
          <div className="d-flex gap-2">
            <div className="audioplayer__play-button-placeholder" />
            <div className="audioplayer__info d-flex flex-column justify-content-center">
              <h4 className="my-0">{audio?.title}</h4>
              <p className="small mb-0 text-uppercase">
                <b>{[audio?.artist, audio?.album].join(' - ')}</b>
              </p>
            </div>
          </div>
          {spectrum && showSpectrum && (
            <AudioSpectrum
              id="audioplayer__spectrum"
              height={50}
              width={250}
              audioEle={reactAudio?.current?.audio.current}
              capColor="#005376"
              capHeight={2}
              meterWidth={5}
              meterCount={50}
              meterColor={[
                { stop: 0, color: '#00b4d7' },
                { stop: 0.5, color: '#00b4d7' },
                { stop: 1, color: '#00b4d7' },
              ]}
              gap={4}
            />
          )}
        </div>
        <ReactAudioPlayer
          autoPlay
          crossOrigin=""
          onLoadedData={() => setSpectrum(true)}
          src={src}
          layout="horizontal"
          ref={reactAudio}
          className="audioplayer"
          showJumpControls={false}
          loop
          onError={() => {
            toast.error(GENERIC_ERROR_MESSAGE)
          }}
        />
      </div>
    </div>
  )
}
