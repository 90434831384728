import { Instance, types } from 'mobx-state-tree'
import { MICROLICENSING_AUDIO_TYPE, MICROLICENSING_KEY, MICROLICENSING_MOODS } from '../../constants'
import { MicrolicensingPropsConfigFile } from './MicrolicensingPropsConfigFile.model'

export const MicrolicensingPropsConfig = types.model({
  uuid: types.maybeNull(types.string),
  waves: types.maybeNull(types.array(types.number)),
  shopifyProductId: types.maybeNull(types.string),
  moods: types.maybeNull(types.array(types.enumeration(Object.values(MICROLICENSING_MOODS)))),
  salesNumber: types.maybeNull(types.number),
  licenses: types.maybeNull(types.array(types.string)),
  key: types.maybeNull(types.enumeration(Object.values(MICROLICENSING_KEY))),
  genreUuids: types.maybeNull(types.array(types.string)),
  bpm: types.maybeNull(types.number),
  audioType: types.maybeNull(types.enumeration(Object.values(MICROLICENSING_AUDIO_TYPE))),
  description: types.maybeNull(types.string),
  shortDescription: types.maybeNull(types.string),
  files: types.maybeNull(types.array(MicrolicensingPropsConfigFile)),
})

export type IMicrolicensingPropsConfig = Instance<typeof MicrolicensingPropsConfig>
