import React from 'react'

import { SvgIconProps } from './iconProps'

const IconDeezer: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_710_18717)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8384 4.8821C28.1345 3.1661 28.5689 2.08702 29.0502 2.08497H29.0511C29.9485 2.08806 30.676 5.83028 30.676 10.4506C30.676 15.071 29.9475 18.8162 29.0492 18.8162C28.6808 18.8162 28.341 18.1803 28.0674 17.1146C27.635 21.0156 26.7377 23.6973 25.6989 23.6973C24.8951 23.6973 24.1738 22.0864 23.6896 19.5459C23.3589 24.3775 22.5267 27.8054 21.5541 27.8054C20.9436 27.8054 20.3871 26.447 19.9751 24.2352C19.4797 28.8009 18.335 32 17.0003 32C15.6655 32 14.5188 28.8019 14.0255 24.2352C13.6164 26.447 13.0599 27.8054 12.4465 27.8054C11.4738 27.8054 10.6437 24.3775 10.311 19.5459C9.82669 22.0864 9.1074 23.6973 8.30163 23.6973C7.26391 23.6973 6.36556 21.0166 5.93317 17.1146C5.66153 18.1834 5.31969 18.8162 4.9514 18.8162C4.05305 18.8162 3.3246 15.071 3.3246 10.4506C3.3246 5.83028 4.05305 2.08497 4.9514 2.08497C5.43363 2.08497 5.865 3.16712 6.16411 4.8821C6.6433 1.92315 7.4216 0 8.30163 0C9.34649 0 10.252 2.71983 10.6803 6.66921C11.0994 3.79478 11.7353 1.96231 12.4475 1.96231C13.4455 1.96231 14.294 5.56643 14.6084 10.5939C15.1995 8.01624 16.0551 6.39918 17.0023 6.39918C17.9494 6.39918 18.8051 8.01727 19.3952 10.5939C19.7106 5.56643 20.558 1.96231 21.5561 1.96231C22.2672 1.96231 22.9021 3.79478 23.3233 6.66921C23.7506 2.71983 24.6561 0 25.7009 0C26.578 0 27.3592 1.92419 27.8384 4.8821ZM1 9.62779C1 7.5624 1.41306 5.88763 1.92277 5.88763C2.43247 5.88763 2.84553 7.5624 2.84553 9.62779C2.84553 11.6931 2.43247 13.368 1.92277 13.368C1.41306 13.368 1 11.6931 1 9.62779ZM31.1543 9.62779C31.1543 7.5624 31.5674 5.88763 32.0771 5.88763C32.5867 5.88763 32.9999 7.5624 32.9999 9.62779C32.9999 11.6931 32.5867 13.368 32.0771 13.368C31.5674 13.368 31.1543 11.6931 31.1543 9.62779Z"
        fill="#A238FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_710_18717">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IconDeezer
