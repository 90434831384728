import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ICreateOrEditAnnouncementResponse = IGenericResponse<'createOrEditAnnouncement', { uuid: string }>

export type ICreateOrEditAnnouncementProps = {
  uuid?: string
  startDate?: string
  endDate: string
  siteUuids: string[]
  text: string
  title: string
  type: string
}

export async function createOrEditAnnouncement(
  props: ICreateOrEditAnnouncementProps
): Promise<ICreateOrEditAnnouncementResponse> {
  const query = `#graphql
    mutation createOrEditAnnouncement($input: AnnouncementInput!) {
      createOrEditAnnouncement(input: $input) {
        uuid
      }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, { input: props }) as Promise<ICreateOrEditAnnouncementResponse>
}
