import { Instance, types } from 'mobx-state-tree'
import {
  DEFAULT_PER_PAGE_OPTIONS,
  DEFAULT_PER_PAGE_VALUE,
  DEFAULT_SORT_DIRECTION,
  SortDirection,
} from '../../constants'

export const Pagination = types
  .model({
    sort: types.optional(types.maybeNull(types.string), null),
    sortDirection: types.optional(
      types.union(types.literal(SortDirection.ASC), types.literal(SortDirection.DESC)),
      DEFAULT_SORT_DIRECTION
    ),
    page: types.optional(types.number, 1),
    perPage: types.optional(types.number, DEFAULT_PER_PAGE_VALUE),
    totalItems: types.number,
    perPageOptions: types.optional(
      types.array(
        types.model({
          text: types.string,
          value: types.number,
        })
      ),
      DEFAULT_PER_PAGE_OPTIONS
    ),
    scrollToTop: true,
  })

  .views(self => ({
    get totalPages() {
      return Math.ceil(self.totalItems / self.perPage)
    },
    get allQueryParams() {
      return {
        sort: self.sort,
        sortDirection: self.sortDirection,
        page: self.page,
        perPage: self.perPage,
      }
    },
  }))
  .views(self => ({
    get hasNextPage() {
      return self.totalPages > self.page
    },
    get hasPreviewsPage() {
      return self.page > 1
    },
    get firstRecordNrOnPage() {
      return (self.page - 1) * self.perPage + 1
    },
    get lastRecordNrONPage() {
      const tempLast = self.page * self.perPage
      return self.totalItems < tempLast ? self.totalItems : tempLast
    },
  }))
  .actions(self => ({
    setPage(page: number) {
      if (page > 0) {
        // scroll to the top of the page
        if (self.totalPages > page) {
          //! this needs to be set in the table and updated
          if (self.scrollToTop) window.scrollTo(0, 0)
        }

        self.page = page
      }
    },
  }))
  .actions(self => ({
    setTotalItems(itemsCount: number) {
      if (itemsCount >= 0) {
        self.totalItems = itemsCount
      }
    },
    setScrollToTop(val: boolean) {
      self.scrollToTop = val
    },

    setPerPage(perPage: number) {
      self.perPage = perPage
    },
    setSort(sort: string | null) {
      self.sort = sort
    },
    setSortDirection(sortDirection: SortDirection) {
      self.sortDirection = sortDirection
    },
    switchSortDirection() {
      if (self.sortDirection === SortDirection.DESC) {
        self.sortDirection = SortDirection.ASC
        return
      }
      self.sortDirection = SortDirection.DESC
    },
    resetPagination() {
      self.setPage(1)
      self.perPage = 20
      self.sort = null
      self.sortDirection = DEFAULT_SORT_DIRECTION
    },
    nextPage() {
      if (self.hasNextPage) {
        self.setPage(self.page + 1)
      }
    },
    previewsPage() {
      if (self.page > 1) {
        self.setPage(self.page - 1)
      }
    },
  }))

export type IPagination = Instance<typeof Pagination>
