import { types } from 'mobx-state-tree'

export const JSONPrimitive = types.custom<string, JSON>({
  name: 'JSON',
  fromSnapshot(value: string) {
    return JSON.parse(value)
  },
  toSnapshot(value: JSON) {
    return JSON.stringify(value)
  },
  isTargetType(value: string): boolean {
    return typeof value === 'object'
  },
  getValidationMessage(): string {
    return ''
  },
  // Example with validation
  // getValidationMessage(value: string): string {
  //   if (LOG_MESSAGE_REGEX.test(value)) return ''
  //   return ''
  // },
})
