import { SONGPITCH_ROUTES } from '../../routes/platformRoutes/songpitchRoutes'
import { IMenuItem } from './menu.types'

const MENU_ITEMS_SONGPITCH: IMenuItem[] = [
  { key: 'songpitch-dashboard', name: 'SongPitch Dashboard', isTitle: true },
  {
    ...SONGPITCH_ROUTES.DASHBOARD,
    icon: 'uil-chart',
  },
  {
    ...SONGPITCH_ROUTES.PITCH_EDIT,
    icon: 'uil-file-plus',
  },
  {
    ...SONGPITCH_ROUTES.SONG_LIST,
    icon: 'uil-music',
  },
  {
    ...SONGPITCH_ROUTES.PITCH_LIST,
    icon: 'uil-presentation-plus',
  },
  {
    ...SONGPITCH_ROUTES.CHANNEL_LIST,
    icon: 'uil-tv-retro',
  },

  {
    ...SONGPITCH_ROUTES.VIDEO_LIST,
    icon: 'uil-film',
  },
  {
    ...SONGPITCH_ROUTES.VIDEO_ADDED_LIST,
    icon: 'uil-film',
  },

  {
    key: 'earnings',
    name: 'Earnings',
    icon: 'uil-money-bill',
    children: [SONGPITCH_ROUTES.MONTHLY_REPORT, SONGPITCH_ROUTES.TAX_FORMS],
  },
]

export default MENU_ITEMS_SONGPITCH
