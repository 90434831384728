import { applySnapshot, flow, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { assignGenresToChannel, IAssignGenresToChannelResponse } from '../../api/assets-api/assignGenresToChannel'
import { assignUserToChannel, IAssignUserToChannelResponse } from '../../api/assets-api/assignUserToChannel'
import { getChannel, IGetChannelResponse } from '../../api/assets-api/contentId/getChannel'
import { getRevenueShares, IGetRevenueSharesResponse } from '../../api/assets-api/revenueShare/getRevenueShares'
import { REVENUE_SHARE_TYPE } from '../../constants'
import { IAssetUser } from './AssetUser.model'
import { ChannelBasic } from './ChannelBasic.model'
import { RevenueShare } from './RevenueShare.model'

export const Channel = ChannelBasic.volatile(() => ({
  loading: false,
}))
  .views(self => ({
    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid
    },
  }))
  .actions(self => ({
    updateAssetWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },

    removeSplit: (splitUuid: string) => {
      const removedSplitIndex = self.users.findIndex(assetUser => assetUser.revSplitUuid === splitUuid)
      const removeSplit = self.users.splice(removedSplitIndex, 1)[0]

      const ownerIndex = self.users.findIndex(assetUser => !assetUser.revSplitUuid)
      self.users[ownerIndex].percentage = (self.users[ownerIndex].percentage || 100) + (removeSplit.percentage || 0)
    },

    getChannel: flow(function* (uuid: string) {
      try {
        self.loading = true
        const resp: IGetChannelResponse = yield getChannel({ uuid })
        if (resp && resp.data.data?.channel) {
          applySnapshot(self, resp.data.data.channel)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
    assignUser: flow(function* (userUuid: string) {
      try {
        self.loading = true
        const resp: IAssignUserToChannelResponse = yield assignUserToChannel({
          userUuid,
          channelUuid: self.uuid,
        })

        if (resp?.data.data?.assignUserToChannel.uuid) {
          toast.success('User successfully assigned')
        }
        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
        throw err
      }
    }),
    assignGenres: flow(function* (genreUuids: string[]) {
      try {
        self.loading = true
        const resp: IAssignGenresToChannelResponse = yield assignGenresToChannel({
          channelUuid: self.uuid,
          genreUuids,
        })

        if (resp?.data.data?.assignGenresToChannel.uuid) {
          toast.success('Channel genre successfully assigned')
        }
        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
        throw err
      }
    }),
    getRevenueShareOrInitiateRevenueShare: flow(function* () {
      try {
        if (self.uuid && self.siteUuid) {
          self.loading = true
          const resp: IGetRevenueSharesResponse = yield getRevenueShares({
            filters: {
              entityUuid: [self.uuid],
              siteUuid: self.siteUuid,
            },
          })

          if (resp?.data.data?.revenueShares.length === 1) {
            self.revenueShare = resp?.data.data?.revenueShares[0]
          }

          if (resp?.data.data?.revenueShares.length === 0) {
            if (!self.revenueShare) {
              self.revenueShare = RevenueShare.create({
                type: REVENUE_SHARE_TYPE.CHANNEL,
                siteUuid: self.siteUuid,
                entityUuid: self.uuid,
              })
            }
          }
          self.loading = false
        }
      } catch (err) {
        console.error(err)
        self.loading = false
        throw err
      }
    }),
  }))

export type IChannel = Instance<typeof Channel>
