import { SERVICE_NAME } from '../../../constants'
import { IUserPaymentMethodBasic } from '../../../models/payment/UserPaymentMethodBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { userPaymentMethodFragment } from './fragments'

export type IGetPaymentMethodResponse = IGenericResponse<'userPaymentMethod', IUserPaymentMethodBasic>

type IGetPaymentMethodProps = {
  userUuid?: string
}

export async function getUserPaymentMethod(
  props: IGetPaymentMethodProps,
  fragment: string = userPaymentMethodFragment
): Promise<IGetPaymentMethodResponse> {
  const query = `
    query userPaymentMethod($userUuid:String) {
      userPaymentMethod(userUuid:$userUuid) {
        ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.PAYMENTS, query, props)) as IGetPaymentMethodResponse
}
