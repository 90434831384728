import { types, Instance, flow, cast } from 'mobx-state-tree'
import moment from 'moment'

import { DailyVideoPitchEarnings, IDailyVideoPitchEarnings } from './DailyVideoPitchEarnings.model'
import {
  dailySongpitchEstimatedEarningsPerUser,
  IDailySongpitchEstimatedEarningsPerUseResponse,
} from '../../api/assets-api/songpitch/dailySongpitchEstimatedEarningsPerUser'

export const DailyVideoPitchEarningsList = types
  .model({
    list: types.array(DailyVideoPitchEarnings),
  })
  .volatile(() => ({
    loading: false,
    userUuidFilter: '',
    startDateFilter: '',
    endDateFilter: '',
  }))
  .views(self => ({
    get getDailySumArray(): IDailyVideoPitchEarnings[] {
      const sumArray: IDailyVideoPitchEarnings[] = []
      self.list.forEach(de => {
        // check if it exists
        const dateIndex = sumArray.findIndex(el => el.earningDate === de.earningDate)
        if (dateIndex && sumArray[dateIndex]) {
          sumArray[dateIndex].setEstimatedEarning(
            (sumArray[dateIndex].estimatedEarning || 0) + (de.estimatedEarning || 0)
          )
        } else {
          sumArray.push(
            DailyVideoPitchEarnings.create({ earningDate: de.earningDate, estimatedEarning: de.estimatedEarning })
          )
        }
      })
      return sumArray
    },
    get getVideoSumArray(): IDailyVideoPitchEarnings[] {
      const videoSumArray: IDailyVideoPitchEarnings[] = []
      self.list.forEach(de => {
        // check if it exists
        const videoIndex = videoSumArray.findIndex(el => el.videoId === de.videoId) // check also the assetID

        if (videoIndex > -1) {
          videoSumArray[videoIndex].setEstimatedEarning(
            (videoSumArray[videoIndex].estimatedEarning || 0) + (de.estimatedEarning || 0)
          )
        } else {
          videoSumArray.push(
            DailyVideoPitchEarnings.create({
              estimatedEarning: de.estimatedEarning,
              videoTitle: de.videoId,
              videoId: de.videoId,
              assetTitle: de.assetTitle,
              assetUuid: de.assetUuid,
            })
          )
        }
      })

      videoSumArray.sort((a, b) => {
        if (!a.estimatedEarning && !b.estimatedEarning) {
          return 0
        }
        if (!a.estimatedEarning) {
          return 1
        }
        if (!b.estimatedEarning) {
          return -1
        }
        if (a.estimatedEarning < b.estimatedEarning) {
          return 1
        }
        if (a.estimatedEarning > b.estimatedEarning) {
          return -1
        }
        return 0
      })

      return videoSumArray
    },
  }))
  .actions(self => ({
    getDaySum: (earningDate: string) => {
      let totalDay = 0

      self.list.forEach(el => {
        if (el.earningDate === earningDate) {
          totalDay += el.estimatedEarning || 0
        }
      })

      return totalDay
    },
  }))
  .actions(self => ({
    setUserUuidFilter: (uuid: string) => {
      self.userUuidFilter = uuid
    },
    setStartDateFilter: (startDate: string) => {
      self.startDateFilter = startDate
    },
    setEndDateFilter: (endDate: string) => {
      self.endDateFilter = endDate
    },
    load: flow(function* () {
      try {
        self.loading = true

        const variables = {
          userUuid: self.userUuidFilter,
          startDate: self.startDateFilter,
          endDate: self.endDateFilter,
        }

        const resp: IDailySongpitchEstimatedEarningsPerUseResponse = yield dailySongpitchEstimatedEarningsPerUser(
          variables
        )

        if (resp && resp.data.data?.dailySongpitchEstimatedEarningsPerUser) {
          // flatten array
          const flattenResp: IDailyVideoPitchEarnings[] = []

          resp.data.data.dailySongpitchEstimatedEarningsPerUser.forEach(earnArray => {
            flattenResp.push(...earnArray)
          })

          self.list = cast(flattenResp)
        }

        self.loading = false
      } catch (e) {
        console.error(e)
        self.loading = false
      }
    }),

    getChartAccountDailyData: () => {
      const uniqDates = self.getDailySumArray
        .map(el => moment(el.earningDate, 'YYYY-MM-DD').format('DD-MMM'))
        .filter((v, i, a) => a.indexOf(v) === i)
      return {
        categories: uniqDates,

        data: [{ name: 'Earnings', data: uniqDates.map(el => self.getDaySum(el)) }],
      }
    },
  }))

export type IDailyVideoPitchEarningsList = Instance<typeof DailyVideoPitchEarningsList>
