import { CMS_SLUG } from '../general/general'

type TCmsBadgeColorType = {
  [key: string]: string
}

export const CMS_BADGE_COLOR: TCmsBadgeColorType = {
  [CMS_SLUG.EA_GM]: 'info',
  [CMS_SLUG.MEA_EE_PUB]: 'primary',
  [CMS_SLUG.MEA]: 'secondary',
  [CMS_SLUG.EE]: 'info',
  [CMS_SLUG.EE_PUB]: 'success',
  [CMS_SLUG.EA_GMA]: 'secondary-lighten',
}
