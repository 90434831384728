import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { adminShiftFragmentLines } from '../fragments'
import { IAdminShift } from '../../../models'

export type IStartShiftResponse = IGenericResponse<'startShift', IAdminShift>

export async function startShift(): Promise<IStartShiftResponse> {
  const query = `
    mutation startShift {
      startShift {
        ${adminShiftFragmentLines}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, {})) as IStartShiftResponse
}
