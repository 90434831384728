import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteAnnouncementProps = {
  uuid: string
}

export type IDeleteAnnouncementResponse = IGenericResponse<'deleteAnnouncement', boolean>

export async function deleteAnnouncement({ uuid }: IDeleteAnnouncementProps): Promise<IDeleteAnnouncementResponse> {
  const query = `
    mutation deleteAnnouncement($uuid:String!) {
      deleteAnnouncement (
        uuid: $uuid
      )
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { uuid })) as IDeleteAnnouncementResponse
}
