import { useState, useEffect, Fragment, MouseEvent } from 'react'
import classNames from 'classnames'
import { intersectionBy } from 'lodash'
import { Collapse } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IMenuItem } from '../../constants/menus/menu.types'
import MenuItem from './MenuItem'
import { useStore } from '../../hooks'

interface IProps {
  subMenuClassNames?: string
  item: IMenuItem
  linkClassName?: string
  activeMenuItems: string[]
  toggleMenu?: (menuItem: IMenuItem, show: boolean) => void
}

const MenuItemWithChildren = ({
  item,
  linkClassName,
  subMenuClassNames,
  activeMenuItems,
  toggleMenu,
}: IProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(activeMenuItems.includes(item.key))
  const { auth } = useStore()

  useEffect(() => {
    setOpen(activeMenuItems.includes(item.key))
  }, [activeMenuItems, item])

  const toggleMenuItem = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    const status = !open
    setOpen(status)
    if (toggleMenu) toggleMenu(item, status)
    return false
  }

  return (
    <li className={classNames('side-nav-item', { 'menuitem-active': open })}>
      <Link
        to="/#"
        onClick={toggleMenuItem}
        data-menu-key={item.key}
        aria-expanded={open}
        className={classNames('has-arrow', 'side-sub-nav-link', linkClassName, {
          'menuitem-active': activeMenuItems.includes(item.key) ? 'active' : '',
        })}
      >
        {item.icon && <i className={item.icon} />}
        {item.IconElement}
        {!item.badge ? (
          <span className="menu-arrow" />
        ) : (
          <span className={`badge bg-${item.badge.variant} float-end`}>{item.badge.text}</span>
        )}
        <span> {item.name} </span>
      </Link>
      {item.children && (
        <Collapse in={open}>
          <ul className={classNames(subMenuClassNames)}>
            {item.children.map(child => {
              if (
                (!child.roles && !child.permissionsOr && !child.permissionsAnd) ||
                (child.roles && intersectionBy(child.roles, auth.roleNames).length > 0) ||
                (child.permissionsOr && intersectionBy(auth.allPermissions, child.permissionsOr).length > 0) ||
                (child.permissionsAnd &&
                  child.permissionsAnd.map(perm => auth.hasPermission(perm)).reduce((aggr, curr) => aggr && curr))
              ) {
                return (
                  <Fragment key={child.key}>
                    <>
                      {child.children ? (
                        <>
                          {/* parent */}
                          <MenuItemWithChildren
                            item={child}
                            linkClassName={activeMenuItems.includes(child.key) ? 'active' : ''}
                            activeMenuItems={activeMenuItems}
                            subMenuClassNames="side-nav-third-level"
                            toggleMenu={toggleMenu}
                          />
                        </>
                      ) : (
                        <>
                          {/* child */}
                          <MenuItem
                            item={child}
                            className={activeMenuItems.includes(child.key) ? 'menuitem-active' : ''}
                            linkClassName={activeMenuItems.includes(child.key) ? 'active' : ''}
                          />
                        </>
                      )}
                    </>
                  </Fragment>
                )
              }
              return <Fragment key={child.key} />
            })}
          </ul>
        </Collapse>
      )}
    </li>
  )
}

export default MenuItemWithChildren
