import { Instance, cast, destroy, flow, types } from 'mobx-state-tree'
import { createUserGroup, deleteUserGroup, getUserGroups } from '../../api/users-api/userGroup'
import { USER_GROUP_TYPE } from '../../constants'
import { IUserGroup, UserGroup } from './UserGroup.model'

export const UserGroupList = types
  .model({
    list: types.array(UserGroup),
  })
  .volatile(() => ({
    loading: false,
  }))
  .views(self => ({
    byName(userGroupName: string) {
      return self.list.find(userGroup => userGroup.name === userGroupName)
    },
    byUserGroupUuid(userGroupUuid: string) {
      return self.list.find(userGroup => userGroup.uuid === userGroupUuid)
    },
  }))
  .actions(self => ({
    sortUserGroups() {
      self.list = self.list.sort((a: IUserGroup, b: IUserGroup) => {
        if (a.name !== null && b.name !== null) {
          return a.name > b.name ? 1 : -1
        }
        return 0
      })
    },
  }))
  .actions(self => ({
    setUserGroups(userGroups: IUserGroup[]) {
      self.list = cast(userGroups)
      self.sortUserGroups()
    },
    addUserGroup(userGroup: IUserGroup) {
      self.list.push(cast(userGroup))
      self.sortUserGroups()
    },
    remove(userGroupUuid: string) {
      destroy(self.byUserGroupUuid(userGroupUuid))
    },
  }))

  .actions(self => ({
    createUserGroup: async (props: { name: string; type: USER_GROUP_TYPE }) => {
      try {
        const resp = await createUserGroup({ name: props.name, type: props.type })
        if (resp && resp.data.data) {
          self.addUserGroup(resp.data.data.createUserGroup)
          return resp.data.data?.createUserGroup.uuid
        }
      } catch (err) {
        console.error(err)
      }
      return ''
    },
    deleteUserGroup: async (userGroupUuid: string) => {
      try {
        const resp = await deleteUserGroup({ userGroupUuid })
        if (resp && resp.data.data?.deleteUserGroup) {
          self.remove(userGroupUuid)
        }
      } catch (err) {
        console.error(err)
      }
      return ''
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp = yield getUserGroups()

        self.loading = true
        if (resp && resp.data.data) {
          self.setUserGroups(resp.data.data.userGroups)
          self.sortUserGroups()
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))

export type IUserGroupList = Instance<typeof UserGroupList>
