import { useContext, createContext } from 'react'
import { IRootStore } from '../models/RootStore.model'

export const StoreContext = createContext<IRootStore>({} as IRootStore)

export function useStore(): IRootStore {
  const store = useContext(StoreContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }

  return store
}
