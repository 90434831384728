import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ISetIsEarlyPercentageProps = {
  userUuid: string
  isEarlyPercentage: number
}

// ! the return from the backend should be updated to return the user otherwise we need to make another call after this
export type ISetIsEarlyPercentageResponse = IGenericResponse<'setIsEarlyPercentage', boolean>

export async function setIsEarlyPercentage({
  userUuid,
  isEarlyPercentage,
}: ISetIsEarlyPercentageProps): Promise<ISetIsEarlyPercentageResponse> {
  const query = `mutation setIsEarlyPercentage(
    $isEarlyPercentage:Float!
    $userUuid:String!
  ) {
    setIsEarlyPercentage(
      isEarlyPercentage:$isEarlyPercentage
      userUuid:$userUuid
    )
  }`

  return (await clientWrapper(SERVICE_NAME.USERS, query, {
    userUuid,
    isEarlyPercentage,
  })) as ISetIsEarlyPercentageResponse
}
