import { cast, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { ddexUploadCsv, IDdexUploadCsvResponse } from '../../api/assets-api/ddex/ddexUploadCsv'

import { DdexRelease } from './DdexRelease.model'

export const DdexReleaseList = types
  .model({
    list: types.array(DdexRelease),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    clearList() {
      self.loading = false
      self.list = cast([])
    },
    byReleaseUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.release.uuid === uuid)
    },
    ddexGenerateAssetsFromCsv: flow(function* (csvString: string, audios: string[], images: string[]) {
      try {
        self.loading = true
        const releaseUuids: string[] = []
        const res: IDdexUploadCsvResponse = yield ddexUploadCsv({ csvString, audios, images })

        if (res?.data.data?.ddexUploadCsv.errors && res?.data.data?.ddexUploadCsv.errors.length > 0) {
          console.error(res?.data.data?.ddexUploadCsv.errors)
          res?.data.data?.ddexUploadCsv.errors.forEach(err => {
            toast.error(err)
          })
          throw new Error('CSV string upload error.')
        }

        if (res?.data.data?.ddexUploadCsv.releases) {
          const rawReleases = res.data.data.ddexUploadCsv.releases
          rawReleases.forEach(release => {
            const newDdexRelease = DdexRelease.create({
              release,
              audios: [],
            })

            releaseUuids.push(release.uuid || '')

            self.list.push(newDdexRelease)
          })
        }
        self.loading = false

        return releaseUuids
      } catch (err) {
        self.loading = false
        console.error(err)
        return []
      }
    }),
  }))

export type IDdexReleaseList = Instance<typeof DdexReleaseList>
