import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { genresFragment } from '../fragments'

type TGenre = {
  uuid: string
  name: string
}

export type IGetGenresResponseBody = {
  uuid: string
  name: string
  parentGenre?: TGenre
  subGenres?: TGenre[]
}

export type IGetGenresResponse = IGenericResponse<'genres', IGetGenresResponseBody[]>

export type IGetGenresProps = {
  platformGenre?: string
}

export async function getGenres(
  props?: IGetGenresProps,
  fragment: string = genresFragment
): Promise<IGetGenresResponse> {
  const query = `
    query genres {
      genres${props?.platformGenre ? `(type: "${props?.platformGenre}")` : ''} {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query) as Promise<IGetGenresResponse>
}
