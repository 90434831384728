import { SERVICE_NAME } from '../../../../constants'
import { IClaimManager } from '../../../../models/claims/ClaimManager.model'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IGetClaimManagersResponse = IGenericResponse<'claimManagers', IClaimManager[]>

export async function getClaimManagers(): Promise<IGetClaimManagersResponse> {
  const query = `
    query ClaimManagers {
        claimManagers {
            uuid
            userUuid
            managerUuid
            default
            createdAt
            updatedAt
            updatedBy
        }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {})) as IGetClaimManagersResponse
}
