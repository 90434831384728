import { SERVICE_NAME } from '../../../constants'
import { IClaimBasic } from '../../../models/claims/ClaimBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { claimFragment } from './fragments'

export type IGetClaimResponse = IGenericResponse<'claim', IClaimBasic>

export type IGetClaimProps = {
  claimId: string
}

export async function getClaim(
  { claimId }: IGetClaimProps,
  fragment: string = claimFragment
): Promise<IGetClaimResponse> {
  const query = `
    query claim($claimId: String!) {
      claim (claimId: $claimId) {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { claimId })) as IGetClaimResponse
}
