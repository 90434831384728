import { Instance, types } from 'mobx-state-tree'

export const OfxDetails = types.model({
  name: types.string,
  account: types.maybeNull(types.string),
  bankName: types.maybeNull(types.string),
  swift: types.maybeNull(types.string),
  addressLine: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  userCountry: types.maybeNull(types.string),
  beneficiaryId: types.maybeNull(types.string),
})

export type IOfxDetails = Instance<typeof OfxDetails>
