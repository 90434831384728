import { ReactNode } from 'react'
import classNames from 'classnames'

interface IValidationBadge {
  valid: boolean | null
  children?: ReactNode
  className?: string
}

const ValidationBadge = ({ valid, children, className }: IValidationBadge): JSX.Element => {
  return (
    <div
      className={classNames(
        className,
        'badge badge-status shadow-none m-0',
        valid ? 'status-success' : 'status-warning'
      )}
    >
      <i className={classNames('uil uil-check fs-6 ', valid ? 'uil-check' : 'uil-times')}> </i>
      <span>{children}</span>
    </div>
  )
}

export default ValidationBadge
