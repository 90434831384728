import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IImageDeleteResponse = IGenericResponse<'Image_delete', boolean>

export async function imageDelete(uuid: string): Promise<IImageDeleteResponse> {
  const variables = {
    uuid,
  }

  const query = `
    mutation imageDelete ($uuid:String!) {
      Image_delete (uuid: $uuid)  
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IImageDeleteResponse>
}
