import { Instance, types } from 'mobx-state-tree'
import { LICENSE_TYPE } from '../../constants'

export const License = types.model({
  uuid: types.string,
  assetUuid: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  limitUses: types.maybeNull(types.number),
  userUuid: types.maybeNull(types.string),
  type: types.maybeNull(types.enumeration(Object.values(LICENSE_TYPE))),
  channelId: types.maybeNull(types.string),
  channelName: types.maybeNull(types.string),
})

export type ILicense = Instance<typeof License>
