import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IUpdateInternalStatusProps = {
  userPaymentMethodUuid: string
  internalStatus: string
  rejectReason?: string
}

export type IUpdateInternalStatusReturn = IGenericResponse<'updateInternalStatus', boolean>

export const updateInternalStatus = async (props: IUpdateInternalStatusProps): Promise<IUpdateInternalStatusReturn> => {
  const query = `
    mutation updateInternalStatus (
      $userPaymentMethodUuid: String
      $internalStatus: UserPaymentMethodInternalStatus!,
      $rejectReason: String,
    ) {
      updateInternalStatus  (
        userPaymentMethodUuid: $userPaymentMethodUuid,
        internalStatus: $internalStatus,
        rejectReason: $rejectReason,
      )
    }
`

  return (await clientWrapper(SERVICE_NAME.PAYMENTS, query, props)) as IUpdateInternalStatusReturn
}
