import { useMemo } from 'react'
import { debounce } from 'lodash'
import { Form, InputGroup, Button } from 'react-bootstrap'
import { SEARCH_CALL_DELAY } from '@epic-front/common'

interface IDebouncedFilterInputProps {
  className?: string
  isDisabled?: boolean
  minLength?: number
  placeholder: string
  defaultValue: string
  onChange: (value: string) => void
}

const DebouncedFilterInput = ({
  className,
  isDisabled,
  minLength,
  placeholder,
  defaultValue,
  onChange,
}: IDebouncedFilterInputProps): JSX.Element => {
  const debouncedHandler = useMemo(() => debounce(e => onChange(e.target.value), SEARCH_CALL_DELAY), [])

  return (
    <InputGroup className="mb-0">
      <Form.Control
        type="text"
        disabled={isDisabled}
        minLength={minLength}
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={debouncedHandler}
      />
      <Button onKeyDown={debouncedHandler} variant="light" className="input-group-text  border">
        <i className="uil uil-search" />
      </Button>
    </InputGroup>
  )
}

export default DebouncedFilterInput
