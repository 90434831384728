import { DELIVERY_PLATFORM, SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

type IDownloadAssetDistributionFormatProps = {
  deliveryService: DELIVERY_PLATFORM
  releaseUuid: string
}

export type IDownloadAssetDistributionFormatResponse = IGenericResponse<'downloadAssetDistributionFormat', string[]>

export async function downloadAssetDistributionFormat(
  props: IDownloadAssetDistributionFormatProps
): Promise<IDownloadAssetDistributionFormatResponse> {
  const query = `
    mutation downloadAssetDistributionFormat($releaseUuid: String!, $deliveryService: DeliveryService!) {
        downloadAssetDistributionFormat(releaseUuid: $releaseUuid, deliveryService:$deliveryService) 
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IDownloadAssetDistributionFormatResponse>
}
