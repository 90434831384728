import { Instance, types } from 'mobx-state-tree'

export const DailyVideoPitchEarnings = types
  .model({
    earningDate: types.maybeNull(types.string),
    estimatedEarning: types.maybeNull(types.number),
    videoId: types.maybeNull(types.string),
    videoUuid: types.maybeNull(types.string),
    videoTitle: types.maybeNull(types.string),
    pitchUuid: types.maybeNull(types.string),
    assetUuid: types.maybeNull(types.string),
    assetTitle: types.maybeNull(types.string),
  })
  .actions(self => ({
    setEstimatedEarning(earning: number) {
      self.estimatedEarning = earning
    },
  }))

export type IDailyVideoPitchEarnings = Instance<typeof DailyVideoPitchEarnings>
