import { applySnapshot, flow, Instance } from 'mobx-state-tree'
import { getSite, IGetSiteResponse } from '../../api/users-api/other/getSite'
import { SiteBasic } from './SiteBasic.model'

export const Site = SiteBasic.volatile(() => ({
  loading: false,
})).actions(self => ({
  loadCurrent: flow(function* () {
    try {
      self.loading = true
      const resp: IGetSiteResponse = yield getSite()
      if (resp?.data?.data?.site) {
        applySnapshot(self, { ...self, ...resp.data?.data?.site })
      }
      self.loading = false
    } catch (err) {
      self.loading = false
      console.error(err)
    }
  }),
}))

export type ISite = Instance<typeof Site>
