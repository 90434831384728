import { GA_ID } from '@epic-front/common'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import 'react-image-lightbox/style.css'
import { ToastContainer } from 'react-toastify'
import { ErrorBoundary } from './blocks/ErrorBoundary'
import { StoreContext } from './hooks/useStore'
import Routes from './routes/Routes'
import store from './store'

function App(): JSX.Element {
  // we want to make the require dynamic
  /* eslint-disable-next-line   */
  require(`./assets/scss/${process.env.REACT_APP_PLATFORM}.scss`)

  const { isAuth, isTokenExpired, getMe } = store.auth

  useEffect(() => {
    ;(async () => {
      try {
        if (isAuth && !isTokenExpired) {
          await getMe()
          store.init()
        }
      } catch (err) {
        console.error(err)
      }
    })()
  }, [isAuth, getMe])

  useEffect(() => {
    if (GA_ID) {
      ReactGA.initialize(GA_ID)
    }
  }, [])
  return (
    <ErrorBoundary>
      <StoreContext.Provider value={store}>
        <Routes />
        <ToastContainer position="bottom-center" />
      </StoreContext.Provider>
    </ErrorBoundary>
  )
}

export default observer(App)
