import { Instance, types } from 'mobx-state-tree'
import { ArtTrack, IArtTrack } from './artTracks/ArtTrack.model'
import { Release } from './releases/Release.model'

export const DdexRelease = types
  .model({
    release: Release,
    audios: types.array(ArtTrack),
  })
  .volatile(() => ({
    loading: false,
  }))
  .views(self => ({
    get allAssetsHaveFiles() {
      return self.audios.reduce((a, c) => !!c.downloadUrl && a, true)
    },
  }))
  .actions(self => ({
    addAudios(audio: IArtTrack) {
      self.audios.push(audio)
    },
  }))

export type IDdexRelease = Instance<typeof DdexRelease>
