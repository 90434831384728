import ReactDOM from 'react-dom/client'
import moment from 'moment'

import App from './App'
import reportWebVitals from './reportWebVitals'

moment.relativeTimeThreshold('d', 31)

// eslint-disable-next-line   @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
