import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

import { SERVICE_NAME } from '../../constants'

export type IGetChannelDailyViewsResponse = IGenericResponse<
  'channelDailyViews',
  { days: { date: string; views: number }[]; total: number; percentage?: number | null }
>

type IGetChannelDailyViewsProps = {
  userUuid?: string
  // assetUuid?: string
  channelUuid?: string
}

export async function getChannelDailyViews(input?: IGetChannelDailyViewsProps): Promise<IGetChannelDailyViewsResponse> {
  const query = `
    query channelDailyViews($input: ChannelDailyViewsInputData) {
      channelDailyViews(input: $input)
      {
        days {
          date
          views
        }
        total
        percentage
      }
    }
  `

  return clientWrapper(SERVICE_NAME.ASSETS, query, { input }) as Promise<IGetChannelDailyViewsResponse>
}
