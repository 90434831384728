import { Instance, cast, flow } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { participantCreateOrUpdate } from '../../api/assets-api'
import { IParticipantCreateUpdateResponse } from '../../api/types'
import { DELIVERY_PLATFORM } from '../../constants'
import { IParticipantExternalLinkFormData, IParticipantFormData, ParticipantBasic } from './ParticipantBasic'

export const Participant = ParticipantBasic.named('Participant')
  .views(self => ({
    get asBasicModel() {
      return {
        uuid: self.uuid || '',
        name: self.name || '',
        externalLinksToStores: (self.externalLinksToStores || []).map(p => ({
          platformName: p.platformName,
          platformUrl: p.platformUrl,
          imageUrl: p.imageUrl || undefined,
        })),
      }
    },
  }))
  .views(self => ({
    get externalImageLink() {
      if (!self.externalLinksToStores || self.externalLinksToStores.length === 0) {
        return ''
      }
      const link = self.externalLinksToStores.find(
        extLnk => extLnk.platformName === DELIVERY_PLATFORM.SPOTIFY
      )?.imageUrl
      return link || ''
    },
    get externalSpotifyLink() {
      if (!self.externalLinksToStores || self.externalLinksToStores.length === 0) {
        return ''
      }
      const link = self.externalLinksToStores.find(
        extLnk => extLnk.platformName === DELIVERY_PLATFORM.SPOTIFY
      )?.platformUrl
      return link || ''
    },
  }))
  .actions(self => ({
    setUuid(uuid: string) {
      self.uuid = uuid
    },
  }))
  .actions(self => ({
    createUpdate: flow(function* (info: IParticipantFormData, successMessage = '') {
      try {
        const resp: IParticipantCreateUpdateResponse = yield participantCreateOrUpdate(info)
        if (resp && resp.data.data?.Participant_createOrUpdate.uuid && successMessage) {
          self.setUuid(resp.data.data?.Participant_createOrUpdate.uuid)
          toast.success(successMessage)
        }
      } catch (err) {
        console.error(err)
      }
    }),
  }))
  .actions(self => ({
    setExternalLinks(links: IParticipantExternalLinkFormData[]) {
      self.externalLinksToStores = cast(links)
      self.createUpdate({
        uuid: self.uuid || '',
        externalLinksToStores: links,
      })
    },
  }))

export type IParticipant = Instance<typeof Participant>
