import { SERVICE_NAME } from '../../../constants'
import { IContactRequest } from '../../../models'
import { IPaginationParams } from '../../../types'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { contactRequestFragment } from '../fragments'

export type IContactFormsResponse = IGenericResponse<'contactForms', { total: number; contactForms: IContactRequest[] }>

export type IContactFormsProps = {
  pagination?: IPaginationParams
  filters?: {
    siteUuid?: string
    status?: string
    subjectType?: string
    email?: string
    month?: string
    updatedBy?: string
  }
}

export async function getContactForms(
  props: IContactFormsProps,
  fragment: string = contactRequestFragment
): Promise<IContactFormsResponse> {
  const query = `#graphql
  query getContactForms($filters: ContactFormFilters,$pagination: PaginationInput) {
    contactForms(filters: $filters, pagination: $pagination) {
        total
        contactForms 
        {
           ${fragment}
        }
      }
    }
`

  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<IContactFormsResponse>
}
