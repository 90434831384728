import React from 'react'

import { SvgIconProps } from './iconProps'

const IconBankTransferManual: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g className="Vector">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85182 11.422C4.68944 11.1408 5.56732 10.9983 6.45084 11C6.45124 11 6.45164 11 6.45205 11L6.45 12L6.44963 11C6.45003 11 6.45043 11 6.45084 11C7.72262 10.9997 8.97699 11.2956 10.1145 11.8642L10.1118 11.8629L9.67 12.76L10.1175 11.8657C10.1165 11.8652 10.1155 11.8647 10.1145 11.8642L13.4593 13.5115L13.4764 13.5208C13.8981 13.7493 14.2514 14.086 14.4998 14.4964C14.7483 14.9067 14.8829 15.3758 14.8899 15.8555L14.89 15.8627V15.99C14.89 16.7061 14.6055 17.3928 14.0992 17.8992C13.6054 18.3929 12.9401 18.6757 12.2434 18.6895C12.2606 18.688 12.2778 18.6863 12.295 18.6845L12.19 17.69V18.69C12.2078 18.69 12.2256 18.6898 12.2434 18.6895C12.0509 18.7064 11.8572 18.7048 11.665 18.6845L11.5947 18.6771L7.9062 17.7498L8.3938 15.8102L11.9334 16.7C11.9139 16.6991 11.8944 16.6976 11.875 16.6955L11.77 17.69L12.0138 16.7202L11.9334 16.7C11.984 16.7023 12.0346 16.7008 12.085 16.6955L12.1374 16.69H12.19C12.3757 16.69 12.5537 16.6162 12.685 16.485C12.8163 16.3537 12.89 16.1757 12.89 15.99V15.8789C12.89 15.8807 12.8901 15.8826 12.8901 15.8845L13.89 15.87H12.89V15.8789C12.8872 15.7565 12.8524 15.6369 12.789 15.5322C12.7272 15.4301 12.6404 15.3456 12.5369 15.2866L12.5582 15.2971L13 14.4L12.5236 15.2792C12.5281 15.2817 12.5325 15.2841 12.5369 15.2866L9.22252 13.6543C8.36189 13.2237 7.41272 12.9996 6.45037 13L6.44795 13C5.78493 12.9986 5.12612 13.105 4.49727 13.315L4.50701 13.3115L4.17 12.37L4.48818 13.318C4.49121 13.317 4.49424 13.316 4.49727 13.315L2.58358 14H0.5V12H2.23642L2.07299 12.0585L2.41 13V12H2.23642L3.84237 11.4251L3.85182 11.422Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8519 14.8301C21.5544 14.8384 22.2255 15.1223 22.7208 15.6205C23.216 16.1188 23.4959 16.7916 23.5 17.4941L23.5 17.5L23.5 17.6098C23.5 17.6099 23.5 17.6098 23.5 17.6098C23.5001 18.1288 23.3489 18.6367 23.065 19.0711C22.7812 19.5052 22.3772 19.8472 21.9023 20.0555C21.9019 20.0557 21.9016 20.0558 21.9012 20.056L15.4029 22.9152C15.4029 22.9153 15.403 22.9152 15.4029 22.9152C14.5453 23.2928 13.6186 23.4885 12.6816 23.49L12.6778 23.49C12.1323 23.4888 11.5889 23.4219 11.0594 23.2906L4.17758 21.58H0.5V19.58H4.42242L11.5406 21.3494C11.5408 21.3494 11.5403 21.3493 11.5406 21.3494M20.8519 14.8301L20.8381 14.83ZM20.6428 16.8549L14.0502 18.74H12V16.74H13.7698L20.1152 14.9256L20.1253 14.923C20.358 14.8617 20.5975 14.8305 20.8381 14.83M20.6428 16.8549C20.7056 16.8391 20.7701 16.8307 20.8348 16.83C21.0106 16.8339 21.1782 16.9057 21.3023 17.0305C21.4274 17.1564 21.4984 17.3262 21.5 17.5036V17.61C21.5 17.7402 21.4621 17.8678 21.3908 17.9769C21.3196 18.0859 21.2181 18.1718 21.0988 18.224L14.5973 21.0847C13.9932 21.3506 13.3404 21.4887 12.6804 21.49"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.48 3.45001H19.82V5.45001H16.4845C16.3692 5.45146 16.2591 5.49809 16.1778 5.57994C16.0959 5.66236 16.05 5.77382 16.05 5.88999C16.05 6.00403 16.0953 6.11343 16.1759 6.19407C16.2566 6.27471 16.366 6.32001 16.48 6.32001H17.48C18.1245 6.32001 18.7426 6.57603 19.1983 7.03174C19.654 7.48746 19.91 8.10554 19.91 8.75001C19.91 9.39449 19.654 10.0126 19.1983 10.4683C18.7426 10.924 18.1245 11.18 17.48 11.18H14.14V9.18001H17.48C17.594 9.18001 17.7034 9.13471 17.7841 9.05407C17.8647 8.97343 17.91 8.86406 17.91 8.75001C17.91 8.63597 17.8647 8.5266 17.7841 8.44596C17.7034 8.36532 17.594 8.32001 17.48 8.32001H16.48C15.8355 8.32001 15.2174 8.06399 14.7617 7.60828C14.306 7.15257 14.05 6.5345 14.05 5.89004M16.48 3.45001L16.4731 3.45004ZM16.4731 3.45004C15.8289 3.45451 15.2126 3.71355 14.7587 4.17066ZM14.7587 4.17066C14.3047 4.62776 14.05 5.24584 14.05 5.89004Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 4.44999V2.54999H18V4.44999H16Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 12.09V10.18H18V12.09H16Z" />
    </g>
  </svg>
)

export default IconBankTransferManual
