import { Instance, types } from 'mobx-state-tree'

export const PayoneerDetails = types.model({
  registration_link: types.string,
  audit_id: types.maybeNull(types.number),
  code: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  token: types.maybeNull(types.string),
})

export type IPayoneerDetails = Instance<typeof PayoneerDetails>
