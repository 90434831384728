import { IArtTrack, IRelease } from '@epic-front/common/src/models'
import { getSnapshot } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'

interface IUseDualErrorHandlingProps {
  errors: ErrorResponse
  asset: IArtTrack | IRelease
}

type ErrorResponse = { [key: string]: { message?: string; ref?: number } | FieldError }

interface IUseDualErrorHandlingOutput {
  derivedErrors: ErrorResponse
}

const getBEValidationErrorsChange = (asset: IArtTrack | IRelease) => {
  if (asset?.validationErrors !== null) {
    return JSON.stringify(getSnapshot(asset.validationErrors))
  }
  return ''
}

const getFEValidationErrorsChange = (errors: ErrorResponse) => {
  const newErrors = { ...errors }
  // To prevent cyclic object values error, we need to remove the references from the error object
  Object.keys(errors).forEach((key: string) => {
    delete newErrors[key as string].ref
  })
  return JSON.stringify(errors)
}

export const useDualErrorHandling = (props: IUseDualErrorHandlingProps): IUseDualErrorHandlingOutput => {
  const [derivedErrors, setDerivedErrors] = useState<ErrorResponse>({})
  useEffect(() => {
    const backendErrors: ErrorResponse = {}

    props.asset?.validationErrors?.forEach((obj: { field: string; message: string }) => {
      backendErrors[obj.field as string] = { message: obj.message }
    })

    const newErrors = { ...props.errors, ...backendErrors }
    setDerivedErrors({ ...newErrors })
  }, [getFEValidationErrorsChange(props.errors), getBEValidationErrorsChange(props.asset)])
  return { derivedErrors }
}
