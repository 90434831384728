import { types, Instance } from 'mobx-state-tree'

export const Permission = types.model({
  name: types.string,
  description: types.string,
  detailedDescription: types.maybeNull(types.string),
  active: types.boolean,
})

export type IPermission = Instance<typeof Permission>
