import { Instance, cast, flow, types } from 'mobx-state-tree'
import {
  IContactFormsProps,
  IContactFormsResponse,
  getContactForms,
} from '../../api/users-api/contactRequests/getContactForms'
import { Pagination } from '../general/Pagination.model'
import { ContactRequest, IContactRequest } from './ContactRequest.model'

export const ContactRequestList = types
  .model({
    list: types.array(ContactRequest),
  })
  .volatile(() => ({
    loading: false,
    pagination: Pagination.create({ totalItems: 1 }),
    siteUuidFilter: '',
    statusFilter: '',
    subjectTypeFilter: '',
    emailFilter: '',
    monthFilter: '',
    updatedByFilter: '',
  }))
  .views(self => ({
    byUuid(uuid: string) {
      return self.list.find(announcement => announcement.uuid === uuid)
    },
  }))
  .actions(self => ({
    setSiteFilter(site: string) {
      self.siteUuidFilter = site
    },
    setStatusFilter(status: string) {
      self.statusFilter = status
    },
    setSubjectFilter(subject: string) {
      self.subjectTypeFilter = subject
    },
    setMonthFilter(month: string) {
      self.monthFilter = month
    },
    setEmailFilter(email: string) {
      self.emailFilter = email
    },
    setUpdatedByFilter(updatedBy: string) {
      self.updatedByFilter = updatedBy
    },
  }))

  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const variables: IContactFormsProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.statusFilter && { status: self.statusFilter }),
            ...(self.subjectTypeFilter && { subjectType: self.subjectTypeFilter }),
            ...(self.emailFilter && { email: self.emailFilter }),
            ...(self.monthFilter && { month: self.monthFilter }),
            ...(self.updatedByFilter && { updatedBy: self.updatedByFilter }),
          },
        }

        const resp: IContactFormsResponse = yield getContactForms(variables)

        if (resp && resp.data.data) {
          self.list = cast(resp.data.data.contactForms.contactForms as IContactRequest[])
          self.pagination.setTotalItems(resp.data.data.contactForms.total)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))

export type IContactRequestList = Instance<typeof ContactRequestList>
