import { Modal } from '@blocks'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'

interface IRejectModalProps {
  open: boolean
  closeModal: () => void
  submit: (reason: string) => void
}

export const RejectModal = ({ open, closeModal, submit }: IRejectModalProps): JSX.Element => {
  const [reason, setReason] = useState<string>('')

  const Buttons = (
    <>
      <Button className="btn btn-gray-400" onClick={closeModal}>
        <i className="uil-times" />
        Cancel
      </Button>
      <Button
        variant="danger"
        onClick={() => {
          submit(reason)
        }}
      >
        <i className="uil-check" />
        Confirm Reject
      </Button>
    </>
  )

  return (
    <Modal buttons={Buttons} onHide={closeModal} title="Confirm Reject" isOpen={open} size="md">
      <h5 className="alert alert-warning text-center">Are you sure you want to permanently reject this?</h5>
      <Form.Label htmlFor="rejectComment">Reject Reason</Form.Label>
      <Form.Control
        as="textarea"
        rows={6}
        name="rejectComment"
        placeholder="Please a reason for rejecting this.."
        onChange={e => {
          setReason(e.target.value)
        }}
      />
    </Modal>
  )
}
