import { Instance, cast, flow, types } from 'mobx-state-tree'
import moment from 'moment'
import { IGetChannelDailyViewsResponse, getChannelDailyViews } from '../../api/assets-api/getChannelDailyViews'

export const ChannelViewsDailyList = types
  .model({
    list: types.array(
      types.model({
        date: types.string,
        views: types.number,
      })
    ),
    percentageDifference: types.maybeNull(types.number),
    total: types.maybeNull(types.number),
  })
  .volatile(() => ({
    loading: true,
    channelUuidFilter: '',
    // add filters for start date and end date
  }))

  .actions(self => ({
    setChannelUuidFilter(value: string) {
      self.channelUuidFilter = value
    },
    load: flow(function* () {
      try {
        self.list = cast([])
        self.loading = true
        const resp: IGetChannelDailyViewsResponse = yield getChannelDailyViews(
          self.channelUuidFilter ? { channelUuid: self.channelUuidFilter } : undefined
        )
        const orderedDailyViews = resp?.data.data?.channelDailyViews.days || []
        orderedDailyViews.sort((a, b) => (a.date > b.date ? 1 : -1))
        if (orderedDailyViews) {
          self.list = cast(orderedDailyViews)

          self.total = resp?.data.data?.channelDailyViews.total || 0
          self.percentageDifference = resp?.data.data?.channelDailyViews.percentage || null
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),

    getCategs() {
      return self.list.map(el => moment(el.date).format('DD-MMM'))
    },
    getDataArray() {
      return self.list.map(el => el.views)
    },
  }))

export type IChannelViewsDailyList = Instance<typeof ChannelViewsDailyList>
