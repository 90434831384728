import { Instance, types } from 'mobx-state-tree'
import { IMAGE_TYPE } from '../../constants'

export const ImageBasic = types.model({
  uuid: types.maybeNull(types.string),
  fileName: types.maybeNull(types.string),
  downloadUrl: types.maybeNull(types.string),
  thumbnailDownloadUrl: types.maybeNull(types.string),
  releaseUuid: types.maybeNull(types.string),
  uploadUrl: types.maybeNull(types.string),
  type: types.maybeNull(types.enumeration(Object.values(IMAGE_TYPE))),
})

export type IImageBasic = Instance<typeof ImageBasic>
