import {
  AudioAlbum,
  AudioWave,
  Broadcast,
  Bullhorn,
  DashboardPerformance,
  DiplomaCertificate,
  DollarSignCircle,
  Gear,
  MultipleArrowsUp,
  ShieldDocument,
  UserArrows,
  UserFile,
  Users,
  VideoPlayerWebsite,
  WindowShield,
  XmarkShield,
} from '@vectopus/atlas-icons-react'
import { PERMISSIONS } from 'e2e-utils'
import { ADMIN_ROUTES } from '../../routes/platformRoutes/adminRoutes'
import { IMenuItem } from './menu.types'

const MENU_ITEMS_ADMIN: IMenuItem[] = [
  { key: 'admin-dashboard', name: 'Admin Dashboard', isTitle: true },
  { ...ADMIN_ROUTES.ROOT, IconElement: <DashboardPerformance size={20} className="me-2" /> },
  {
    key: 'communication-holder',
    name: 'Communication',
    IconElement: <Bullhorn size={20} className="me-2" />,
    permissionsOr: [PERMISSIONS.PAGES.MANAGE_SIGNUP_REQUESTS, PERMISSIONS.PAGES.MANAGE_ANNOUNCEMENTS],
    children: [ADMIN_ROUTES.ANNOUNCEMENTS_LIST, ADMIN_ROUTES.CONTACT_REQUESTS_LIST],
  },
  {
    key: 'users-holder',
    name: 'Users',
    IconElement: <Users size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.ADD_USERS,
      PERMISSIONS.PAGES.LIST_USERS,
      PERMISSIONS.PAGES.PERMISSIONS,
      PERMISSIONS.PAGES.MANAGE_USER_LABELS,
      PERMISSIONS.PAGES.MANAGE_USER_GROUPS,
      // PERMISSIONS.PAGES.AFFILIATIONS,
    ],
    children: [
      ADMIN_ROUTES.USER_LIST,
      ADMIN_ROUTES.USER_ADD,
      ADMIN_ROUTES.MANAGE_PERMISSIONS,
      ADMIN_ROUTES.MANAGE_USER_LABELS,
      ADMIN_ROUTES.MANAGE_USER_GROUPS,
      ADMIN_ROUTES.AFFILIATIONS_LIST,
    ],
  },
  {
    key: 'onboarding',
    name: 'Client Onboarding',
    IconElement: <UserArrows size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.DOCUMENTS,
      PERMISSIONS.PAGES.INVITATIONS,
      PERMISSIONS.PAGES.REVENUE_SPLIT_INVITATIONS,
      PERMISSIONS.PAGES.ONBOARDING_OVERVIEW,
    ],
    children: [
      ADMIN_ROUTES.ONBOARDING_OVERVIEW,
      ADMIN_ROUTES.MANAGE_INVITATIONS,
      ADMIN_ROUTES.MANAGE_CONTRACTS,
      ADMIN_ROUTES.MANAGE_REVENUE_SPLIT_INVITATIONS,
    ],
  },
  {
    key: 'client-payment-details',
    name: 'Client Payment Detail',
    IconElement: <UserFile size={20} className="me-2" />,
    permissionsOr: [PERMISSIONS.PAGES.MANAGE_TAX_FORMS, PERMISSIONS.PAGES.MANAGE_USER_PAYMENT_METHODS],
    children: [ADMIN_ROUTES.MANAGE_TAX_FORMS, ADMIN_ROUTES.MANAGE_USER_PAYMENT_METHODS],
  },
  {
    key: 'configs',
    name: 'Configs',
    IconElement: <Gear size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.MANAGE_GENRES,
      PERMISSIONS.PAGES.REFRESH_AUDIOSALAD_TOKEN,
      PERMISSIONS.PAGES.REDIS_FLUSH_ALL,
      PERMISSIONS.PAGES.VIDEOS_COMPOSITIONS_CSV,
      PERMISSIONS.PAGES.LOGS,
      PERMISSIONS.PAGES.CURRENCY_SETUP,
      PERMISSIONS.PAGES.DISTRIBUTION_CONFIGS,
    ],
    children: [
      ADMIN_ROUTES.MANAGE_GENRE_LIST,
      ADMIN_ROUTES.CURRENCY_SETUP,
      ADMIN_ROUTES.REFRESH_AUDIOSALAD_TOKEN,
      ADMIN_ROUTES.REDIS_FLUSH_ALL,
      ADMIN_ROUTES.VIDEOS_COMPOSITIONS_CSV,
      ADMIN_ROUTES.LOG_LIST,
      ADMIN_ROUTES.DISTRIBUTION_CONFIGS,
    ],
  },

  { key: 'admin-catalogue', name: 'Catalogue', isTitle: true },
  { ...ADMIN_ROUTES.ASSETS_ALL, IconElement: <AudioAlbum size={20} className="me-2" /> },
  {
    key: 'content-upload',
    name: 'Content Upload',
    IconElement: <AudioWave size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.UPLOAD_AUDIO,
      PERMISSIONS.PAGES.UPLOAD_VIDEO,
      PERMISSIONS.PAGES.CREATE_AUDIO_RELEASE,
      PERMISSIONS.PAGES.DDEX,
      PERMISSIONS.PAGES.IMPORT_AUDIO_RELEASE,
    ],
    children: [
      ADMIN_ROUTES.CREATE_AUDIO_RELEASE,
      ADMIN_ROUTES.DDEX_UPLOAD,
      ADMIN_ROUTES.AUDIO_UPLOAD,
      ADMIN_ROUTES.AUDIO_UPLOAD_CSV,
      ADMIN_ROUTES.VIDEO_UPLOAD,
    ],
  },
  {
    key: 'distribution-tab',
    name: 'Distribution',
    IconElement: <MultipleArrowsUp size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.MANAGE_RELEASES,
      PERMISSIONS.PAGES.MANAGE_IMPORTED_RELEASES,
      PERMISSIONS.PAGES.MANAGE_ANALYTICS,
      PERMISSIONS.PAGES.ISRC_MANAGER,
      PERMISSIONS.PAGES.AUDIO_RELEASE_LOGS,
    ],
    children: [
      ADMIN_ROUTES.MANAGE_AUDIO_RELEASE,
      ADMIN_ROUTES.ART_TRACKS_ADMIN_LIST,
      ADMIN_ROUTES.MANAGE_IMPORTED_AUDIO_RELEASE,
      ADMIN_ROUTES.MANAGE_ANALYTICS,
      ADMIN_ROUTES.MANAGE_ISRC_UPC,
      ADMIN_ROUTES.AUDIO_RELEASES_LOGS,
      ADMIN_ROUTES.IMPORT_ASSETS_SPOTIFY,
    ],
  },
  {
    key: 'content-id-assets',
    name: 'YouTube Assets',
    IconElement: <WindowShield size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.CONTENT_ID_ASSETS,
      PERMISSIONS.PAGES.MANAGE_AUDIOS,
      PERMISSIONS.PAGES.MANAGE_VIDEOS,
    ],
    children: [ADMIN_ROUTES.ASSETS_CONTENT_ID, ADMIN_ROUTES.MANAGE_ADDED_AUDIOS, ADMIN_ROUTES.MANAGE_ADDED_VIDEOS],
  },
  {
    key: 'content-id-issues',
    name: 'YouTube Claims',
    IconElement: <XmarkShield size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.CLAIMS,
      PERMISSIONS.PAGES.CLAIM_ISSUES_APPEALED,
      PERMISSIONS.PAGES.CLAIM_ISSUES_DISPUTED,
      PERMISSIONS.PAGES.CLAIM_ISSUES_POTENTIAL,
      PERMISSIONS.PAGES.CLAIM_ISSUES_RESOLVED,
      PERMISSIONS.PAGES.CLAIM_MANAGERS,
      PERMISSIONS.PAGES.CLAIM_EMAIL_TEMPLATES,
    ],
    children: [
      ADMIN_ROUTES.CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.APPEALED_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.DISPUTED_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.POTENTIAL_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.RESOLVED_CLAIMS_CONTENT_ID,
      ADMIN_ROUTES.CLAIM_MANAGERS,
      ADMIN_ROUTES.CLAIM_EMAIL_TEMPLATES,
    ],
  },
  {
    key: 'channels-tab',
    name: 'Channels',
    IconElement: <VideoPlayerWebsite size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.CHANNELS,
      PERMISSIONS.PAGES.CHANNEL_VIDEOS,
      PERMISSIONS.PAGES.CHANNEL_WHITELISTING,
    ],
    children: [ADMIN_ROUTES.CHANNEL_LIST, ADMIN_ROUTES.CHANNEL_VIDEOS, ADMIN_ROUTES.CHANNEL_WHITELISTING],
  },
  {
    key: 'songpitch',
    name: 'SongPitch',
    IconElement: <Broadcast size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.SONGPITCH_PITCHES,
      PERMISSIONS.PAGES.SONGPITCH_VIDEOS,
      PERMISSIONS.PAGES.ISRC_EMBEDDING,
    ],
    children: [ADMIN_ROUTES.PITCHES_LIST, ADMIN_ROUTES.PITCHES_VIDEO_LIST, ADMIN_ROUTES.ISRC_EMBED],
  },
  {
    key: 'microlicensing',
    name: 'Microlicensing',
    IconElement: <DiplomaCertificate size={20} className="me-2" />,
    permissionsOr: [PERMISSIONS.PAGES.MICROLICENSING_ASSETS, PERMISSIONS.PAGES.MICROLICENSING_ORDERS],
    children: [ADMIN_ROUTES.ASSETS_MICROLICENSING, ADMIN_ROUTES.ORDERS_MICROLICENSING],
  },
  { ...ADMIN_ROUTES.LICENSES_LIST, IconElement: <ShieldDocument size={20} className="me-2" /> },

  { key: 'admin-finance', name: 'Earnings & Payments', isTitle: true },
  {
    key: 'earnings-payment',
    name: 'Earnings & Payments',
    IconElement: <DollarSignCircle size={20} className="me-2" />,
    permissionsOr: [
      PERMISSIONS.PAGES.PAYMENTS,
      PERMISSIONS.PAGES.EARNINGS,
      PERMISSIONS.PAGES.BULK_EARNING_CSV_UPLOAD,
      PERMISSIONS.PAGES.SPONSORSHIPS,
      PERMISSIONS.PAGES.DISTRIBUTION_EARNINGS,
      PERMISSIONS.PAGES.MONTHLY_REPORT,
      PERMISSIONS.PAGES.MONTHLY_HIGHLIGHT_REPORT,
    ],
    children: [
      ADMIN_ROUTES.PAYMENT_LIST,
      ADMIN_ROUTES.EARNINGS_LIST,
      ADMIN_ROUTES.BULK_EARNING_CSV_UPLOAD,
      ADMIN_ROUTES.SPONSORSHIPS_LIST,
      ADMIN_ROUTES.PAYMENT_ADMIN_REPORT,
      ADMIN_ROUTES.PAYMENT_ADMIN_HIGHLIGHT_REPORT,
      ADMIN_ROUTES.DISTRIBUTION_EARNINGS,
    ],
  },
]

export default MENU_ITEMS_ADMIN
