import { PLATFORM_SHORT_NAME } from '@epic-front/common/src/constants'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import { useStore } from '../../hooks'
import FeaturedIcon from '../FeaturedIcon'

type BreadcrumbItems = {
  label: string
  path: string
  active?: boolean
}

type PageTitleProps = {
  breadCrumbItems: Array<BreadcrumbItems>
  title: string
  icon?: JSX.Element
}

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps): JSX.Element => {
  const { site } = useStore()
  return (
    <Row>
      <Col className="col-12 d-flex flex-column my-3 gap-2">
        <div className="d-flex flex-row gap-3 align-items-center border-bottom pb-3">
          {props.icon /* Conditionally render iconwrap only if icon is defined */ && (
            <FeaturedIcon size="xl" color="primary" icon={props.icon} />
          )}
          <div className="d-flex flex-column justify-content-between">
            <h1 className="fs-2 fw-medium mb-0 mt-1">{props.title}</h1>
            <Breadcrumb className="m-0">
              <Breadcrumb.Item href={site?.url || '/'}>
                <b>{PLATFORM_SHORT_NAME}</b>
              </Breadcrumb.Item>

              {props.breadCrumbItems.map(item => {
                return item.active ? (
                  <Breadcrumb.Item active key={item.path}>
                    {item.label}
                  </Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item key={item.path} href={item.path}>
                    {item.label}
                  </Breadcrumb.Item>
                )
              })}
            </Breadcrumb>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default PageTitle
