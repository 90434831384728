import { SERVICE_NAME } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IPreviewClaimsEmailProps = {
  claimUuids: string[]
  emailTemplateUuid: string
}

export type IPreviewClaimsEmailResponse = IGenericResponse<
  'previewClaimsEmail',
  {
    template: {
      uuid: string
      subject: string
      body: string
    }
    errors: string[]
  }
>

export async function previewClaimsEmail(input: IPreviewClaimsEmailProps): Promise<IPreviewClaimsEmailResponse> {
  const query = `
    query previewClaimsEmail($input: PreviewClaimsEmailTemplateFilterInput!) {
      previewClaimsEmail(input: $input) {
          template {
            uuid
            subject
            body
          }
          errors
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { input })) as IPreviewClaimsEmailResponse
}
