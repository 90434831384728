import { Instance, types } from 'mobx-state-tree'
import { Asset } from '../assets'
import { Channel } from '../assets/Channel.model'
import { Pitch } from './Pitch.model'

const AssetToYoutubeVideo = types.model({
  videoId: types.string,
  assetUuid: types.maybeNull(types.string),
  channelVideo: types.maybeNull(Asset),
})

export const PitchChannelVideo = types.model({
  channelUuid: types.string,
  status: types.string,
  channel: types.maybeNull(Channel),
  pitchUuid: types.string,
  pitcherUuid: types.maybeNull(types.string),
  pitch: Pitch,
  videos: types.array(AssetToYoutubeVideo),
  asset: types.maybeNull(Asset),
})

export type IPitchChannelVideo = Instance<typeof PitchChannelVideo>
