import { SERVICE_NAME } from '../../constants'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'
import { assignUserToChannelFragment } from './fragments'

type TAssignUserResponseBody = {
  uuid: string
  userUuid: string
  siteUuid: string
}

type IAssignUserToChannelProps = {
  userUuid: string
  channelUuid: string
}

export type IAssignUserToChannelResponse = IGenericResponse<'assignUserToChannel', TAssignUserResponseBody>

export async function assignUserToChannel(
  props: IAssignUserToChannelProps,
  fragment: string = assignUserToChannelFragment
): Promise<IAssignUserToChannelResponse> {
  const query = `
    mutation assignUserToChannel($userUuid: String!, $channelUuid: String!) {
      assignUserToChannel(channelUuid: $channelUuid, userUuid: $userUuid) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IAssignUserToChannelResponse>
}
