import { MAX_RETRIES, SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'

import clientWrapper from '../../clientWrapper'

export interface IResetPasswordProps {
  oldPassword: string
  newPassword: string
  clearAllSessions?: boolean
}

export type IResetPasswordResponse = IGenericResponse<
  'resetPassword',
  {
    uuid: string
  }
>

export async function resetPassword(props: IResetPasswordProps): Promise<IResetPasswordResponse> {
  const query = `
    mutation resetPassword ($oldPassword: String!, $newPassword: String!) {
      resetPassword (newPassword: $newPassword, oldPassword: $oldPassword) {
        uuid
      }
    }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, props, MAX_RETRIES) as Promise<IResetPasswordResponse>
}
