import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IResetPaymentMethodProps = {
  userUuid: string
}

export type IResetPaymentMethodResponse = IGenericResponse<'resetPaymentMethod', boolean>

export async function resetPaymentMethod(props: IResetPaymentMethodProps): Promise<IResetPaymentMethodResponse> {
  const query = `
    mutation reset ($userUuid: String!) {
      resetPaymentMethod(userUuid: $userUuid)
    }
  `

  return (await clientWrapper(SERVICE_NAME.PAYMENTS, query, props)) as IResetPaymentMethodResponse
}
