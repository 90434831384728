import { ReactNode } from 'react'
import { Container, Spinner } from 'react-bootstrap'

interface ILoadingWrapper {
  isLoading: boolean | undefined | null
  children: ReactNode
  containerClass?: string
}

const LoadingWrapper = ({ isLoading, children, containerClass }: ILoadingWrapper): JSX.Element => {
  return (
    <>
      {isLoading && (
        <Container className={containerClass || 'text-center my-5'}>
          <Spinner animation="border" />
        </Container>
      )}
      {!isLoading && children}
    </>
  )
}

export default LoadingWrapper
