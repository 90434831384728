import { Navigate } from 'react-router-dom'
import { ISiteRouteList, RouteType } from './types'

export const commonPrivateRoutes = (siteRoutesList: ISiteRouteList = {}): ISiteRouteList => {
  const generateRoutesArray: string[] = Object.keys(siteRoutesList).map(key => siteRoutesList[key]?.url)

  return {
    ERROR_404_AUTH: {
      url: '*',
      name: 'Error 404',
      element: generateRoutesArray.includes(window.location.pathname) ? (
        <Navigate to={{ pathname: '/account/login' }} state={{ from: window.location.pathname }} />
      ) : (
        <Navigate to="/error-404" />
      ),
      routeType: RouteType.AUTH_PROTECTED,
      key: 'ERROR_404_AUTH',
    },
  }
}
