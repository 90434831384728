import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { IChannelBasic } from '../../../models/assets/ChannelBasic.model'
import { SERVICE_NAME } from '../../../constants'
import { channelFragmentLines } from '../fragments'

export type IGetChannelResponse = IGenericResponse<'channel', IChannelBasic>

export type IGetChannelProps = {
  uuid: string
}

export async function getChannel(
  { uuid }: IGetChannelProps,
  fragment: string = channelFragmentLines
): Promise<IGetChannelResponse> {
  const query = `
    query channel($uuid: String!) {
      channel (uuid: $uuid) {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { uuid })) as IGetChannelResponse
}
