import { Instance, types } from 'mobx-state-tree'

export const SpotifyAlbum = types.model({
  albumType: types.string,
  id: types.string,
  name: types.maybeNull(types.string),
  url: types.string,
  imageUrl: types.maybeNull(types.string),
})

export type ISpotifyAlbum = Instance<typeof SpotifyAlbum>
