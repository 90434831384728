import { Instance, types } from 'mobx-state-tree'

const ClaimTypeDetails = types.model({
  appealExplanation: types.maybeNull(types.string),
  updateStatus: types.maybeNull(types.string),
  disputeReason: types.maybeNull(types.string),
  disputeNotes: types.maybeNull(types.string),
})

export type IClaimTypeDetails = Instance<typeof ClaimTypeDetails>

const Source = types.model({
  type: types.maybeNull(types.string),
  contentOwnerId: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
})

const ClaimEvent = types.model({
  kind: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  time: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  typeDetails: types.maybeNull(ClaimTypeDetails),
  source: types.maybeNull(Source),
})

export type IClaimEvent = Instance<typeof ClaimEvent>

export const ClaimHistory = types.model({
  kind: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  uploaderChannelId: types.maybeNull(types.string),
  event: types.maybeNull(types.array(ClaimEvent)),
})

export type IClaimHistory = Instance<typeof ClaimHistory>
