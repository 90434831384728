import { Instance, types } from 'mobx-state-tree'
import { YOUTUBE_VIDEO_URL_OR_ID_REGEX } from '../../constants'

export function extractYouTubeVideoId(idOrUrl: string) {
  return YOUTUBE_VIDEO_URL_OR_ID_REGEX.exec(idOrUrl)?.[1]?.trim()
}

export const IsrcEmbed = types.model({
  uuid: types.string,
  youTubeVideoId: types.maybeNull(types.string),
  isrc: types.maybeNull(types.string),

  soundRecordingName: types.maybeNull(types.string),
  artistName: types.maybeNull(types.string),

  createdBy: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export type IIsrcEmbed = Instance<typeof IsrcEmbed>
