import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteUserGroupProps = {
  userGroupUuid: string
}

export type IDeleteUserGroupResponse = IGenericResponse<'deleteUserGroup', boolean>

export async function deleteUserGroup({ userGroupUuid }: IDeleteUserGroupProps): Promise<IDeleteUserGroupResponse> {
  const query = `
    mutation deleteUserGroup($userGroupUuid:String!) {
      deleteUserGroup (
        userGroupUuid: $userGroupUuid
      )
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { userGroupUuid })) as IDeleteUserGroupResponse
}
