import { SERVICE_NAME } from '../../../constants'
import { IChannelVideoBasic } from '../../../models'
import { IPaginationParams } from '../../../types/common'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { channelVideosFragment } from '../fragmentsAssets'

export type IGetChannelVideosResponse = IGenericResponse<
  'channelVideos',
  {
    channelVideos: IChannelVideoBasic[]
    total: number
  }
>

type IGetChannelVideosProps = {
  pagination?: IPaginationParams
  filters?: {
    uuid?: string
    userUuid?: string
    channelId?: string
    siteUuid?: string
    isAssigned?: boolean
    search?: string
  }
}
export async function getChannelVideos(
  { pagination, filters }: IGetChannelVideosProps,
  fragment: string = channelVideosFragment
): Promise<IGetChannelVideosResponse> {
  const query = `
    query channelVideos ($pagination: PaginationInput, $filters: ChannelVideosFiltersInput!) {
      channelVideos (pagination: $pagination, filters: $filters) {
        total
        ${fragment}
        pagination {
           sort
           sortDirection
         }
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IGetChannelVideosResponse
}
