import { Instance, types, flow, cast } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { ILoadLabelsResponse, loadLabels } from '../../api/assets-api/audioRelease/loadLabels'

import { Label } from './Label.model'
import { LABEL_VALIDITY } from '../../constants'

export const LabelList = types
  .model({
    list: types.array(Label),
  })
  .volatile(() => ({
    loading: true,
  }))
  .actions(self => ({
    load: flow(function* (platform: string, successMessage = '') {
      try {
        self.loading = true
        const resp: ILoadLabelsResponse = yield loadLabels(platform)
        if (resp && resp.data.data?.Label_findByPlatformName.length) {
          self.list = cast(resp.data.data.Label_findByPlatformName)
        }
        if (successMessage) {
          toast.success(successMessage)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
    checkLabel(query: string): LABEL_VALIDITY {
      if (!query) {
        return LABEL_VALIDITY.EMPTY
      }
      return self.list.map(label => label.name).includes(query) ? LABEL_VALIDITY.VALID : LABEL_VALIDITY.INVALID
    },
  }))

export type ILabelList = Instance<typeof LabelList>
