import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { ICms } from '../../../models/assets/Cms.model'
import { SERVICE_NAME } from '../../../constants'
import { cmsFragment } from '../fragments'

export type IGetCmsesResponse = IGenericResponse<'cmses', ICms[]>

export async function getCmses(fragment: string = cmsFragment): Promise<IGetCmsesResponse> {
  const query = `
    query cmses {
      cmses {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {})) as IGetCmsesResponse
}
