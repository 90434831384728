import clientWrapper from '../../clientWrapper'

import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'
import { IClaimHistory } from '../../../models/claims/ClaimHistory.model'
import { IClaimMatchInfo } from '../../../models/claims/ClaimMatchInfo.model'
import { IClaimPolicy } from '../../../models/claims/ClaimPolicy.model'
import { IClaimStudioInfo } from '../../../models/claims/ClaimStudioInfo.model'

export type IUpdateClaimHistoryResponse = IGenericResponse<
  'updateClaimHistory',
  {
    status: string
    ugcType: string
    timeStatusLastModified: string
    blockOutsideOwnership: boolean
    isPartnerUploaded: boolean
    history: IClaimHistory[]
    matchInfo: IClaimMatchInfo
    studioInfo: IClaimStudioInfo
    policy: IClaimPolicy
    appliedPolicy: IClaimPolicy
    origin: { source: string }
  }
>

export async function updateClaimHistory(claimId: string): Promise<IUpdateClaimHistoryResponse> {
  const query = `
    mutation updateClaimHistory($claimId: String!) {
        updateClaimHistory(claimId: $claimId) {
            status
            contentType
            ugcType
            timeStatusLastModified
            blockOutsideOwnership
            isPartnerUploaded
            history {
                id
                kind
                uploaderChannelId
                event {
                    kind
                    time
                    type
                    reason
                    typeDetails {
                        appealExplanation
                        disputeNotes
                        disputeReason
                        updateStatus
                    }
                    source {
                        contentOwnerId
                        type
                        userEmail
                    }
                }
            }
            matchInfo {
                referenceId
                totalMatch {
                    referenceDurationSecs
                    userVideoDurationSecs
                }
                longestMatch {
                    durationSecs
                    referenceOffset
                    userVideoOffset
                }
                matchSegments {
                    channel
                    reference_segment {
                        duration
                        kind
                        start
                    }
                    video_segment {
                        duration
                        kind
                        start
                    }
                }
            }
            studioInfo {
                claimUrl
                issueUrl
                videoUrl
            }
            policy {
                id
                kind
                name
                timeUpdated
                rules {
                    action
                    subaction
                    conditions {
                    contentMatchType
                    referenceDuration {
                        low
                        high
                    }
                    matchDuration {
                        low
                        high
                    }
                    referenceDuration {
                        low
                        high
                    }
                    referencePercent {
                        low
                        high
                    }
                    requiredTerritories {
                        territories
                        type
                    }
                    }
                }
            }
            appliedPolicy {
                id
                kind
                name
                timeUpdated
                rules {
                    action
                    subaction
                    conditions {
                    contentMatchType
                    referenceDuration {
                        low
                        high
                    }
                    matchDuration {
                        low
                        high
                    }
                    referenceDuration {
                        low
                        high
                    }
                    referencePercent {
                        low
                        high
                    }
                    requiredTerritories {
                        territories
                        type
                    }
                    }
                }
            }
            origin {
                source
            }
        }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { claimId }) as Promise<IUpdateClaimHistoryResponse>
}
