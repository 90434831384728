import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { IPitch } from '../../../models/songpitch/Pitch.model'
import { IPaginationParams } from '../../../types/common'
import { CHANNEL_PITCH_STATUS } from '../../../constants/songpitch'
import { SERVICE_NAME } from '../../../constants'
import { pitchesAsChannelFragment } from '../fragments'

export type IGetPitchesAsChannelBody = {
  total: number
  pitches: IPitch[]
}

export type IGetPitchesAsChannelProps = {
  pagination?: IPaginationParams
  filters: {
    channelUuid: string
    channelPitchStatuses?: CHANNEL_PITCH_STATUS[]
  }
}

export type IGetPitchesAsChannelResponse = IGenericResponse<'pitchesAsChannel', IGetPitchesAsChannelBody>

export async function getPitchesAsChannel(
  props: IGetPitchesAsChannelProps,
  fragment: string = pitchesAsChannelFragment
): Promise<IGetPitchesAsChannelResponse> {
  const variables = {
    pagination: props.pagination,
    filters: props.filters,
    channelUuid: props.filters.channelUuid,
  }
  const query = `
  query pitchesAsChannel($pagination: PaginationInput,$filters: PitchesAsChannelFiltersInput, $channelUuid:String!) {
    pitchesAsChannel(pagination: $pagination,filters: $filters) {
      total
      pitches {
        ${fragment}
      }
    }
  }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IGetPitchesAsChannelResponse>
}
