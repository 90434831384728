import React from 'react'

import { SvgIconProps } from './iconProps'

const IconSpotify: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0003 0C7.16376 0 0 7.16357 0 16.0001C0 24.837 7.16376 32 16.0003 32C24.8378 32 32.0008 24.837 32.0008 16.0001C32.0008 7.16414 24.8378 0.000764253 16.0001 0.000764253L16.0003 0ZM23.3379 23.0767C23.0513 23.5467 22.4361 23.6958 21.9661 23.4073C18.2094 21.1126 13.4802 20.5929 7.91063 21.8654C7.37393 21.9877 6.83895 21.6514 6.71667 21.1145C6.59382 20.5776 6.92875 20.0426 7.46679 19.9204C13.5617 18.5279 18.7898 19.1274 23.0074 21.7049C23.4774 21.9934 23.6264 22.6067 23.3379 23.0767ZM25.2963 18.7201C24.9352 19.307 24.1671 19.4924 23.5806 19.1313C19.2797 16.4877 12.7237 15.7221 7.63664 17.2663C6.9769 17.4656 6.28009 17.0937 6.07985 16.4351C5.88115 15.7754 6.25315 15.0799 6.91175 14.8793C12.7226 13.1162 19.9465 13.9702 24.8855 17.0053C25.4721 17.3664 25.6574 18.1343 25.2963 18.7201ZM25.4645 14.1835C20.3076 11.1205 11.7996 10.8389 6.87602 12.3332C6.08539 12.573 5.2493 12.1267 5.0097 11.336C4.77011 10.545 5.21605 9.7095 6.00725 9.46914C11.6591 7.75338 21.0547 8.08488 26.9918 11.6094C27.7045 12.0315 27.9376 12.9499 27.5153 13.6601C27.095 14.3713 26.1741 14.6057 25.4652 14.1835H25.4645Z"
      fill="#00DA5A"
    />
  </svg>
)

export default IconSpotify
