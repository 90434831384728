import React from 'react'

import { SvgIconProps } from './iconProps'

const IconPayoneer: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_214_1991)" className="ic-payoneer">
      <g className="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.23 5.27H12.4823C13.8858 5.27327 15.2307 5.8331 16.222 6.82669C17.2133 7.82028 17.77 9.16649 17.77 10.57C17.77 11.973 17.2127 13.3185 16.2206 14.3106C15.3519 15.1793 14.2121 15.7147 13 15.8344V18.73H6.23V5.27ZM8.23 7.27V16.73H11V13.86H12.48C13.3526 13.86 14.1894 13.5134 14.8064 12.8964C15.4234 12.2794 15.77 11.4426 15.77 10.57C15.77 9.69611 15.4234 8.8579 14.8062 8.23925C14.1892 7.62086 13.3522 7.27232 12.4788 7.27H8.23Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.41 2.5C2.90742 2.5 2.5 2.90742 2.5 3.41V20.59C2.5 21.0926 2.90742 21.5 3.41 21.5H20.59C21.0926 21.5 21.5 21.0926 21.5 20.59V3.41C21.5 2.90742 21.0926 2.5 20.59 2.5H3.41ZM0.5 3.41C0.5 1.80285 1.80285 0.5 3.41 0.5H20.59C22.1971 0.5 23.5 1.80285 23.5 3.41V20.59C23.5 22.1971 22.1971 23.5 20.59 23.5H3.41C1.80285 23.5 0.5 22.1971 0.5 20.59V3.41Z"
        />
      </g>
    </g>
    <defs>
      <clipPath className="clip0_214_1991">
        <rect width={24} height={24} fill={fill} />
      </clipPath>
    </defs>
  </svg>
)

export default IconPayoneer
