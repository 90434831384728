import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IAnalytics } from '../../../models/analyticsDistribution/Analytics.model'

const defaultFragment = `
  releaseUuid
  offlinePlays
  streams
  totalPlays
  release
  {
    uuid
    title
  }
`

export interface IGetTopStreamedReleasesProps {
  filters: {
    startDate?: string
    endDate?: string
  }
  limit?: number
}

export type IGetTopStreamedReleasesResponse = IGenericResponse<'topStreamedReleases', IAnalytics[]>

export async function getTopStreamedReleases(
  props: IGetTopStreamedReleasesProps,
  fragment: string = defaultFragment
): Promise<IGetTopStreamedReleasesResponse> {
  const query = `
    query topStreamedReleases($filters: AnalyticsFilters, $limit: Float) {
        topStreamedReleases(filters: $filters, limit: $limit ) {
            ${fragment}
        }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { props }) as Promise<IGetTopStreamedReleasesResponse>
}
