import { Instance, types } from 'mobx-state-tree'
import { DELIVERY_PLATFORM, STORES, STORE_DELIVERY_STATE } from '../../constants'

export const Store = types.model({
  deliveryPlatform: types.maybeNull(types.enumeration(Object.values(DELIVERY_PLATFORM))),
  name: types.enumeration(Object.values(STORES)),
  status: types.maybeNull(types.enumeration(Object.values(STORE_DELIVERY_STATE))),
})

export type IStore = Instance<typeof Store>
