import { useMemo } from 'react'
import { debounce } from 'lodash'
import { Form, InputGroup } from 'react-bootstrap'
import { DRAFT_SAVE_DELAY } from '@epic-front/common'

interface IDebouncedInputProps {
  className?: string
  isDisabled?: boolean
  isValid?: boolean
  isInvalid?: boolean
  minLength?: number
  placeholder: string
  defaultValue: string
  value?: string
  invalidMessage?: string | JSX.Element
  onChange: (value: string) => void
}

const DebouncedInput = ({
  className,
  isDisabled,
  isValid,
  isInvalid,
  minLength,
  placeholder,
  defaultValue,
  value,
  invalidMessage,
  onChange,
}: IDebouncedInputProps): JSX.Element => {
  const debouncedHandler = useMemo(() => debounce(e => onChange(e.target.value), DRAFT_SAVE_DELAY), [])

  return (
    <InputGroup className="mb-0" hasValidation>
      <Form.Control
        type="text"
        className={className}
        isValid={isValid}
        isInvalid={isInvalid}
        disabled={isDisabled}
        minLength={minLength}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={debouncedHandler}
      />
      <Form.Control.Feedback type="invalid" tooltip>
        <Form.Label className="color-danger">{invalidMessage}</Form.Label>
      </Form.Control.Feedback>
    </InputGroup>
  )
}

export default DebouncedInput
