import { cast, destroy, flow, Instance, types } from 'mobx-state-tree'
import { participantsGetAllForCurrentUser } from '../../api/assets-api'

import { IParticipantsGetAllForCurrentUserResponse } from '../../api/assets-api/audioRelease/participantsGetAllForCurrentUser'
import { IParticipant, Participant } from './Participant.model'
import { IParticipantFormData } from './ParticipantBasic'

export const ParticipantList = types
  .model({
    list: types.array(Participant),
  })
  .volatile(() => ({
    loading: true,
  }))
  .views(self => ({
    get uuids() {
      const result = self.list.map(participant => participant.uuid || '')
      return result
    },
    get last() {
      return self.list[self.list.length - 1]
    },
    byUuid(uuid: string | null | undefined): IParticipant | null {
      return self.list.find(part => uuid === part.uuid) || null
    },
  }))

  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IParticipantsGetAllForCurrentUserResponse = yield participantsGetAllForCurrentUser()
        if (resp && resp.data.data?.Participant_getAllForCurrentUser) {
          self.list = cast(resp.data.data.Participant_getAllForCurrentUser)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
    add(info: IParticipantFormData) {
      self.list.push(info)
    },
    remove(part: IParticipant) {
      destroy(part)
    },
  }))

export type IParticipantList = Instance<typeof ParticipantList>
