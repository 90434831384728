import { Instance, types, flow, cast } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { artistSearch } from '../../api/assets-api/audioRelease/artistSearch'
import { IArtistSearchResponse } from '../../api/types'

export const Artist = types.model({
  name: types.string,
  platformName: types.string,
  url: types.string,
  imageUrl: types.maybeNull(types.string),
})

export const ArtistList = types
  .model({
    list: types.array(Artist),
  })
  .named('Artist')
  .actions(self => ({
    searchArtist: flow(function* (searchPhrase: string, successMessage = '') {
      const patch = {
        searchPhrase,
        platforms: ['Spotify'],
      }
      try {
        const resp: IArtistSearchResponse = yield artistSearch(patch)
        if (resp && resp.data.data?.Artist_search.length) {
          self.list = cast(resp.data.data.Artist_search)
          if (successMessage) {
            toast.success(successMessage)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),
  }))

export type IArtistList = Instance<typeof ArtistList>
