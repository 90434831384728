import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'
import { ISpotifyAlbum } from '../../models/assets/SpotifyAlbum.model'
import { SERVICE_NAME } from '../../constants'
import { spotifyAlbumsFragment } from './fragments'

export type ISpotifyGetAlbumsProps = {
  artistAlbumUrl: string
}

export type ISpotifyGetAlbumsResponse = IGenericResponse<'spotifyGetAlbums', ISpotifyAlbum[]>

export async function spotifyGetAlbums(
  variables: ISpotifyGetAlbumsProps,
  fragment: string = spotifyAlbumsFragment
): Promise<ISpotifyGetAlbumsResponse> {
  const query = `
    query spotifyGetAlbums ($artistAlbumUrl: String!) {
      spotifyGetAlbums(artistAlbumUrl: $artistAlbumUrl) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<ISpotifyGetAlbumsResponse>
}
