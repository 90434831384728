import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

export interface IUseDndTableProps {
  isDraggable: boolean
  rowIndex: number
  rowId: string
  onDrop: (rowUuid: string, newPosition: number) => void
}

export interface IUseDndTableResponse {
  dragging: boolean
  dragover: boolean
  draggable: boolean
  rowRef: RefObject<HTMLTableRowElement> | null
  dragHandlerRef: RefObject<HTMLButtonElement> | null
}

export const useDndTable = ({ isDraggable, rowIndex, rowId, onDrop }: IUseDndTableProps): IUseDndTableResponse => {
  const rowRef = useRef<HTMLTableRowElement>(null)
  const dragHandlerRef = useRef<HTMLButtonElement>(null)

  const [dragging, setDragging] = useState(false)
  const [dragover, setDragover] = useState(false)
  const [draggable, setDraggable] = useState(false)

  const handleDragStart = useCallback(
    (e: DragEvent) => {
      e.dataTransfer?.setData('data', String(rowId))

      setTimeout(() => setDragging(true), 0)
      rowRef.current?.addEventListener('dragend', () => setDragging(false))
    },
    [rowIndex]
  )

  const handleDragOver = useCallback(
    (e: DragEvent) => {
      e.preventDefault()

      setDragover(true)
      rowRef.current?.addEventListener('dragleave', () => setDragover(false))
    },
    [rowIndex]
  )

  const handleDrop = useCallback(
    (e: DragEvent) => {
      const draggedElementUuid = e.dataTransfer?.getData('data') as string
      const newPosition = rowIndex

      setDragover(false)
      onDrop(draggedElementUuid, newPosition)
    },
    [rowIndex]
  )

  const handleSetDraggableTrue = () => setDraggable(true)
  const handleSetDraggableFalse = () => setDraggable(false)

  useEffect(() => {
    if (isDraggable) {
      rowRef.current?.addEventListener('dragstart', handleDragStart)
      rowRef.current?.addEventListener('dragover', handleDragOver)
      rowRef.current?.addEventListener('drop', handleDrop)

      dragHandlerRef?.current?.addEventListener('mouseover', handleSetDraggableTrue)
      dragHandlerRef?.current?.addEventListener('mouseleave', handleSetDraggableFalse)
    }

    return () => {
      if (isDraggable) {
        rowRef.current?.removeEventListener('dragstart', handleDragStart)
        rowRef.current?.removeEventListener('dragover', handleDragOver)
        rowRef.current?.removeEventListener('drop', handleDrop)

        dragHandlerRef?.current?.removeEventListener('mouseover', handleSetDraggableTrue)
        dragHandlerRef?.current?.removeEventListener('mouseleave', handleSetDraggableFalse)
      }
    }
  }, [rowIndex])

  return {
    dragging,
    dragover,
    draggable,
    rowRef,
    dragHandlerRef,
  }
}
