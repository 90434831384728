import { cast, flow, Instance, types } from 'mobx-state-tree'

import {
  getDailyOfficialAssetEarnings,
  IGetDailyOfficialAssetEarningsResponse,
} from '../../api/assets-api/assets/getDailyOfficialAssetEarning'
import {} from '../../api/assets-api/getDailyEstimatedEarnings'
import { DailyAssetEarning } from './DailyAssetEarning.model'

export const DailyAssetEarningList = types
  .model({
    list: types.array(DailyAssetEarning),
  })
  .volatile(() => ({
    loading: true,
    assetId: '',
    startDate: '',
    endDate: '',
  }))

  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetDailyOfficialAssetEarningsResponse = yield getDailyOfficialAssetEarnings({
          filters: { startDate: self.startDate, endDate: self.endDate, assetId: self.assetId },
        })

        if (resp && resp.data.data?.dailyOfficialAssetEarnings) {
          self.list = cast(resp.data.data.dailyOfficialAssetEarnings)
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))
  .views(self => ({
    get totalEarnings() {
      return self.list.reduce((acc, el) => acc + el.totalEarning, 0)
    },
  }))
  .actions(self => ({
    setAssetId(assetId: string) {
      self.assetId = assetId
    },
    setStartDate(startDate: string) {
      self.startDate = startDate
    },
    setEndDate(endDate: string) {
      self.endDate = endDate
    },
  }))

export type IDailyAssetEarningList = Instance<typeof DailyAssetEarningList>
