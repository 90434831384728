import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IDeleteReleaseProps {
  uuid: string
}

export type IDeleteReleaseResponse = IGenericResponse<'deleteRelease', boolean>

export async function deleteRelease(props: IDeleteReleaseProps): Promise<IDeleteReleaseResponse> {
  const query = `
    mutation deleteRelease ($uuid:String!) {
      deleteRelease (uuid: $uuid)  
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IDeleteReleaseResponse>
}
