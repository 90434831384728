import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IToggleIsEarlyProps = {
  userUuid: string
}

export type IToggleIsEarlyResponse = IGenericResponse<'toggleIsEarly', boolean>

export async function toggleIsEarly({ userUuid }: IToggleIsEarlyProps): Promise<IToggleIsEarlyResponse> {
  const query = `
  mutation toggleIsEarly($userUuid: String!) {
    toggleIsEarly(
        userUuid: $userUuid
      )
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid })) as IToggleIsEarlyResponse
}
