import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IDeliverThroughDDEXAudioReleaseProps {
  releaseUuid: string
}

export type IDeliverThroughDDEXAudioReleaseResponse = IGenericResponse<'deliverThroughDdex', boolean>

export async function deliverThroughDdex(
  props: IDeliverThroughDDEXAudioReleaseProps
): Promise<IDeliverThroughDDEXAudioReleaseResponse> {
  const query = `
    mutation deliverThroughDdex ($releaseUuid: String! ) {
       deliverThroughDdex(releaseUuid: $releaseUuid)
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { ...props }) as Promise<IDeliverThroughDDEXAudioReleaseResponse>
}
