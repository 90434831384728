import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { IDailyAssetEarning } from '../../../models/assets/DailyAssetEarning.model'
import { SERVICE_NAME } from '../../../constants'
import { dailyOfficialAssetEarningsFragment } from '../fragments'

export type IGetDailyOfficialAssetEarningsProps = {
  filters: {
    assetId: string
    endDate: string
    startDate: string
  }
}

export type IGetDailyOfficialAssetEarningsResponse = IGenericResponse<
  'dailyOfficialAssetEarnings',
  IDailyAssetEarning[]
>

export async function getDailyOfficialAssetEarnings(
  { filters }: IGetDailyOfficialAssetEarningsProps,
  fragment: string = dailyOfficialAssetEarningsFragment
): Promise<IGetDailyOfficialAssetEarningsResponse> {
  const query = `
    query dailyOfficialAssetEarnings ($filters: DailyOfficialAssetEarningFilterInput!) {
      dailyOfficialAssetEarnings (filters: $filters) {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { filters })) as IGetDailyOfficialAssetEarningsResponse
}
