import { Instance, flow } from 'mobx-state-tree'
import moment from 'moment'
import { toast } from 'react-toastify'
import { ICreateAffiliateResponse, createAffiliate } from '../../api/assets-api/affiliates/createAffiliate'
import { IEditAffiliateResponse, editAffiliate } from '../../api/assets-api/affiliates/editAffiliate'
import { AFFILIATE_TYPE } from '../../constants'
import { AffiliateBasic } from './AffiliateBasic.model'

export const Affiliate = AffiliateBasic.volatile(() => ({
  loading: false,
}))
  .views(self => ({
    get hasExpired() {
      if (self.lastAffiliationMonth) {
        return moment().startOf('month').diff(moment(self.lastAffiliationMonth)) > 0
      }

      return false
    },
  }))

  .actions(self => ({
    setType(type: AFFILIATE_TYPE) {
      self.type = type
    },
    setEntityUuid(val: string) {
      self.entityUuid = val
    },
    setPercentage(val: number) {
      self.percentage = val
    },
    setSiteUuid(val: string) {
      self.siteUuid = val
    },
    setAffiliateUserUuid(val: string) {
      self.affiliateUserUuid = val
    },
    setLastAffiliationMonth(month: string | null) {
      self.lastAffiliationMonth = month
    },
    createOrUpdate: flow(function* () {
      try {
        if (self.type && self.siteUuid && self.percentage && self.affiliateUserUuid && self.entityUuid) {
          self.loading = true
          if (self.uuid) {
            const resp: IEditAffiliateResponse = yield editAffiliate({
              type: self.type,
              siteUuid: self.siteUuid,
              percentage: self.percentage,
              affiliateUserUuid: self.affiliateUserUuid,
              entityUuid: self.entityUuid,
              lastAffiliationMonth: self.lastAffiliationMonth,
              uuid: self.uuid,
            })

            if (resp && resp.data.data?.editAffiliate) {
              toast.success('Affiliate successfully edited!')
            }
          }

          if (!self.uuid) {
            const resp: ICreateAffiliateResponse = yield createAffiliate({
              type: self.type,
              siteUuid: self.siteUuid,
              percentage: self.percentage,
              affiliateUserUuid: self.affiliateUserUuid,
              lastAffiliationMonth: self.lastAffiliationMonth,
              entityUuid: self.entityUuid,
            })

            if (resp && resp.data.data?.createAffiliate) {
              self.uuid = resp.data.data?.createAffiliate.uuid
              toast.success('Affiliate successfully created!')
            }
          }
          self.loading = false
        }
      } catch (err) {
        self.loading = false

        console.error(err)
      }
    }),
  }))

export type IAffiliate = Instance<typeof Affiliate>
