import { applySnapshot, flow, Instance } from 'mobx-state-tree'
import { imageDelete } from '../../api/assets-api'
import { getImageUploadUrl, IGetImageUploadUrlResponse } from '../../api/assets-api/getImageUploadUrl'
import { IImageDeleteResponse } from '../../api/types'
import { IMAGE_TYPE } from '../../constants'
import { ImageBasic } from './ImageBasic.model'

export interface IImageFormData {
  uuid?: string
  releaseUuid?: string
  storageUrl?: string
  type?: IMAGE_TYPE
}

export const Image = ImageBasic.volatile(() => ({
  uploading: false,
}))
  .actions(self => ({
    setUploading(status: boolean) {
      self.uploading = status
    },
    setUuid(uuid: string) {
      self.uuid = uuid
    },
    setDownloadUrl(url: string) {
      self.downloadUrl = url
    },
    setReleaseUuid(uuid: string) {
      self.releaseUuid = uuid
    },
  }))
  .actions(self => ({
    delete: flow(function* () {
      try {
        if (self.uuid) {
          const resp: IImageDeleteResponse = yield imageDelete(self.uuid)
          if (resp && resp.data.data?.Image_delete) {
            applySnapshot(self, {})
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),
    getImageUploadUrl: flow(function* (imageFileName: string, assetUuid?: string) {
      let toUploadAssetUuid = self.releaseUuid

      if (assetUuid) {
        toUploadAssetUuid = assetUuid
      }

      try {
        if (toUploadAssetUuid) {
          const resp: IGetImageUploadUrlResponse = yield getImageUploadUrl({
            imageFileName,
            releaseUuid: toUploadAssetUuid,
            imageType: self.type || undefined,
          })

          if (resp && resp.data.data?.getImageUploadUrl.uploadUrl) {
            self.downloadUrl = resp.data.data?.getImageUploadUrl.image.downloadUrl
            self.uploadUrl = resp.data.data.getImageUploadUrl.uploadUrl
            self.uuid = resp.data.data.getImageUploadUrl.image.uuid
            self.type = resp.data.data.getImageUploadUrl.image.type
            self.fileName = resp.data.data.getImageUploadUrl.image.fileName
            return self.uploadUrl
          }
        }

        return null
      } catch (err) {
        console.error(err)
        return null
      }
    }),
  }))

export type IImage = Instance<typeof Image>
