import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { WizardContext } from './Wizard'

type IWizardStep = {
  component: ({ next, previous, section }: { next: () => void; previous: () => void; section: number }) => JSX.Element
}

const WizardStep = (props: IWizardStep): JSX.Element => {
  const { next, previous, section } = useContext(WizardContext)

  return (
    <>
      <div>{props.component({ next, previous, section })}</div>
    </>
  )
}

export default observer(WizardStep)
