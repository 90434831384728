import { useState, useEffect } from 'react'
import { PERMISSIONS } from 'e2e-utils'

import { useStore } from './useStore'

interface IUseIsAssetAdminReturn {
  isAdmin: boolean
}

export const useAssetAdmin = (): IUseIsAssetAdminReturn => {
  const { auth } = useStore()
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  useEffect(() => {
    setIsAdmin(auth.hasPermission(PERMISSIONS.ASSET.MANAGE_ALL))
  }, [auth])

  return { isAdmin }
}
