import { cast, flow, Instance, types } from 'mobx-state-tree'

import { getIsrcEmbeds } from '../../api/assets-api/isrcEmbed/getIsrcEmbeds'
import { Pagination } from '../general/Pagination.model'
import { IIsrcEmbed, IsrcEmbed } from './IsrcEmbed.model'

export const IsrcEmbedList = types
  .model({
    list: types.array(IsrcEmbed),
  })
  .volatile(() => ({
    pagination: Pagination.create({ totalItems: 1 }),
    loading: false,
  }))

  .actions(self => ({
    setIsrcEmbeds(list: IIsrcEmbed[]) {
      try {
        self.list = cast(list)
      } catch (e) {
        console.error('ERROR in setIsrcEmbeds cast', e)
      }
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {},
        }

        const resp = yield getIsrcEmbeds(variables)
        if (resp && resp.data.data) {
          self.setIsrcEmbeds(resp.data.data.isrcEmbeds.isrcEmbeds)
          self.pagination.setTotalItems(resp.data.data.isrcEmbeds.total)
        }

        self.loading = false
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IIsrcEmbedList = Instance<typeof IsrcEmbedList>
