import { Instance, cast, destroy, flow, types } from 'mobx-state-tree'
import { createLabel, deleteLabel, getLabels } from '../../api/users-api/labels'
import { ILabel, Label } from './Label.model'

export const LabelList = types
  .model({
    list: types.array(Label),
  })
  .volatile(() => ({
    loading: false,
  }))
  .views(self => ({
    byName(labelName: string) {
      return self.list.find(label => label.name === labelName)
    },
    byLabelUuid(labelUuid: string) {
      return self.list.find(label => label.uuid === labelUuid)
    },
  }))
  .actions(self => ({
    sortLabels() {
      self.list = self.list.sort((a: ILabel, b: ILabel) => {
        if (a.name !== null && b.name !== null) {
          return a.name > b.name ? 1 : -1
        }
        return 0
      })
    },
  }))
  .actions(self => ({
    setLabels(labels: ILabel[]) {
      self.list = cast(labels)
      self.sortLabels()
    },
    addLabel(label: ILabel) {
      self.list.push(cast(label))
      self.sortLabels()
    },
    remove(labelUuid: string) {
      destroy(self.byLabelUuid(labelUuid))
    },
  }))

  .actions(self => ({
    createLabel: async (name: string) => {
      try {
        const resp = await createLabel({ name })
        if (resp && resp.data.data) {
          self.addLabel(resp.data.data.createLabel)
          return resp.data.data?.createLabel.uuid
        }
      } catch (err) {
        console.error(err)
      }
      return ''
    },
    deleteLabel: async (labelUuid: string) => {
      try {
        const resp = await deleteLabel({ labelUuid })
        if (resp && resp.data.data?.deleteLabel) {
          self.remove(labelUuid)
        }
      } catch (err) {
        console.error(err)
      }
      return ''
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp = yield getLabels()

        self.loading = true
        if (resp && resp.data.data) {
          self.setLabels(resp.data.data.labels)
          self.sortLabels()
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))

export type ILabelList = Instance<typeof LabelList>
