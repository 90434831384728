import { Instance, types } from 'mobx-state-tree'
import { DELIVERY_PLATFORM, PLATFORM_STATES } from '../../constants'

export const DeliveryPlatform = types.model({
  name: types.enumeration(Object.values(DELIVERY_PLATFORM)),
  state: types.enumeration(Object.values(PLATFORM_STATES)),
  assetIdOnPlatform: types.maybeNull(types.string),
  errorMessage: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  xmlPath: types.maybeNull(types.string),
  xmlPathArchive: types.maybeNull(types.array(types.string)),
})

export type IDeliveryPlatform = Instance<typeof DeliveryPlatform>
