import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { IPitch } from '../../../models/songpitch/Pitch.model'
import { IPaginationParams } from '../../../types/common'
import { PITCH_STATUS } from '../../../constants/songpitch'
import { SERVICE_NAME } from '../../../constants'
import { pitchesAsUserFragment } from '../fragments'

export type IGetPitchesAsUserBody = {
  total: number
  pitches: IPitch[]
}

export type IGetPitchesAsUserProps = {
  pagination?: IPaginationParams
  filters?: {
    assetUuid?: string
    userUuid?: string
    genreUuid?: string
    channelUuid?: string
    statuses?: PITCH_STATUS[]
  }
}

export type IGetPitchesAsUserResponse = IGenericResponse<'pitchesAsUser', IGetPitchesAsUserBody>

export async function getPitchesAsUser(
  variables: IGetPitchesAsUserProps,
  fragment: string = pitchesAsUserFragment
): Promise<IGetPitchesAsUserResponse> {
  const query = `
  query pitchesAsUser($pagination: PaginationInput,$filters: PitchesFiltersInput) {
    pitchesAsUser(pagination: $pagination,filters: $filters) {
      total
      pitches {
        ${fragment}
      } 
    }
  }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IGetPitchesAsUserResponse>
}
