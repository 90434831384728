import { SERVICE_NAME } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IDeleteClaimManagerResponse = IGenericResponse<'deleteClaimManager', boolean>

export type IDeleteClaimManagerProps = {
  uuid: string
}

export async function deleteClaimManager({ uuid }: IDeleteClaimManagerProps): Promise<IDeleteClaimManagerResponse> {
  const mutation = `
    mutation DeleteClaimManager($uuid: String!) {
        deleteClaimManager(uuid: $uuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, mutation, { uuid })) as IDeleteClaimManagerResponse
}
