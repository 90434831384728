import { Instance, types } from 'mobx-state-tree'
import { Asset } from '../assets'
import { Channel } from '../assets/Channel.model'

export const PitchBasic = types.model({
  uuid: types.maybeNull(types.string),
  assetUuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  status: types.maybeNull(types.string), // update to enum
  pitcherUuid: types.maybeNull(types.string),
  genres: types.array(types.string),
  toChannels: types.array(Channel),
  revSplitType: types.maybeNull(types.string), // update to enum
  revenueSplitPercentage: types.maybeNull(types.number),
  asset: types.maybeNull(Asset),
  videosAsUser: types.maybeNull(types.number), // nr of videos
  videosAsChannel: types.maybeNull(types.number), // nr of videos
})

export type IPitchBasic = Instance<typeof PitchBasic>
