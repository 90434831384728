import { Instance, types } from 'mobx-state-tree'

import { Affiliate } from './Affiliate.model'
import { AssetUser } from './AssetUser.model'
import { Genre } from './Genre.model'
import { RevenueShare } from './RevenueShare.model'

export const ChannelBasic = types.model({
  uuid: types.string,
  channelId: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  siteUuid: types.maybeNull(types.string),
  userUuid: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  publishDate: types.maybeNull(types.string),
  linkedAt: types.maybeNull(types.string),
  unlinkedAt: types.maybeNull(types.string),
  assignedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  latestUploadDate: types.maybeNull(types.string),
  totalView: types.maybeNull(types.number),
  totalVideo: types.maybeNull(types.number),
  totalSubscriber: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  clientName: types.maybeNull(types.string),
  affiliates: types.maybeNull(types.array(Affiliate)),
  revenueShare: types.maybeNull(RevenueShare),
  genres: types.maybeNull(types.array(Genre)),
  genreUuids: types.array(types.string),
  users: types.array(AssetUser),
  onSongpitch: types.maybeNull(types.boolean),
})

export type IChannelBasic = Instance<typeof ChannelBasic>
