import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { IPaginationParams } from '../../../types/common'
import { SERVICE_NAME } from '../../../constants'
import { ILicense } from '../../../models/licenses/License.model'

export type IGetLicensesResponseBody = {
  total: number
  assetLicenses: ILicense[]
}

export type IGetLicensesProps = {
  pagination?: IPaginationParams
  filters?: {
    code?: string
    type?: string
    userUuid?: string
    uuid?: string
    channelId?: string
  }
}

export type IGetLicensesResponse = IGenericResponse<'assetLicenses', IGetLicensesResponseBody>

export async function getLicenses(variables: IGetLicensesProps): Promise<IGetLicensesResponse> {
  const query = `
    query getLicenses ($pagination: PaginationInput, $filters: assetLicenseFilters) {
      assetLicenses(pagination: $pagination, filters: $filters) {
        total
        assetLicenses {
            uuid 
            userUuid
            limitUses  
            type 
            code
            channelId
            channelName
        }
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IGetLicensesResponse>
}
