import { Instance, types } from 'mobx-state-tree'

export const AdminShift = types.model({
  uuid: types.identifier,
  adminUuid: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
})

export type IAdminShift = Instance<typeof AdminShift>
