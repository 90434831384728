import { MICROLICENSING_AUDIO_TYPE, MICROLICENSING_KEY, MICROLICENSING_MOODS, SERVICE_NAME } from '../../../constants'
import { IReleaseBasic } from '../../../models'
import { IMicrolicensingPropsConfigFile } from '../../../models/assets/MicrolicensingPropsConfigFile.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IEditReleaseMetadataProps {
  releaseUuid: string
  metadata: {
    moods?: MICROLICENSING_MOODS[] | null
    genres?: string[] | null
    audioType?: MICROLICENSING_AUDIO_TYPE | null
    bpm?: number | null
    key?: MICROLICENSING_KEY | null
    shortDescription?: string | null
    description?: string | null
    files: Pick<IMicrolicensingPropsConfigFile, 'filePath' | 'extension'>[] | null
  }
}

export type IEditReleaseMetadataResponse = IGenericResponse<'editReleaseMetadata', IReleaseBasic>

export async function editReleaseMetadata({
  releaseUuid,
  metadata,
}: IEditReleaseMetadataProps): Promise<IEditReleaseMetadataResponse> {
  const query = `
    mutation EditReleaseMetadata($metadata: MicrolicensingPropsInput!, $releaseUuid: String!) {
      editReleaseMetadata(metadata: $metadata, releaseUuid: $releaseUuid) {
        uuid
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, { releaseUuid, metadata }) as Promise<IEditReleaseMetadataResponse>
}
