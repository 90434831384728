export enum W9_FEDERAL_CLASSIFICATION_TYPE {
  INDIVIDUAL = 'individual',
  PARTNERSHIP = 'partnership',
  SCORPORATION = 'sCorporation',
  CCORPORATION = 'cCorporation',
  TRUST = 'trust',
  LLC_C = 'limitedLiabilityCompanyC',
  LLC_P = 'limitedLiabilityCompanyP',
  LLC_S = 'limitedLiabilityCompanyS',
}

export enum W8_BENE_ENTITY_TYPE {
  CENTRAL_BANK_OF_ISSUE = 'centralBankOfIssue',
  COMPLEX_TRUST = 'complexTrust',
  CORPORATION = 'corporation',
  DISREGARDED_ENTITY = 'disregardedEntity',
  ESTATE = 'estate',
  FOREIGN_GOVERNMENT_CONTROLLED_ENTITY = 'foreignGovernmentControlledEntity',
  FOREIGN_GOVERNMENT_INTEGRAL_PART = 'foreignGovernmentIntegralPart',
  GRANTOR_TRUST = 'grantorTrust',
  INTERNATIONAL_ORGANIZATION = 'internationalOrganization',
  PARTNERSHIP = 'partnership',
  PRIVATE_FOUNDATION = 'privateFoundation',
  SIMPLE_TRUST = 'simpleTrust',
  TAX_EXEMPT_ORGANIZATION = 'taxExemptOrganization',
}

export const STATUS_ENTITY_TYPES = [
  {
    name: 'Simple trust',
    value: W8_BENE_ENTITY_TYPE.SIMPLE_TRUST,
  },
  {
    name: 'Central Bank of Issue',
    value: W8_BENE_ENTITY_TYPE.CENTRAL_BANK_OF_ISSUE,
  },

  {
    name: 'Grantor trust',
    value: W8_BENE_ENTITY_TYPE.GRANTOR_TRUST,
  },
  {
    name: 'Tax-exempt organization',
    value: W8_BENE_ENTITY_TYPE.TAX_EXEMPT_ORGANIZATION,
  },
  {
    name: 'Private foundation',
    value: W8_BENE_ENTITY_TYPE.PRIVATE_FOUNDATION,
  },
  {
    name: 'Disregarded entity',
    value: W8_BENE_ENTITY_TYPE.DISREGARDED_ENTITY,
  },
  {
    name: 'Corporation',
    value: W8_BENE_ENTITY_TYPE.CORPORATION,
  },
  {
    name: 'Complex trust',
    value: W8_BENE_ENTITY_TYPE.COMPLEX_TRUST,
  },
  {
    name: 'Estate',
    value: W8_BENE_ENTITY_TYPE.ESTATE,
  },
  {
    name: 'International organization',
    value: W8_BENE_ENTITY_TYPE.INTERNATIONAL_ORGANIZATION,
  },
  {
    name: 'Partnership',
    value: W8_BENE_ENTITY_TYPE.PARTNERSHIP,
  },
  {
    name: 'Foreign Government - Controlled Entity',
    value: W8_BENE_ENTITY_TYPE.FOREIGN_GOVERNMENT_CONTROLLED_ENTITY,
  },
  {
    name: 'Foreign Government - Integral Part',
    value: W8_BENE_ENTITY_TYPE.FOREIGN_GOVERNMENT_INTEGRAL_PART,
  },
]
