import { Instance, cast, destroy, getSnapshot, types } from 'mobx-state-tree'
import { capitalizeFirstLetter } from '../../helpers'
import { IPermission, Permission } from './Permission.model'

export const Role = types
  .model({
    uuid: types.identifier,
    name: types.string,
    description: types.string,
    isInternal: types.maybeNull(types.boolean),
    usersInRole: types.maybeNull(types.number),
    permissions: types.array(Permission),
  })
  .views(self => ({
    get displayName() {
      return capitalizeFirstLetter(self.name.trim())
    },
    hasPermission(name: string): boolean {
      const has = self.permissions.map(perm => perm.name).find(permName => permName === name)
      return !!has
    },
  }))
  .actions(self => ({
    addPermission(newPermission: IPermission) {
      self.permissions.push(cast(getSnapshot(newPermission)))
    },
    removePermission(name: string) {
      const myPerm = self.permissions.find(perm => perm.name === name)
      destroy(myPerm)
    },
  }))

export type IRole = Instance<typeof Role>
