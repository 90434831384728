import { SERVICE_NAME } from '../../../constants'
import { IUserGroup } from '../../../models/users/UserGroup.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IGetUserGroupsResponse = IGenericResponse<'userGroups', IUserGroup[]>

export async function getUserGroups(): Promise<IGetUserGroupsResponse> {
  const query = `
    query getUserGroups {
      userGroups {
        name
        uuid
        type
      }
  }
  `

  return clientWrapper(SERVICE_NAME.USERS, query) as Promise<IGetUserGroupsResponse>
}
