import { PARTICIPANT_ROLES, SERVICE_NAME } from '../../../constants'
import { IAssetBasic } from '../../../models'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { assetFullLineFragment } from '../fragmentsAssets'

export interface ICreateUpdateAssetProps {
  continue?: boolean
  uuid?: string
  title?: string
  assetType?: string
  userUuid?: string | null
  genreUuids?: string[]
  participantUuidsWithRoles?: { participantUuid: string; role: PARTICIPANT_ROLES }[]
  cInfo?: string | null
  cYear?: number
  pInfo?: string | null
  pYear?: number
  rightsHolders?: string
  label?: string | null
  upc?: string | null
  fileName?: string | null
  fileSizeInBytes?: number | null
  bpm?: number | null
  uploadFinished?: boolean
  titleVersion?: string | null
  releaseFormat?: string | null
  recordingLocation?: string | null
  releaseDate?: string | null
  audioLanguage?: string | null
  metadataLanguage?: string | null
  displayImagePath?: string | null
  advisory?: string | null
  generalInfoStepPassed?: boolean
  trackInfoStepPassed?: boolean
  targetPlatformStepPassed?: boolean
  description?: string | null
  releaseUuid?: string | null
  assetId?: string | null
  customId?: string | null
  isrc?: string | null
  iswc?: string | null
  artist?: string | null
  album?: string | null
  genre?: string | null
  originalReleaseDate?: string | null
  preorderDate?: string | null
  isCover?: boolean | null
  previewStart?: number | null
  stores?: string[] | null
  srCmsDestination?: string | null
  trackOrder?: string[]
  forceAssignIsrc?: boolean
}

export type ICreateUpdateAssetResponse = IGenericResponse<'createOrUpdateAsset', IAssetBasic>

export async function createOrUpdateAsset(
  props: ICreateUpdateAssetProps,
  fragment: string = assetFullLineFragment
): Promise<ICreateUpdateAssetResponse> {
  const variables = {
    patch: props,
  }
  const query = `
    mutation createOrUpdateAsset($patch: AssetInput!) {
      createOrUpdateAsset (
        patch: $patch
      ) {
        ${fragment} 
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<ICreateUpdateAssetResponse>
}
