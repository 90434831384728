import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { SERVICE_NAME } from '../../../constants'

export type ILoadLabelsResponse = IGenericResponse<
  'Label_findByPlatformName',
  {
    name: string
    uuid: string
  }[]
>

export type ILoadLabelsProps = {
  platformName?: string
}

export async function loadLabels(platform: string): Promise<ILoadLabelsResponse> {
  const variables = { platform }
  const query = `
    query loadLabels ($platform:  DeliveryService!) {
      Label_findByPlatformName (platformName: $platform) {
        uuid
        name
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, variables)) as ILoadLabelsResponse
}
