import axios, { AxiosProgressEvent } from 'axios'
import { applySnapshot, cast, destroy, detach, flow, getSnapshot, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { getUploadUrlForDDEX, imageDelete } from '../../../api/assets-api'

import { createOrUpdateTrack, ICreateUpdateTrackResponse } from '../../../api/assets-api/assets/createOrUpdateTrack'
import { deleteTrack, IDeleteTrackResponse } from '../../../api/assets-api/assets/deleteTrack'
import { IEditMetadataResponse } from '../../../api/assets-api/assets/editMetadata'
import { IUploadToShopifyResponse, uploadToShopify } from '../../../api/assets-api/assets/uploadToShopify'
import { findArtTrackByUuid, IFindArtTrackByUuidResponse } from '../../../api/assets-api/findArtTrackByUuid'
import { artTrackFragmentWithValidationErrors } from '../../../api/assets-api/fragmentsAssets'
import {
  getAssetLifetimeEarnings,
  IGetAssetLifetimeEarningsResponse,
} from '../../../api/assets-api/getAssetLifetimeEarnings'
import { IGetUploadUrlObjectResponse } from '../../../api/assets-api/getUploadUrl'
import { IGetUploadUrlForDDEXResponse, IImageDeleteResponse } from '../../../api/types'
import { DDEXUpload, IMAGE_TYPE, PARTICIPANT_ROLES, TASFileStructContent, UPLOAD_FILE_STATUS } from '../../../constants'
import { getUploadDetails } from '../../../helpers/assets'
import { IAssetFormData } from '../../../types/common'
import { IImage } from '../../general/Image.model'
import { IAssetUser } from '../AssetUser.model'
import { IMicrolicensingPropsConfig } from '../MicrolicensingPropsConfig.model'
import { IParticipantBasic } from '../ParticipantBasic'
import { ArtTrackBasic } from './ArtTrackBasic.model'
import { editTrackMetadata, IEditTrackMetadataProps } from '../../../api/assets-api/assets/editTrackMetadata'

export const ArtTrack = ArtTrackBasic.volatile(() => ({
  loading: false,
  loadingPatchUpdate: false,
  uploadingFile: false,
  loadingPlatformUpdate: false,
  loadingParticipant: false,
  loadingEditMetadata: false,
  fileUploadProgress: null as number | null,
  file: null as TASFileStructContent | null,
  deliveredDdex: false,
  totalRevenue: null as number | null,
  lifetimeEarnings: null as number | null,
  distributionLinks: [] as string[],
}))
  .views(self => ({
    get mainGenre() {
      if (self.genreUuids && self.genreUuids.length > 0) {
        return self.genreUuids[0]
      }
      return null
    },
    get secondaryGenre() {
      if (self.genreUuids && self.genreUuids.length > 1) {
        return self.genreUuids[1]
      }
      return null
    },
    get filterReference() {
      return `${self.uuid} ${self.title} ${self.artist} ${self.album} ${self.isrc} ${self.rightsHolders} ${self.fileName}`.toLowerCase()
    },

    get imageUuids() {
      return self.images?.map(img => img.uuid)
    },

    getParticipantUuidAndRoles() {
      const list: { participantUuid: string; role: PARTICIPANT_ROLES }[] = []
      self.participants?.forEach(p => {
        if (p.uuid && p.role) {
          list.push({ participantUuid: p.uuid, role: p.role })
        }
      })
      return list
    },

    get otherImage() {
      return self.images?.find(img => img.type === IMAGE_TYPE.OTHER)
    },

    get artistImage() {
      return self.images?.find(img => img.type === IMAGE_TYPE.ARTIST)
    },

    getFrontImage() {
      return self.images?.find(img => img.type === IMAGE_TYPE.FRONT)
    },
    getImageByUuid(uuid: string) {
      return self.images?.find(img => {
        return img.uuid === uuid
      })
    },

    trackIsValid(): boolean {
      return !!self.validationErrors && self.validationErrors.length === 0
    },

    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid
    },

    get fileUploadStatus() {
      if (self.uploadFinished) {
        return UPLOAD_FILE_STATUS.UPLOADED
      }

      if (self.fileName && self.fileUploadProgress === 100) {
        return UPLOAD_FILE_STATUS.UPLOADED
      }

      if (self.uploadingFile || self.loading) {
        return UPLOAD_FILE_STATUS.UPLOADING
      }

      return UPLOAD_FILE_STATUS.MISSING
    },
  }))

  .actions(self => ({
    updateAssetWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },
    applyChanges: (info: IAssetFormData) => {
      const selfCopy = { ...getSnapshot(self) }

      applySnapshot(self, {
        ...selfCopy,
        ...{ ...info, stores: undefined },
      })
    },

    applyMicrolicensingChanges: (microlicensingPropsConfigInput: Partial<IMicrolicensingPropsConfig>) => {
      const microlicensingPropsCopy = { ...self.microlicensingProps }

      const microlicensingProps = {
        ...microlicensingPropsCopy,
        ...microlicensingPropsConfigInput,
      }

      applySnapshot(self, {
        ...self,
        microlicensingProps,
      })
    },

    removeSplit: (splitUuid: string) => {
      const removedSplitIndex = self.users.findIndex(assetUser => assetUser.revSplitUuid === splitUuid)
      const removeSplit = self.users.splice(removedSplitIndex, 1)[0]

      const ownerIndex = self.users.findIndex(assetUser => !assetUser.revSplitUuid)
      self.users[ownerIndex].percentage = (self.users[ownerIndex].percentage || 100) + (removeSplit.percentage || 0)
    },

    // // move progress here ?!
    // addImage(info: IImageFormData) {
    //   // ! not ok
    //   self.images?.push(info)
    //   const current = self.images?.[self.images.length - 1]
    //   current?.createUpdate(info, 'Image created')
    // },
    // add image locally
    addImageLocally(image: IImage) {
      self.images?.push(image)
    },
    removeImage: flow(function* (imgUuid: string) {
      try {
        const resp: IImageDeleteResponse = yield imageDelete(imgUuid)
        if (resp && resp.data.data?.Image_delete) {
          // WE CREATE AN OBJECT TO PASS IT AND DESTROY IT HERE MAYBE WE SHOULD FIND IT AND DESTROY IT HERE
          const img = self.getImageByUuid(imgUuid)
          destroy(img)
        }
      } catch (err) {
        console.error(err)
      }
    }),

    setUuid(uuid: string) {
      self.uuid = uuid
    },
    setMainGenre(newValue: string | null) {
      if (newValue && self.genreUuids) self.genreUuids[0] = newValue ?? undefined
    },
    setSecondaryGenre(newValue: string | null) {
      if (newValue && self.genreUuids) self.genreUuids[1] = newValue ?? undefined
    },

    setTotalRevenue(revenue: number) {
      self.totalRevenue = revenue
    },

    setForceAssignIsrc(check: boolean) {
      self.forceAssignIsrc = check
    },

    setFileUploadProgress(progress: number | null) {
      self.fileUploadProgress = progress
    },
    changeAssetFileUrl: (newUrl: string) => {
      self.storagePath = newUrl
    },
    changeAssetFileName: (newFileName: string) => {
      self.fileName = newFileName
    },
    changeAssetFileSize: (newSize: number) => {
      self.fileSizeInBytes = newSize
    },

    getUploadUrlForDDEX: flow(function* (releaseUuid: string, uploadData: DDEXUpload) {
      const variables = {
        releaseUuid,
        userUuid: uploadData.userUuid,
        csvFileName: uploadData.csv.name,
        wavFileNames: uploadData.wavs.map(wav => wav.name),
        jpegFileNames: uploadData.jpegs.map(jpeg => jpeg.name),
      }

      try {
        const resp: IGetUploadUrlForDDEXResponse = yield getUploadUrlForDDEX(variables)
        if (resp && resp.data.data?.getUploadUrlForDDEX) {
          return resp.data.data.getUploadUrlForDDEX
        }
      } catch (err) {
        console.error(err)
      }

      return ''
    }),
    setDownloadUrl(newUrl: string | null) {
      self.downloadUrl = newUrl
    },

    prepareRequest(formData: IAssetFormData) {
      const reqBody = { ...formData }

      if (reqBody.releaseFormat === null) {
        delete reqBody.releaseFormat
      }

      const patch = {
        ...reqBody,
      }

      if (reqBody.releaseDate === '') {
        patch.releaseDate = undefined
      }
      if (reqBody.originalReleaseDate === '') {
        patch.originalReleaseDate = undefined
      }
      if (reqBody.preorderDate === '') {
        patch.preorderDate = undefined
      }

      delete patch.participantsPresent
      delete patch.albumArtPresent
      return patch
    },
  }))
  .actions(self => ({
    load: flow(function* (fragment?: string) {
      try {
        self.loading = true
        if (self.uuid) {
          const resp: IFindArtTrackByUuidResponse = yield findArtTrackByUuid(self.uuid, fragment)
          if (resp && resp.data.data?.findTrackByUuid) {
            const assetResp = resp.data.data?.findTrackByUuid
            const preparedAsset = { ...assetResp }
            preparedAsset.participants = cast(assetResp?.participants || [])

            if (self.participants) {
              detach(self.participants)
            }
            if (self.images) {
              detach(self.images)
            }
            applySnapshot(self, preparedAsset)
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
    loadLifetimeEarnings: flow(function* () {
      try {
        self.loadingPatchUpdate = true
        if (self.uuid) {
          const resp: IGetAssetLifetimeEarningsResponse = yield getAssetLifetimeEarnings(self.uuid)
          if (resp && resp.data.data?.assetLifetimeEarnings) {
            self.lifetimeEarnings = resp.data.data?.assetLifetimeEarnings
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingPatchUpdate = false
      }
    }),
    loadParticipants: flow(function* () {
      try {
        if (self.uuid) {
          const resp = yield findArtTrackByUuid(self.uuid)
          if (resp && resp.data.data.findAssetByUuid) {
            if (self.participants) {
              detach(self.participants)
            }
            self.participants = cast(resp.data.data.findAssetByUuid.participants)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),

    delete: flow(function* () {
      try {
        if (self.uuid) {
          const resp: IDeleteTrackResponse = yield deleteTrack({ uuid: self.uuid })
          if (resp && resp.data.data?.deleteTrack) {
            toast.success(`Asset deleted.`)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),

    createUpdate: flow(function* ({
      info,
      successMessage = '',
      fragment = artTrackFragmentWithValidationErrors,
    }: {
      info: IAssetFormData
      successMessage?: string
      fragment?: string
    }) {
      try {
        self.loadingPatchUpdate = true
        const patch = self.prepareRequest({ ...info })

        const resp: ICreateUpdateTrackResponse = yield createOrUpdateTrack(
          {
            ...patch,
            uuid: self.uuid || undefined,
          },
          fragment
        )

        if (resp && resp.data.data?.createOrUpdateTrack.uuid) {
          if (successMessage) {
            toast.success(successMessage)
          }
          if (resp.data.data?.createOrUpdateTrack.validationErrors) {
            self.validationErrors = cast(resp.data.data?.createOrUpdateTrack.validationErrors)
          }

          self.uuid = resp.data.data?.createOrUpdateTrack.uuid
        }
        self.loadingPatchUpdate = false
        return resp?.data.data?.createOrUpdateTrack.uuid || ''
      } catch (err) {
        self.loadingPatchUpdate = false
        console.error(err)
        return ''
      }
    }),
  }))
  .actions(self => ({
    addOrUpdateParticipant: flow(function* (
      participant: IParticipantBasic,
      role: PARTICIPANT_ROLES,
      oldRole?: PARTICIPANT_ROLES
    ) {
      try {
        if (self.uuid && participant.uuid) {
          self.loadingParticipant = true

          self.participants = cast(
            self.participants?.filter(p => !(p.uuid === participant.uuid && p.role === oldRole)) || []
          )
          self.participants?.push({
            name: participant.name || '',
            uuid: participant.uuid,
            role,
            externalLinksToStores: participant.externalLinksToStores
              ? cast(participant.externalLinksToStores.map(el => getSnapshot(el)))
              : [],
          })

          yield self.createUpdate({
            info: {
              participantUuidsWithRoles: [
                ...(self.participants
                  ?.map(p => ({
                    participantUuid: p.uuid || '',
                    role: p.role,
                  }))
                  .filter(p => !(p.participantUuid === participant.uuid && p.role === oldRole)) || []),
              ],
            },

            fragment: 'uuid',
          })

          self.loadingParticipant = false
          toast.success('Successfully linked participant to release')
        }
      } catch (err) {
        self.loadingParticipant = false
        toast.error('Error while updating')
        console.error(err)
      }
    }),
    removeParticipant: flow(function* (participantUuid: string, role) {
      try {
        if (self.uuid) {
          self.loadingParticipant = true
          self.participants = cast(
            self.participants?.filter(p => !(p.uuid === participantUuid && p.role === role)) || []
          )
          yield self.createUpdate({
            info: {
              participantUuidsWithRoles: [
                ...(self.participants?.map(participant => ({
                  participantUuid: participant.uuid || '',
                  role: participant.role,
                })) ||
                  // .filter(p => p.participantUuid !== participantUuid)
                  []),
              ],
            },
          })

          self.loadingParticipant = false
        }
      } catch (err) {
        self.loadingParticipant = false
        toast.error('Error while updating')
        console.error(err)
      }
    }),
    uploadFile: flow(function* (file: TASFileStructContent, successMessage?: string) {
      try {
        // add the dropped object to asset as a volatile prop
        self.file = file

        // get upload url
        self.uploadingFile = true
        const uploadDetails: IGetUploadUrlObjectResponse = yield getUploadDetails({
          assetType: 'ArtTrack',
          assetFileName: self.file?.file?.name || '',
          assetUuid: self.uuid || '',
        })

        self.file.uploadUrl = uploadDetails?.uploadUrl || ''
        // start upload

        try {
          yield axios.put(self.file?.uploadUrl || '', self.file?.file, {
            headers: {
              'Content-Type': self.file?.file.type,
            },

            onUploadProgress: (e: AxiosProgressEvent) => {
              if (e.total) {
                const percentage = Math.round((100 * e.loaded) / e.total)
                self.setFileUploadProgress(percentage)
              }
            },
          })

          const resp: ICreateUpdateTrackResponse = yield createOrUpdateTrack(
            {
              fileName: self.file?.file?.name || '',
              fileSizeInBytes: self.file?.file?.size,
              uploadFinished: true,
              uuid: self.uuid || '',
            },
            artTrackFragmentWithValidationErrors
          )

          applySnapshot(self, resp?.data.data?.createOrUpdateTrack)

          self.uploadingFile = false
          toast.success(successMessage)
        } catch (error) {
          self.uploadingFile = false
          toast.error('File Upload Failed')
          console.error(error)
        }
      } catch (err) {
        console.error(err)
      }
    }),
    uploadMatchingImages: flow(function* (images: File[]) {
      if (self.images) {
        yield Promise.all(
          self.images.map(
            flow(function* (img: IImage) {
              const matchingImages = images.filter(i => i.name === img.fileName)

              if (matchingImages && matchingImages.length) {
                yield Promise.all(
                  matchingImages.map(
                    flow(function* (matchingImage) {
                      if (img.fileName && self.uuid) {
                        const uploadUrl = yield img.getImageUploadUrl(img.fileName, self.uuid)

                        if (uploadUrl) {
                          try {
                            yield axios.put(uploadUrl, matchingImage, {
                              headers: {
                                'Content-Type': matchingImage.type,
                              },
                            })

                            img.setUploading(false)

                            toast.success('Image uploaded')

                            yield self.load()
                          } catch (err) {
                            img.setUploading(false)
                            img.setDownloadUrl('')
                            console.error(err)

                            toast.error('Image upload error')
                          }
                        }
                      }
                    })
                  )
                )
              }
            })
          )
        )
      }
    }),

    uploadToShopify: flow(function* () {
      try {
        self.loading = true

        if (self.uuid) {
          const resp: IUploadToShopifyResponse = yield uploadToShopify(self.uuid)

          if (resp?.data.data?.uploadToShopify) {
            toast.success(`${self.title} is now on Microlicensing`)
            yield self.load()
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),

    editTrackMetadata: flow(function* (successMessage = '') {
      try {
        if (self.uuid === null) return
        self.loadingEditMetadata = true

        const editMetadataProps: IEditTrackMetadataProps = {
          trackUuid: self.uuid,
          metadata: {
            key: self.microlicensingProps?.key ?? null,
            bpm: self.microlicensingProps?.bpm ?? null,
            audioType: self.microlicensingProps?.audioType ?? null,
            moods: self.microlicensingProps?.moods ?? null,
            genres: self.microlicensingProps?.genreUuids ?? null,
            description: self.microlicensingProps?.description ?? null,
            shortDescription: self.microlicensingProps?.shortDescription ?? null,
            files: self.microlicensingProps?.files
              ? self.microlicensingProps.files.map(file => ({
                  filePath: file.filePath,
                  extension: file.extension,
                }))
              : null,
          },
        }

        const resp: IEditMetadataResponse = yield editTrackMetadata(editMetadataProps)

        if (resp && resp.data.data?.editMetadata.uuid && successMessage) {
          toast.success(successMessage)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingEditMetadata = false
      }
    }),
  }))

export type IArtTrack = Instance<typeof ArtTrack>
