import React from 'react'

import { SvgIconProps } from './iconProps'

const IconUser: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_164_2309)">
      <path
        fillRule="evenodd"
        d="M12 .5C5.649.5.5 5.649.5 12S5.649 23.5 12 23.5 23.5 18.351 23.5 12 18.351.5 12 .5zM2.5 12a9.5 9.5 0 1119 0 9.458 9.458 0 01-1.928 5.738 7.867 7.867 0 00-4.25-5.001 5 5 0 10-6.645 0 7.867 7.867 0 00-4.25 5A9.458 9.458 0 012.5 12zm3.612 7.456A9.46 9.46 0 0012 21.5a9.46 9.46 0 005.886-2.043l-.116-.644A5.87 5.87 0 0012 14a5.867 5.867 0 00-5.77 4.8l-.118.656zM12 6a3 3 0 100 6 3 3 0 000-6z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_164_2309">
        <path fill={fill} d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default IconUser
