import { Instance, cast, destroy, flow, types } from 'mobx-state-tree'
import {
  IAnnouncementProps,
  IAnnouncementResponse,
  ICurrentAnnouncementsResponse,
  currentAnnouncements,
  getAnnouncements,
} from '../../api/users-api/announcements'
import { Pagination } from '../general/Pagination.model'
import { Announcement, IAnnouncement } from './Announcement.model'

export const AnnouncementList = types
  .model({
    list: types.array(Announcement),
  })
  .volatile(() => ({
    loading: false,
    pagination: Pagination.create({ totalItems: 1 }),
    siteUuidFilter: '',
    statusFilter: '',
    enabledFilter: null as null | boolean,
    currentAndUpcomingFilter: null as null | boolean,
  }))
  .views(self => ({
    byUuid(uuid: string) {
      return self.list.find(announcement => announcement.uuid === uuid)
    },
  }))
  .actions(self => ({
    setSiteFilter(site: string) {
      self.siteUuidFilter = site
    },
    setStatusFilter(status: string) {
      self.statusFilter = status
    },
    setEnabledFilter(val: boolean | null) {
      self.enabledFilter = val
    },
    setCurrentAndUpcomingFilter(val: boolean | null) {
      self.currentAndUpcomingFilter = val
    },
    setAnnouncements(announcement: IAnnouncement[]) {
      // maybe detach here
      self.list = cast(announcement)
    },
    addAnnouncement(announcement: IAnnouncement) {
      self.list.push(cast(announcement))
    },
    removeAnnouncement(announcementUuid: string) {
      destroy(self.byUuid(announcementUuid))
    },
  }))

  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const variables: IAnnouncementProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.statusFilter && { status: self.statusFilter }),
            ...(self.enabledFilter && { isEnabled: self.enabledFilter }),
            ...(self.currentAndUpcomingFilter && { currentAndUpcoming: self.currentAndUpcomingFilter }),
          },
        }

        const resp: IAnnouncementResponse = yield getAnnouncements(variables)

        if (resp && resp.data.data) {
          self.setAnnouncements(resp.data.data.announcements.announcements)
          self.pagination.setTotalItems(resp.data.data.announcements.total)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
    loadCurrent: flow(function* () {
      try {
        self.loading = true
        const resp: ICurrentAnnouncementsResponse = yield currentAnnouncements()

        if (resp && resp.data.data?.currentAnnouncements) {
          self.setAnnouncements(resp.data.data.currentAnnouncements)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))

export type IAnnouncementList = Instance<typeof AnnouncementList>
