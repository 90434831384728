import { ReactNode } from 'react'
import { Offcanvas } from 'react-bootstrap'

interface IPlayAssetCanvas {
  show: boolean
  close: () => void
  children?: ReactNode
}

const PlayAssetCanvas = ({ show, close, children }: IPlayAssetCanvas): JSX.Element => {
  return (
    <Offcanvas
      show={show}
      onHide={close}
      placement="bottom"
      className="offcanvas-mediaplayer"
      style={{ height: 'auto' }}
    >
      {children}
    </Offcanvas>
  )
}

export default PlayAssetCanvas
