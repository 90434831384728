import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IRevenueShare } from '../../../models/assets/RevenueShare.model'
import { revenueShareFragment } from '../fragments'

interface IGetRevenueSharesProps {
  filters: {
    entityUuid?: string[]
    siteUuid?: string
  }
}

export type IGetRevenueSharesResponse = IGenericResponse<'revenueShares', IRevenueShare[]>

export async function getRevenueShares(
  props: IGetRevenueSharesProps,
  fragment: string = revenueShareFragment
): Promise<IGetRevenueSharesResponse> {
  const query = `
    query revenueShares($filters: RevenueShareFilterInput) {
      revenueShares(filters: $filters) {
        ${fragment}
      }
    }
  `

  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetRevenueSharesResponse>
}
