import { DELIVERY_PLATFORM, SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface ITriggerManualDeliveryProps {
  releaseUuid: string
  platformName: DELIVERY_PLATFORM
}

export type ITriggerManualDeliveryResponse = IGenericResponse<'triggerManualDelivery', boolean>

export async function triggerManualDelivery(
  props: ITriggerManualDeliveryProps
): Promise<ITriggerManualDeliveryResponse> {
  const query = `
    mutation triggerManualDelivery ($releaseUuid: String!, $platformName:  DeliveryService!) {
      triggerManualDelivery(releaseUuid: $releaseUuid, platformName: $platformName)
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<ITriggerManualDeliveryResponse>
}
