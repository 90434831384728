import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { numberFormat } from '@epic-front/common/src/helpers'
import { IPagination } from '@epic-front/common/src/models/general/Pagination.model'

type IPaginationProps = {
  pagination: IPagination
  callback?: () => void
  className?: string
}

const Pagination = ({ pagination, callback, className }: IPaginationProps): JSX.Element | null => {
  const [displaySelectId] = useState<string>(uniqueId('pagination-display-select-'))
  const [pageInputId] = useState<string>(uniqueId('pagination-page-input-'))

  if (!pagination.totalItems) {
    return null
  }

  return (
    <div className={`p-3 ${className}`}>
      <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between flex-wrap gap-3">
        {/* LEFT */}
        <div className="d-flex justify-content-center justify-content-sm-between gap-2 flex-wrap">
          <span className="align-self-center">Items per page:</span>
          <select
            id={displaySelectId}
            value={pagination.perPage}
            className="form-select d-inline-block w-auto "
            onChange={e => {
              pagination.setPerPage(Number(e.target.value))
              pagination.setPage(1)
              callback?.()
            }}
          >
            {pagination.perPageOptions.map(pageSize => {
              return (
                <option key={pageSize.value} value={pageSize.value}>
                  {pageSize.text}
                </option>
              )
            })}
          </select>
          <div className="align-self-center">
            {numberFormat(pagination.firstRecordNrOnPage, undefined, undefined, ',')} to{' '}
            {numberFormat(pagination.lastRecordNrONPage, undefined, undefined, ',')} of{' '}
            {numberFormat(pagination.totalItems, undefined, undefined, ',')} items
          </div>
        </div>
        {/* CENTER */}
        <div className="d-flex justify-content-center justify-content-sm-between justify-sm-content-between gap-1 fw-bold">
          <span className="align-self-center">Page:</span>
          <span className="align-self-center">
            {numberFormat(pagination.page, undefined, undefined, ',')} of{' '}
            {numberFormat(pagination.totalPages, undefined, undefined, ',')}
          </span>
        </div>
        {/* RIGHT */}
        <div className="d-flex justify-content-between gap-2 flex-grow-1 flex-sm-grow-0 flex-wrap">
          <div className="d-flex gap-2">
            <span className="align-self-center">Page: </span>
            <div className="d-flex align-items-center">
              <input
                type="number"
                id={pageInputId}
                value={pagination.page}
                className="form-control"
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) : 1
                  pagination.setPage(page)
                  callback?.()
                }}
              />
            </div>
          </div>

          <nav aria-label="navigation">
            <ul className="pagination m-0">
              <li className={classNames('page-item', !pagination.hasPreviewsPage && 'disabled')}>
                <button
                  type="button"
                  onClick={() => pagination.setPage(1)}
                  className="page-link h-100"
                  disabled={!pagination.hasPreviewsPage}
                >
                  <i className="uil-angle-double-left" />
                </button>{' '}
              </li>
              <li className={classNames('page-item', !pagination.hasPreviewsPage && 'disabled')}>
                <button
                  type="button"
                  onClick={() => pagination.previewsPage()}
                  className="page-link"
                  disabled={!pagination.hasPreviewsPage}
                >
                  <i className="uil-angle-left" />
                </button>{' '}
              </li>
              <li className={classNames('page-item', !pagination.hasNextPage && 'disabled')}>
                <button
                  type="button"
                  onClick={() => pagination.nextPage()}
                  className="page-link"
                  disabled={!pagination.hasNextPage}
                >
                  <i className="uil-angle-right" />
                </button>{' '}
              </li>
              <li className={classNames('page-item', !pagination.hasNextPage && 'disabled')}>
                <button
                  type="button"
                  onClick={() => pagination.setPage(pagination.totalPages)}
                  className="page-link"
                  disabled={!pagination.hasNextPage}
                >
                  <i className="uil-angle-double-right" />
                </button>{' '}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default observer(Pagination)
