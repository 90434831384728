import {
  PLATFORM,
  SONGMATE,
  ADMIN,
  EPIC_ELITE,
  ELITE_ALLIANCE,
  ELITE_ALLIANCE_MUSIC,
  SITE_SLUG,
  SONGPITCH,
  RELEASE_FORMATS,
} from '@epic-front/common/src/constants'
import adminLogoSm from '../assets/images/admin/logo-sm.svg'
import adminLogoSmVertical from '../assets/images/admin/logo-sm-vert.svg'
import adminLogo from '../assets/images/admin/logo-light.svg'
import adminLogoDark from '../assets/images/admin/logo-dark.svg'
import songmateLogoSm from '../assets/images/songmate/logo-sm.svg'
import songmateLogoDark from '../assets/images/songmate/logo-dark.svg'
import songmateLogo from '../assets/images/songmate/logo-light.svg'
import songPitchLogoSm from '../assets/images/songpitch/logo-sm.svg'
import songPitchLogoDark from '../assets/images/songpitch/logo-dark.svg'
import songPitchLogo from '../assets/images/songpitch/logo-light.svg'
import epicEliteLogoSm from '../assets/images/epicElite/logo-sm.svg'
import epicEliteLogo from '../assets/images/epicElite/logo-light.svg'
import epicEliteLogoDark from '../assets/images/epicElite/logo-dark.svg'
import eliteAllianceLogoSm from '../assets/images/eliteAlliance/logo-sm.svg'
import eliteAllianceLogo from '../assets/images/eliteAlliance/logo-light.svg'
import eliteAllianceLogoDark from '../assets/images/eliteAlliance/logo-dark.svg'
import eliteAllianceMusicLogoSm from '../assets/images/eliteAllianceMusic/logo-sm.svg'
import eliteAllianceMusicLogo from '../assets/images/eliteAllianceMusic/logo-light.svg'
import eliteAllianceMusicLogoDark from '../assets/images/eliteAllianceMusic/logo-dark.svg'
import epIcon from '../assets/images/svgs/ep.svg'
import albumIcon from '../assets/images/svgs/album.svg'
import singleIcon from '../assets/images/svgs/single.svg'

export const getSiteLogoLight = (): string => {
  let logo = epicEliteLogo

  switch (PLATFORM) {
    case SONGPITCH:
      logo = songPitchLogo
      break
    case SONGMATE:
      logo = songmateLogo
      break
    case ADMIN:
      logo = adminLogo
      break
    case EPIC_ELITE:
      logo = epicEliteLogo
      break
    case ELITE_ALLIANCE:
      logo = eliteAllianceLogo
      break
    case ELITE_ALLIANCE_MUSIC:
      logo = eliteAllianceMusicLogo
      break
    default:
      break
  }

  return logo
}

export const getSiteLogoDark = (): string => {
  let logo = epicEliteLogo

  switch (PLATFORM) {
    case SONGPITCH:
      logo = songPitchLogoDark
      break
    case SONGMATE:
      logo = songmateLogoDark
      break
    case ADMIN:
      logo = adminLogoDark
      break
    case EPIC_ELITE:
      logo = epicEliteLogoDark
      break
    case ELITE_ALLIANCE:
      logo = eliteAllianceLogoDark
      break
    case ELITE_ALLIANCE_MUSIC:
      logo = eliteAllianceMusicLogoDark
      break
    default:
      break
  }

  return logo
}

export const getSiteLogoSm = (): string => {
  let logoSm = epicEliteLogoSm

  switch (PLATFORM) {
    case SONGPITCH:
      logoSm = songPitchLogoSm
      break
    case SONGMATE:
      logoSm = songmateLogoSm
      break
    case ADMIN:
      logoSm = adminLogoSmVertical
      break
    case EPIC_ELITE:
      logoSm = epicEliteLogoSm
      break
    case ELITE_ALLIANCE:
      logoSm = eliteAllianceLogoSm
      break
    case ELITE_ALLIANCE_MUSIC:
      logoSm = eliteAllianceMusicLogoSm
      break
    default:
      break
  }

  return logoSm
}

export const getSiteLogoSmBySlug = (slug: string): string => {
  let icon = ''

  switch (slug) {
    case SITE_SLUG.ELITE_ALLIANCE_MUSIC:
      icon = eliteAllianceMusicLogoSm
      break
    case SITE_SLUG.ELITE_ALLIANCE:
      icon = eliteAllianceLogoSm
      break
    case SITE_SLUG.EPIC_ELITE:
      icon = epicEliteLogoSm
      break
    case SITE_SLUG.SONGMATE:
      icon = songmateLogoSm
      break
    case SITE_SLUG.SONGPITCH:
      icon = songmateLogoSm
      break
    case SITE_SLUG.ADMIN:
      icon = adminLogoSm
      break
    default:
      break
  }
  return icon
}

export const getReleaseTypeIcon = (type: string): string => {
  if (type === RELEASE_FORMATS.ALBUM) {
    return albumIcon
  }
  if (type === RELEASE_FORMATS.EP) {
    return epIcon
  }
  if (type === RELEASE_FORMATS.SINGLE) {
    return singleIcon
  }
  return singleIcon
}
