import { SERVICE_NAME } from '../../../constants'
import { IAssetBasic } from '../../../models/assets/AssetBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { assetFullRawFragment } from '../fragmentsAssets'
import { IGetAssetsProps } from './getAssets'

export type IGetAssetsRawResponse = IGenericResponse<'assetsRaw', { assets: IAssetBasic[]; total: number }>

export async function getAssetsRaw(
  { pagination, filters }: IGetAssetsProps,
  fragment: string = assetFullRawFragment
): Promise<IGetAssetsRawResponse> {
  const query = `
    query assetsRaw ($pagination: PaginationInput, $filters: AssetsFilterInput) {
      assetsRaw (pagination: $pagination, filters: $filters) {
        total
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IGetAssetsRawResponse
}
