import { applySnapshot, Instance } from 'mobx-state-tree'

import { IAssetUser } from '../AssetUser.model'
import { ChannelVideoBasic } from './ChannelVideoBasic.model'

export const ChannelVideo = ChannelVideoBasic.volatile(() => ({
  loading: false,
}))
  .views(self => ({
    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid
    },
  }))

  .actions(self => ({
    updateAssetWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },
  }))

export type IChannelVideo = Instance<typeof ChannelVideo>
