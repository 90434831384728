import { Instance, types, flow, cast } from 'mobx-state-tree'
import { IGetStoresResponse, getStores } from '../../api/assets-api/audioRelease/getStores'
import { DistributionStore } from './DistributionStore.model'

export const DistributionStoreList = types
  .model({
    list: types.array(DistributionStore),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetStoresResponse = yield getStores()
        if (resp && resp.data.data?.getStores) {
          self.list = cast(resp.data.data.getStores)
        }
        self.loading = false
      } catch (err) {
        console.error(err)
      }
    }),
  }))

export type IDistributionStoreList = Instance<typeof DistributionStoreList>
