import React from 'react'
import { ERROR_BOUNDARY_REFRESH_TIMEOUT } from '@epic-front/common'
import ErrorBoundaryElement, { PAGE_FORCE_REFRESH_KEY } from '../pages/errors/ErrorBoundaryElement'

interface ErrorBoundaryProps {
  children: React.ReactNode
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    const lastForcedRefreshTimestamp = JSON.parse(window.localStorage.getItem(PAGE_FORCE_REFRESH_KEY) || 'null') as
      | number
      | null

    if (
      !lastForcedRefreshTimestamp ||
      new Date().getTime() - lastForcedRefreshTimestamp > ERROR_BOUNDARY_REFRESH_TIMEOUT
    ) {
      window.localStorage.setItem(PAGE_FORCE_REFRESH_KEY, new Date().getTime().toString())

      window.location.reload()
      return { hasError: false }
    }

    return { hasError: true }
  }

  componentDidCatch(error: unknown, info: unknown) {
    console.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryElement />
    }
    return this.props.children
  }
}
