import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

import { IDailyEstimatedEarningReportBasic } from '../../models/assets/earningReports/DailyEstimatedEarningReportBasic.model'
import { SERVICE_NAME } from '../../constants'
import { dailyEstimatedEarningsFragment } from './fragments'

interface IGetDailyEstimatedEarningsProps {
  filters: {
    userUuid?: string
    siteUuid?: string
    startDate?: string
    endDate?: string
  }
}

export type IGetDailyEstimatedEarningsResponse = IGenericResponse<
  'dailyEstimatedEarnings',
  IDailyEstimatedEarningReportBasic[]
>

export async function getDailyEstimatedEarnings(
  props: IGetDailyEstimatedEarningsProps,
  fragment: string = dailyEstimatedEarningsFragment
): Promise<IGetDailyEstimatedEarningsResponse> {
  const query = `
    query dailyEstimatedEarnings ($filters: DailyEstimatedEarningFilterInput!) {
      dailyEstimatedEarnings (filters: $filters) {
        ${fragment}
      } 
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetDailyEstimatedEarningsResponse>
}
