import { SERVICE_NAME } from '../../../constants'
import { IRole } from '../../../models/users/Role.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { roleFragment } from '../fragments'

export type IEditRoleResponse = IGenericResponse<'editRole', IRole>

export type IEditRoleProps = {
  roleUuid: string
  name: string
  description: string
  isInternal: boolean
}

export async function editRole(props: IEditRoleProps, fragment: string = roleFragment): Promise<IEditRoleResponse> {
  const query = `
    mutation editRole($roleUuid: String!, $name: String!, $description: String!, $isInternal: Boolean!){
      editRole (roleUuid: $roleUuid, name: $name, description: $description, isInternal: $isInternal) {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, props)) as IEditRoleResponse
}
