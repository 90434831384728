import { cast, flow, Instance, types } from 'mobx-state-tree'

import { getTopAssets, IGetTopStreamedSongsResponse } from '../../api/assets-api/assets/getTopAssets'
import { ANALYTICS_ASSET_TYPE, TOP_ASSETS_TYPE } from '../../constants'
import { ArtTrackBasic } from './artTracks/ArtTrackBasic.model'
import { AssetBasic } from './AssetBasic.model'
import { ChannelVideoBasic } from './channelVideos/ChannelVideoBasic.model'
import { ReleaseBasic } from './releases/ReleaseBasic.model'

export const TopList = types
  .model({
    list: types.array(
      types.model({
        asset: types.maybeNull(AssetBasic),
        release: types.maybeNull(ReleaseBasic),
        channelVideo: types.maybeNull(ChannelVideoBasic),
        track: types.maybeNull(ArtTrackBasic),
        claims: types.maybeNull(types.number),
        earnings: types.maybeNull(types.number),
        views: types.maybeNull(types.number),
      })
    ),
    sortBy: types.enumeration(Object.values(TOP_ASSETS_TYPE)),
    type: types.enumeration(Object.values(ANALYTICS_ASSET_TYPE)),
  })
  .volatile(() => ({
    loading: false,
    limit: 5,
    userUuidSelector: undefined as undefined | string,
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const resp: IGetTopStreamedSongsResponse = yield getTopAssets({
          limit: self.limit,
          sortBy: self.sortBy,
          type: self.type,
          userUuid: self.userUuidSelector,
        })

        if (resp && resp.data.data?.topAssets) {
          self.list = cast(resp.data.data.topAssets)
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))

export type ITopList = Instance<typeof TopList>
