import { Instance, flow, types } from 'mobx-state-tree'
import { editContactRequest } from '../../api/users-api/contactRequests/editContactRequest'
import { JSONPrimitive } from '../../constants'

export const ContactRequest = types
  .model({
    uuid: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    content: JSONPrimitive,
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    updatedBy: types.maybeNull(types.string),
    subjectType: types.maybe(types.string),
    siteUuid: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    cvUrl: types.maybeNull(types.string),
    linkedInUrl: types.maybeNull(types.string),
    subscribers: types.maybeNull(types.array(types.string)),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    editContactRequest: flow(function* ({ status, subjectType }: { status?: string; subjectType?: string }) {
      try {
        if (self.uuid && (status || subjectType)) {
          self.loading = true
          yield editContactRequest({
            uuid: self.uuid,
            subjectType,
            status,
          })

          self.loading = false
        }
      } catch (e) {
        self.loading = false
        console.error(e)
      }
    }),
  }))

export type IContactRequest = Instance<typeof ContactRequest>
