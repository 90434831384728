import { cast, flow, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { getMe, IGetMeResponse } from '../../api/users-api/auth/getMe'
import { ILoginResponse, login } from '../../api/users-api/auth/login'
import { ADMIN, PLATFORM, SESSION_DURATION } from '../../constants'
import { IUser, User } from './User.model'
import { IUserBasic } from './UserBasic.model'

const Auth = types
  .model({
    token: types.maybe(types.string),
    user: types.maybe(User),
    expiryDate: types.maybe(types.number),
  })
  .volatile(() => ({
    loading: false,
  }))
  .views(self => ({
    get isAuth() {
      return !!self.user
    },
    get isTokenExpired() {
      return self.expiryDate && self.expiryDate < Date.now()
    },
    get isAdminOnClientSite() {
      return PLATFORM !== ADMIN && self.user?.hasInternalRole
    },
    get roleNames() {
      const r = self.user?.roles || []
      return r.map(item => item.name)
    },
    get allPermissions() {
      return self.user?.roles.map(role => role.permissions.map(perm => perm.name))?.flat()
    },
  }))
  .actions(self => ({
    setToken(token: string | undefined) {
      self.token = token
    },
    setUser(user: IUser | IUserBasic | undefined) {
      self.user = cast(user)
    },
  }))
  .actions(self => ({
    logout() {
      self.setToken(undefined)
      self.setUser(undefined)

      self.user = undefined
      self.expiryDate = undefined
    },
  }))
  .actions(self => ({
    // we only use this on startup
    getMe: flow(function* () {
      try {
        if (self.token) {
          const resp: IGetMeResponse = yield getMe()
          if (resp && resp.data.data?.me) {
            self.user = resp.data.data?.me as IUser
          } else {
            self.logout()
            throw new Error()
          }
        }
      } catch (err) {
        throw new Error()
      }
    }),

    login: flow(function* ({ email, password }: { email: string; password: string }) {
      try {
        self.loading = true

        const res: ILoginResponse = yield login({
          email,
          password,
        })

        if (res && res.data.data) {
          const user = res.data.data?.login.user as IUser
          const token = res.data.data?.login.token || undefined
          const expiryDate = Date.now() + SESSION_DURATION // get expiry from server

          self.user = user
          self.token = token
          self.expiryDate = expiryDate
        }

        if (self.isAdminOnClientSite) {
          toast.error('User is not a Client')
          self.logout()
        }

        self.loading = false
      } catch (error) {
        // error is handled in clientWrapper
        self.loading = false
      }
    }),
    hasPermission(permission: string) {
      const hasPermission =
        self.user?.roles.map(role => role.hasPermission(permission)).reduce((curr, aggr) => curr || aggr, false) ||
        false

      return hasPermission
    },
  }))

export default Auth
