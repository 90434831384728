import { SERVICE_NAME } from '../../../constants'
import { IPaymentMethod } from '../../../models/payment/PaymentMethod.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { paymentMethodBasicFragment } from './fragments'

export type IGetPaymentMethodsResponse = IGenericResponse<'paymentMethods', IPaymentMethod[]>

export async function getPaymentMethods(
  fragment: string = paymentMethodBasicFragment
): Promise<IGetPaymentMethodsResponse> {
  const query = `
    query getPaymentMethods {
      paymentMethods {
        ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.PAYMENTS, query)) as IGetPaymentMethodsResponse
}
