import axios from 'axios'
import { applySnapshot, cast, destroy, detach, flow, getSnapshot, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { assignOwnerToReleases, deliverThroughDdex, getUploadUrlForDDEX, imageDelete } from '../../../api/assets-api'
import { IAssignOwnerToReleasesResponse } from '../../../api/assets-api/assets/assignOwnerToReleases'
import { changeReleasesState, IChangeReleaseStatesResponse } from '../../../api/assets-api/assets/changeReleasesState'
import {
  createOrUpdateRelease,
  ICreateUpdateReleaseResponse,
} from '../../../api/assets-api/assets/createOrUpdateRelease'
import { IDeleteAssetResponse } from '../../../api/assets-api/assets/deleteAsset'
import { deleteRelease } from '../../../api/assets-api/assets/deleteRelease'
import { IEditMetadataResponse } from '../../../api/assets-api/assets/editMetadata'
import { editReleaseMetadata, IEditReleaseMetadataProps } from '../../../api/assets-api/assets/editReleaseMetadata'
import { ITakedownShopifyResponse, takedownShopify } from '../../../api/assets-api/assets/takedownShopify'
import {
  ITriggerManualDeliveryResponse,
  triggerManualDelivery,
} from '../../../api/assets-api/assets/triggerManualDelivery'
import { IUploadToShopifyResponse, uploadToShopify } from '../../../api/assets-api/assets/uploadToShopify'
import { findReleaseByUuid, IFindReleaseByUuidResponse } from '../../../api/assets-api/findReleaseByUuid'
import { releaseFragment, releaseFragmentWithFullArtTracks } from '../../../api/assets-api/fragmentsAssets'
import {
  getAssetLifetimeEarnings,
  IGetAssetLifetimeEarningsResponse,
} from '../../../api/assets-api/getAssetLifetimeEarnings'
import {
  downloadAssetDistributionFormat,
  IDownloadAssetDistributionFormatResponse,
} from '../../../api/assets-api/other/downloadAssetDistributionFormat'
import {
  IDeliverThroughDDEXAudioReleaseResponse,
  IGetUploadUrlForDDEXResponse,
  IImageDeleteResponse,
} from '../../../api/types'
import {
  ADMIN,
  APPROVAL_STATES,
  DDEXUpload,
  DELIVERY_PLATFORM,
  IMAGE_TYPE,
  LABEL_VALIDITY,
  MAX_FILES_PER_TYPE,
  PARTICIPANT_ROLES,
  PLATFORM,
  PLATFORM_STATES,
  RELEASE_FORMATS,
  TASFileStructContent,
} from '../../../constants'
import { getLabelFromAssetState } from '../../../helpers/assets'
import { IAssetFormData } from '../../../types/common'
import { IImage } from '../../general/Image.model'
import { IAssetUser } from '../AssetUser.model'
import { IMicrolicensingPropsConfig } from '../MicrolicensingPropsConfig.model'
import { IParticipantBasic } from '../ParticipantBasic'
import { ReleaseBasic } from './ReleaseBasic.model'

export const Release = ReleaseBasic.volatile(() => ({
  loading: false,
  loadingPatchUpdate: false,
  uploadingFile: false,
  loadingPlatformUpdate: false,
  loadingParticipant: false,
  loadingEditMetadata: false,
  fileUploadProgress: null as number | null,
  file: null as TASFileStructContent | null,
  deliveredDdex: false,
  labelValid: LABEL_VALIDITY.NOT_CHECKED as LABEL_VALIDITY,
  totalRevenue: null as number | null,
  lifetimeEarnings: null as number | null,
  distributionLinks: [] as string[],
}))
  .views(self => ({
    get mainGenre() {
      if (self.genreUuids && self.genreUuids.length > 0) {
        return self.genreUuids[0]
      }
      return null
    },
    get secondaryGenre() {
      if (self.genreUuids && self.genreUuids.length > 1) {
        return self.genreUuids[1]
      }
      return null
    },

    get imageUuids() {
      return self.images?.map(img => img.uuid)
    },

    getParticipantUuidAndRoles() {
      const list: { participantUuid: string; role: PARTICIPANT_ROLES }[] = []
      self.participants?.forEach(p => {
        if (p.uuid && p.role) {
          list.push({ participantUuid: p.uuid, role: p.role })
        }
      })
      return list
    },

    get otherImage() {
      return self.images?.find(img => img.type === IMAGE_TYPE.OTHER)
    },

    get artistImage() {
      return self.images?.find(img => img.type === IMAGE_TYPE.ARTIST)
    },

    getFrontImage() {
      return self.images?.find(img => img.type === IMAGE_TYPE.FRONT)
    },
    getImageByUuid(uuid: string) {
      return self.images?.find(img => {
        return img.uuid === uuid
      })
    },

    getPlatform(platform: DELIVERY_PLATFORM) {
      if (self.platforms !== null) {
        return self.platforms.find(delivery => delivery.name === platform)
      }
      return null
    },
    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid
    },
  }))
  .views(self => ({
    get audioSaladState() {
      return self.getPlatform(DELIVERY_PLATFORM.AUDIO_SALAD)
    },
    get ddexState() {
      return self.getPlatform(DELIVERY_PLATFORM.DDEX)
    },
  }))
  .views(self => ({
    get hasEditDisabled() {
      // on admin we want to be less restrictive
      if (PLATFORM === ADMIN) {
        return self.clientStatus === PLATFORM_STATES.LIVE || self.clientStatus === PLATFORM_STATES.UPCOMING
      }
      return (
        [
          PLATFORM_STATES.LIVE as string,
          PLATFORM_STATES.UPCOMING as string,
          APPROVAL_STATES.PENDING_APPROVAL as string,
          PLATFORM_STATES.TAKEN_DOWN as string,
          APPROVAL_STATES.APPROVED as string,
        ].indexOf(self.clientStatus || '') > -1
      )
    },
  }))

  .actions(self => ({
    updateAssetWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },
    applyChanges: (info: IAssetFormData) => {
      const selfCopy = { ...getSnapshot(self) }

      applySnapshot(self, {
        ...selfCopy,
        ...{ ...info, stores: selfCopy.stores },
        cYear: info.cYear ? parseInt(info.cYear || '', 10) : selfCopy.cYear,
        pYear: info.pYear ? parseInt(info.pYear || '', 10) : selfCopy.pYear,
      })
    },

    applyMicrolicensingChanges: (microlicensingPropsConfigInput: Partial<IMicrolicensingPropsConfig>) => {
      const microlicensingPropsCopy = { ...self.microlicensingProps }

      const microlicensingProps = {
        ...microlicensingPropsCopy,
        ...microlicensingPropsConfigInput,
      }

      applySnapshot(self, {
        ...self,
        microlicensingProps,
      })
    },

    hasStore: (store: string): boolean => {
      return self.stores?.some(el => el.name === store) || false
    },

    removeSplit: (splitUuid: string) => {
      const removedSplitIndex = self.users.findIndex(assetUser => assetUser.revSplitUuid === splitUuid)
      const removeSplit = self.users.splice(removedSplitIndex, 1)[0]

      const ownerIndex = self.users.findIndex(assetUser => !assetUser.revSplitUuid)
      self.users[ownerIndex].percentage = (self.users[ownerIndex].percentage || 100) + (removeSplit.percentage || 0)
    },

    // add image locally
    addImageLocally(image: IImage) {
      self.images?.push(image)
    },
    removeImage: flow(function* (imgUuid: string) {
      try {
        const resp: IImageDeleteResponse = yield imageDelete(imgUuid)
        if (resp && resp.data.data?.Image_delete) {
          // WE CREATE AN OBJECT TO PASS IT AND DESTROY IT HERE MAYBE WE SHOULD FIND IT AND DESTROY IT HERE
          const img = self.getImageByUuid(imgUuid)
          destroy(img)
        }
      } catch (err) {
        console.error(err)
      }
    }),

    setAssetState(state: APPROVAL_STATES) {
      self.assetState = state
    },
    changeReleaseFormat(newValue: RELEASE_FORMATS, trackQuantity?: number) {
      if (newValue === RELEASE_FORMATS.SINGLE && trackQuantity && trackQuantity > MAX_FILES_PER_TYPE.Single) {
        toast.warning('Make sure you have a maximum of TWO track for a Single Release')
      }
      if (newValue === RELEASE_FORMATS.EP && trackQuantity && trackQuantity > MAX_FILES_PER_TYPE.EP) {
        toast.warning('Make sure you have a maximum of 6 tracks for an EP Release')
      }
      self.releaseFormat = newValue
    },
    setUuid(uuid: string) {
      self.uuid = uuid
    },
    setMainGenre(newValue: string | null) {
      if (newValue && self.genreUuids) self.genreUuids[0] = newValue ?? undefined
    },
    setSecondaryGenre(newValue: string | null) {
      if (newValue && self.genreUuids) self.genreUuids[1] = newValue ?? undefined
    },
    setTracksOrder(trackList: string[]) {
      self.trackOrder = cast(trackList)
    },
    swapTrackOrder(index1: number, index2: number) {
      if (self.trackOrder) {
        if (index1 > -1 && index2 > -1 && index1 < self.trackOrder.length && index2 < self.trackOrder.length) {
          // swamp
          ;[self.trackOrder[index1], self.trackOrder[index2]] = [self.trackOrder[index2], self.trackOrder[index1]]
        }
      }
    },
    setLabelValid(newState: LABEL_VALIDITY) {
      self.labelValid = newState
    },
    setTotalRevenue(revenue: number) {
      self.totalRevenue = revenue
    },
    setGeneralInfoStepPassed(newValue: boolean) {
      self.generalInfoStepPassed = newValue
    },
    setTrackInfoStepPassed(newValue: boolean) {
      self.trackInfoStepPassed = newValue
    },
    setTargetPlatformStepPassed(newValue: boolean) {
      self.targetPlatformStepPassed = newValue
    },

    setOwner(uuid: string) {
      const prevOwner = self.getOwnerUuid()
      if (prevOwner) {
        self.users = cast(self.users.filter(user => user.userUuid !== prevOwner))
      }
      self.users.push({ userUuid: uuid, isOwner: true })
    },

    setFileUploadProgress(progress: number | null) {
      self.fileUploadProgress = progress
    },

    getStreamingLinkByStore(store: string) {
      return self.streamingLinks?.find(link => link.store === store)
    },
    getUploadUrlForDDEX: flow(function* (releaseUuid: string, uploadData: DDEXUpload) {
      const variables = {
        releaseUuid,
        userUuid: uploadData.userUuid,
        csvFileName: uploadData.csv.name,
        wavFileNames: uploadData.wavs.map(wav => wav.name),
        jpegFileNames: uploadData.jpegs.map(jpeg => jpeg.name),
      }

      try {
        const resp: IGetUploadUrlForDDEXResponse = yield getUploadUrlForDDEX(variables)
        if (resp && resp.data.data?.getUploadUrlForDDEX) {
          return resp.data.data.getUploadUrlForDDEX
        }
      } catch (err) {
        console.error(err)
      }

      return ''
    }),
    setDownloadUrl(newUrl: string | null) {
      self.downloadUrl = newUrl
    },

    prepareRequest(formData: IAssetFormData) {
      const reqBody = { ...formData }

      if (reqBody.releaseFormat === null) {
        delete reqBody.releaseFormat
      }

      const patch = {
        ...reqBody,
        cYear: reqBody.cYear ? parseInt(reqBody.cYear || '', 10) : undefined,
        pYear: reqBody.pYear ? parseInt(reqBody.pYear || '', 10) : undefined,
      }

      if (reqBody.releaseDate === '') {
        patch.releaseDate = undefined
      }
      if (reqBody.originalReleaseDate === '') {
        patch.originalReleaseDate = undefined
      }
      if (reqBody.preorderDate === '') {
        patch.preorderDate = undefined
      }

      delete patch.participantsPresent
      delete patch.albumArtPresent
      return patch
    },
  }))
  .actions(self => ({
    load: flow(function* (fragment?: string) {
      try {
        self.loading = true
        if (self.uuid) {
          const resp: IFindReleaseByUuidResponse = yield findReleaseByUuid(self.uuid, fragment) // load full release
          if (resp && resp.data.data?.findReleaseByUuid) {
            const assetResp = resp.data.data?.findReleaseByUuid
            const preparedAsset = { ...assetResp }
            preparedAsset.participants = cast(assetResp?.participants || [])

            if (self.participants) {
              detach(self.participants)
            }
            if (self.images) {
              detach(self.images)
            }
            applySnapshot(self, preparedAsset)
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
    loadLifetimeEarnings: flow(function* () {
      try {
        self.loadingPatchUpdate = true
        if (self.uuid) {
          const resp: IGetAssetLifetimeEarningsResponse = yield getAssetLifetimeEarnings(self.uuid)
          if (resp && resp.data.data?.assetLifetimeEarnings) {
            self.lifetimeEarnings = resp.data.data?.assetLifetimeEarnings
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingPatchUpdate = false
      }
    }),
    loadParticipants: flow(function* () {
      try {
        if (self.uuid) {
          const resp = yield findReleaseByUuid(self.uuid)
          if (resp && resp.data.data.findAssetByUuid) {
            if (self.participants) {
              detach(self.participants)
            }
            self.participants = cast(resp.data.data.findAssetByUuid.participants)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),

    delete: flow(function* () {
      try {
        if (self.uuid) {
          const resp: IDeleteAssetResponse = yield deleteRelease({ uuid: self.uuid })
          if (resp && resp.data.data?.deleteAsset) {
            toast.success(`Release deleted.`)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),

    triggerManualDelivery: flow(function* (platformName: DELIVERY_PLATFORM) {
      try {
        if (!self.uuid) {
          throw new Error('Release uuid is not set')
        }

        self.loadingPlatformUpdate = true

        const resp: ITriggerManualDeliveryResponse = yield triggerManualDelivery({
          releaseUuid: self.uuid,
          platformName,
        })

        self.loadingPlatformUpdate = false

        return resp && resp.data.data?.triggerManualDelivery === true
      } catch (err) {
        self.loadingPlatformUpdate = false
        console.error(err)

        return false
      }
    }),

    changeAssetState: flow(function* ({
      targetState,
      comment,
      successMsg,
    }: {
      targetState: APPROVAL_STATES
      comment?: string
      successMsg?: string
    }) {
      try {
        if (self.uuid) {
          self.loadingPlatformUpdate = true
          const resp: IChangeReleaseStatesResponse = yield changeReleasesState([
            {
              itemId: self.uuid,
              targetState,
              comment,
            },
          ])
          if (resp && resp.data.data?.changeReleasesState[0]?.assetState) {
            self.setAssetState(resp.data.data?.changeReleasesState[0].assetState)
            toast.success(successMsg || `Release state set to ${getLabelFromAssetState(targetState)}`)
          }
          self.loadingPlatformUpdate = false
        }
      } catch (err) {
        self.loadingPlatformUpdate = false
        console.error(err)
      }
      return ''
    }),

    assignOwner: flow(function* (selectedAssignUser: string) {
      try {
        if (self.uuid) {
          self.loadingPatchUpdate = true
          const resp: IAssignOwnerToReleasesResponse = yield assignOwnerToReleases({
            ownerUuid: selectedAssignUser,
            releaseUuids: [self.uuid],
          })
          if (resp && resp.data.data?.assignOwnerToReleases) {
            toast.success('Assigned')

            self.setOwner(selectedAssignUser)
          }
          self.loadingPatchUpdate = false
        }
      } catch (e) {
        console.error(e)
        self.loadingPatchUpdate = false
        toast.success('Error assigning to user')
      }
    }),

    createUpdate: flow(function* ({
      info,
      successMessage = '',
      fragment = 'uuid',
    }: {
      info: IAssetFormData
      successMessage?: string
      fragment?: string
    }) {
      try {
        self.loadingPatchUpdate = true
        const patch = self.prepareRequest({ ...info })

        const resp: ICreateUpdateReleaseResponse = yield createOrUpdateRelease(
          {
            ...patch,
            uuid: self.uuid || undefined,
          },
          fragment
        )

        if (resp && resp.data.data?.createOrUpdateRelease.uuid) {
          if (successMessage) {
            toast.success(successMessage)
          }
          if (resp.data.data?.createOrUpdateRelease.validationErrors) {
            self.validationErrors = cast(resp.data.data?.createOrUpdateRelease.validationErrors)
          }

          self.uuid = resp.data.data?.createOrUpdateRelease.uuid

          if (resp.data.data?.createOrUpdateRelease.stores) {
            // stores were updated
            self.stores = cast(resp.data.data?.createOrUpdateRelease.stores)
          }
        }
        self.loadingPatchUpdate = false
        return resp?.data.data?.createOrUpdateRelease.uuid || ''
      } catch (err) {
        self.loadingPatchUpdate = false
        console.error(err)
        return ''
      }
    }),
    deliverThroughDdex: flow(function* () {
      try {
        self.loading = true
        if (self.uuid) {
          const resp: IDeliverThroughDDEXAudioReleaseResponse = yield deliverThroughDdex({
            releaseUuid: self.uuid,
          })
          if (resp && resp.data.data?.deliverThroughDdex) {
            self.deliveredDdex = true
            toast.success('Release successfully queued for delivery!')
            return
          }
          toast.error('Audio Release files could not be queued for delivery!')
        }
        self.loading = false
      } catch (err) {
        toast.error('Audio Release files could not be queued for delivery!')
        console.error(err)
        self.loading = false
      }
    }),

    downloadAssetDistributionFormat: flow(function* (deliveryService: DELIVERY_PLATFORM) {
      self.distributionLinks = []

      try {
        self.loading = true

        if (self.uuid) {
          const resp: IDownloadAssetDistributionFormatResponse = yield downloadAssetDistributionFormat({
            releaseUuid: self.uuid,
            deliveryService,
          })

          if (resp && resp.data.data?.downloadAssetDistributionFormat) {
            self.distributionLinks.push(...resp.data.data.downloadAssetDistributionFormat)
          }
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    addOrUpdateParticipant: flow(function* (
      participant: IParticipantBasic,
      role: PARTICIPANT_ROLES,
      oldRole?: PARTICIPANT_ROLES
    ) {
      try {
        if (self.uuid && participant.uuid) {
          self.loadingParticipant = true

          self.participants = cast(
            self.participants?.filter(p => !(p.uuid === participant.uuid && p.role === oldRole)) || []
          )
          self.participants?.push({
            name: participant.name || '',
            uuid: participant.uuid,
            role,
            externalLinksToStores: participant.externalLinksToStores
              ? cast(participant.externalLinksToStores.map(el => getSnapshot(el)))
              : [],
          })

          yield self.createUpdate({
            info: {
              participantUuidsWithRoles: [
                ...(self.participants
                  ?.map(p => ({
                    participantUuid: p.uuid || '',
                    role: p.role,
                  }))
                  .filter(p => !(p.participantUuid === participant.uuid && p.role === oldRole)) || []),
              ],
            },
          })

          self.loadingParticipant = false
          toast.success('Successfully linked participant to release')
        }
      } catch (err) {
        self.loadingParticipant = false
        toast.error('Error while updating')
        console.error(err)
      }
    }),
    removeParticipant: flow(function* (participantUuid: string, role) {
      try {
        if (self.uuid) {
          self.loadingParticipant = true
          self.participants = cast(
            self.participants?.filter(p => !(p.uuid === participantUuid && p.role === role)) || []
          )
          yield self.createUpdate({
            info: {
              participantUuidsWithRoles: [
                ...(self.participants?.map(participant => ({
                  participantUuid: participant.uuid || '',
                  role: participant.role,
                })) ||
                  // .filter(p => p.participantUuid !== participantUuid)
                  []),
              ],
            },
            fragment: releaseFragment,
          })

          self.loadingParticipant = false
        }
      } catch (err) {
        self.loadingParticipant = false
        toast.error('Error while updating')
        console.error(err)
      }
    }),

    uploadMatchingImages: flow(function* (images: File[]) {
      if (self.images) {
        yield Promise.all(
          self.images.map(
            flow(function* (img: IImage) {
              const matchingImages = images.filter(i => i.name === img.fileName)

              if (matchingImages && matchingImages.length) {
                yield Promise.all(
                  matchingImages.map(
                    flow(function* (matchingImage) {
                      if (img.fileName && self.uuid) {
                        const uploadUrl = yield img.getImageUploadUrl(img.fileName, self.uuid)

                        if (uploadUrl) {
                          try {
                            yield axios.put(uploadUrl, matchingImage, {
                              headers: {
                                'Content-Type': matchingImage.type,
                              },
                            })

                            img.setUploading(false)

                            toast.success('Image uploaded')

                            yield self.load(releaseFragmentWithFullArtTracks)
                          } catch (err) {
                            img.setUploading(false)
                            img.setDownloadUrl('')
                            console.error(err)

                            toast.error('Image upload error')
                          }
                        }
                      }
                    })
                  )
                )
              }
            })
          )
        )
      }
    }),

    editReleaseMetadata: flow(function* (successMessage = '') {
      try {
        if (self.uuid === null) return
        self.loadingEditMetadata = true

        const editMetadataProps: IEditReleaseMetadataProps = {
          releaseUuid: self.uuid,
          metadata: {
            key: self.microlicensingProps?.key ?? null,
            bpm: self.microlicensingProps?.bpm ?? null,
            audioType: self.microlicensingProps?.audioType ?? null,
            moods: self.microlicensingProps?.moods ?? null,
            genres: self.microlicensingProps?.genreUuids ?? null,
            description: self.microlicensingProps?.description ?? null,
            shortDescription: self.microlicensingProps?.shortDescription ?? null,
            files: self.microlicensingProps?.files
              ? self.microlicensingProps.files.map(file => ({
                  filePath: file.filePath,
                  extension: file.extension,
                }))
              : null,
          },
        }

        const resp: IEditMetadataResponse = yield editReleaseMetadata(editMetadataProps)

        if (resp && resp.data.data?.editMetadata.uuid && successMessage) {
          toast.success(successMessage)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingEditMetadata = false
      }
    }),

    uploadToShopify: flow(function* () {
      try {
        self.loading = true

        if (self.uuid) {
          const resp: IUploadToShopifyResponse = yield uploadToShopify(self.uuid)

          if (resp?.data.data?.uploadToShopify) {
            toast.success(`${self.title} is now on Microlicensing`)
            yield self.load()
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),

    takedownShopify: flow(function* () {
      try {
        self.loading = true

        if (self.uuid) {
          const resp: ITakedownShopifyResponse = yield takedownShopify(self.uuid)

          if (resp?.data.data?.takedownShopify) {
            toast.success(`${self.title} is now unpublished from Shopify`)
            yield self.load()
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IRelease = Instance<typeof Release>
