import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { SERVICE_NAME } from '../../../constants'
import { IPaginationParams } from '../../../types'

import { adminShiftFragmentLines } from '../fragments'
import { IAdminShift } from '../../../models'

export type IGetAdminShiftsResponse = IGenericResponse<'adminShifts', IAdminShift[]>

export type IGetAdminShiftsProps = {
  filters?: {
    userUuid?: string
    siteUuid?: string
    status?: string
  }
  pagination?: IPaginationParams
}
export async function getAdminShifts(props: IGetAdminShiftsProps): Promise<IGetAdminShiftsResponse> {
  const query = `
  query adminShifts($filters: AdminShiftsFilterInput, $pagination: PaginationInput) {
    adminShifts (filters: $filters, pagination: $pagination) {
      ${adminShiftFragmentLines}
    }
  }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { props })) as IGetAdminShiftsResponse
}
