import { SERVICE_NAME } from '../../constants'
import { IArtTrackBasic } from '../../models'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'
import { artTrackFragment } from './fragmentsAssets'

export type IFindArtTrackByUuidResponse = IGenericResponse<'findTrackByUuid', IArtTrackBasic>

export async function findArtTrackByUuid(
  uuid: string,
  fragment: string = artTrackFragment
): Promise<IFindArtTrackByUuidResponse> {
  const variables = { uuid }
  const query = `
    query findTrackByUuid ($uuid: String!) {
      findTrackByUuid(uuid:$uuid) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IFindArtTrackByUuidResponse>
}
