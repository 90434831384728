import { ASSET_TYPE, DELIVERY_PLATFORM, SERVICE_NAME } from '../../constants'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

export type IGetUploadUrlProps = {
  assetType: ASSET_TYPE | 'ArtTrack'
  assetFileName: string
  assetUuid?: string
  platform?: DELIVERY_PLATFORM
}

export type IGetUploadUrlObjectResponse = {
  uploadUrl: string
  filePath: string
} | null

export type IGetUploadUrlResponse = IGenericResponse<'getUploadUrl', IGetUploadUrlObjectResponse>

export async function getUploadUrl(props: IGetUploadUrlProps): Promise<IGetUploadUrlResponse> {
  const query = `
    query GetUploadUrl($platform: DeliveryService, $assetType: AssetType!, $assetUuid: String, $assetFileName: String!) {
      getUploadUrl(platform: $platform, assetType: $assetType, assetUuid: $assetUuid, assetFileName: $assetFileName) {
         filePath
        uploadUrl
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetUploadUrlResponse>
}
