import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { CLAIM_TAKE_ACTION_ROLE, SERVICE_NAME } from '../../../constants'

import { claimRequestFragment } from './fragments'

import { IBulkClaimResponse } from './bulkUpdateClaimIssuesStatus'

export type IRequestToTakeActionResponse = IGenericResponse<'requestToTakeAction', IBulkClaimResponse[]>

export type IRequestToTakeActionProps = {
  takeActionRole: CLAIM_TAKE_ACTION_ROLE
  claimIds: string[]
  days?: number
  requestedStatus?: string
  takeActionBy?: string
  takeActionReason?: string
}

export async function requestToTakeAction(
  props: IRequestToTakeActionProps,
  fragment: string = claimRequestFragment
): Promise<IRequestToTakeActionResponse> {
  const query = `
    mutation requestToTakeAction (
        $takeActionRole: ClaimTakeActionRole!,
        $claimIds: [String!]!,
        $days: Float,
        $requestedStatus: String,
        $takeActionBy: String
        $takeActionReason: String
    ) {
      requestToTakeAction (
        takeActionRole: $takeActionRole,
        claimIds: $claimIds,
        days: $days,
        requestedStatus: $requestedStatus,
        takeActionBy: $takeActionBy
        takeActionReason: $takeActionReason
    ) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IRequestToTakeActionResponse>
}
