import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IGetRejectionReasonProps = {
  userUuid: string
}

export type IGetRejectionReasonReturn = IGenericResponse<'getRejectReason', string>

export const getRejectionReason = async (props: IGetRejectionReasonProps): Promise<IGetRejectionReasonReturn> => {
  const query = `
    mutation getMyRejectReason ($userUuid: String) {
      getRejectReason(userUuid: $userUuid)
    }
  `

  return (await clientWrapper(SERVICE_NAME.PAYMENTS, query, props)) as IGetRejectionReasonReturn
}
