import React from 'react'
import { CheckCircle, XmarkCircle } from '@vectopus/atlas-icons-react'
import classNames from 'classnames'

interface IBooleanBadgeProps {
  isTrue?: boolean | null
  className?: string
  outlined?: boolean
  // badge-outlined-no
  // badge-outlined-yes
}

const BooleanBadge: React.FC<IBooleanBadgeProps> = ({ isTrue, className, outlined }) => {
  if (isTrue === null) {
    return <></>
  }
  if (typeof isTrue === 'undefined') {
    return <></>
  }
  return (
    <span
      className={classNames(
        'badge badge-boolean',
        isTrue ? `badge-${outlined ? 'outlined-' : ''}yes` : `badge-${outlined ? 'outlined-' : ''}no`,
        className
      )}
    >
      {isTrue ? <CheckCircle weight="bold" /> : <XmarkCircle weight="bold" />}
      {isTrue ? 'YES' : 'NO'}
    </span>
  )
}

export default BooleanBadge
