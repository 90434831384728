import { lazy } from 'react'
import { ISiteRouteList, RouteType } from './types'

/**
 * LAZY LOAD ALL THE PAGES
 */

const Login = lazy(() => import('../pages/account/Login'))
const Logout = lazy(() => import('../pages/account/Logout'))
const SessionExpired = lazy(() => import('../pages/account/SessionExpired'))
const SongmateAccountWarning = lazy(() => import('../pages/account/SongmateAccountWarning'))
const ForgotPassword = lazy(() => import('../pages/account/ForgotPassword'))
const ResetPassword = lazy(() => import('../pages/account/ChangePassword'))

/**  NOTE 
ALL LOGGED USERS SHOULD BE AT LEAST ADMINS OR MORE
*/

export const commonPublicAccountRoutes: ISiteRouteList = {
  LOGIN: {
    url: '/account/login',
    name: 'Login',
    element: <Login />,
    routeType: RouteType.PUBLIC_ACCOUNT_PAGES,
    key: 'LOGIN',
  },
  LOGOUT: {
    url: '/account/logout',
    name: 'Logout',
    element: <Logout />,
    routeType: RouteType.PUBLIC_ACCOUNT_PAGES,
    key: 'LOGOUT',
  },
  FORGOT_PASSWORD: {
    url: '/account/forgot-password',
    name: 'Forgot Password',
    element: <ForgotPassword />,
    routeType: RouteType.PUBLIC_ACCOUNT_PAGES,
    key: 'FORGOT_PASSWORD',
  },
  SESSION_EXPIRED: {
    url: '/account/session-expired',
    name: 'Session Expired',
    element: <SessionExpired />,
    routeType: RouteType.PUBLIC_ACCOUNT_PAGES,
    key: 'SESSION_EXPIRED',
  },

  SONGMATE_ACCOUNT_WARNING: {
    url: '/account/songmate-account-warning',
    name: 'Session Expired',
    element: <SongmateAccountWarning />,
    routeType: RouteType.PUBLIC_ACCOUNT_PAGES,
    key: 'SONGMATE_ACCOUNT_WARNING',
  },
  RESET_PASSWORD: {
    url: '/account/reset-password',
    name: 'Reset Password',
    element: <ResetPassword />,
    routeType: RouteType.PUBLIC_ACCOUNT_PAGES,
    key: 'RESET_PASSWORD',
  },
}
