import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { artistSearchFragment } from '../fragments'

type IArtistSearchProps = {
  searchPhrase: string
  platforms: string[]
}

export type IArtistSearchResponseBody = {
  platformName: string
  name: string
  url: string
  imageUrl: string
}[]

export type IArtistSearchResponse = IGenericResponse<'Artist_search', IArtistSearchResponseBody>

export async function artistSearch(
  info: IArtistSearchProps,
  fragment: string = artistSearchFragment
): Promise<IArtistSearchResponse> {
  const variables = {
    patch: {
      ...info,
    },
  }
  const query = `
    query artistSearch ($patch: ArtistInput!) {
      Artist_search (artistInput: $patch) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IArtistSearchResponse>
}
