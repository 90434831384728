import { Instance, types } from 'mobx-state-tree'
import { COUNTRIES, STORES } from '../../constants'

export const Analytics = types
  .model({
    date: types.maybeNull(types.string),
    store: types.maybeNull(types.enumeration(Object.values(STORES))),
    countryCode: types.maybeNull(types.string),
    streams: types.maybeNull(types.number),
    offlinePlays: types.maybeNull(types.number),
    totalPlays: types.maybeNull(types.number),
    assetUuid: types.maybeNull(types.string),
    earningEstimate: types.maybeNull(types.number),
    releaseUuid: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    // ? remove this if not needed
    // asset: types.maybeNull(AssetBasic),
    // tracks: types.maybeNull(ArtTrackBasic),
    // releases: types.maybeNull(ReleaseBasic),
    assetTitle: types.maybeNull(types.string),
    assetArtist: types.maybeNull(types.string),
    assetUserUuid: types.maybeNull(types.string),
    thumbnail: types.maybeNull(types.string),
  })
  .views(self => ({
    getCountryName() {
      return COUNTRIES.find(entry => entry.Code === self.countryCode)?.Name ?? 'Unspecified Country'
    },
  }))
  .actions(self => ({
    setTitle(title: string) {
      self.assetTitle = title
    },
    setArtist(artist: string) {
      self.assetArtist = artist
    },
    setUserUuid(uuid: string) {
      self.assetUserUuid = uuid
    },
    setReleaseUuid(uuid: string) {
      self.releaseUuid = uuid
    },
    setThumbnail(thumbnail: string) {
      self.thumbnail = thumbnail
    },
  }))

export type IAnalytics = Instance<typeof Analytics>
