import { CLIENT_TIER, SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type ISetClientTierProps = {
  userUuid: string
  clientTier: CLIENT_TIER | null
}

export type ISetClientTireResponse = IGenericResponse<'setClientTier', boolean>

export async function setClientTier({ userUuid, clientTier }: ISetClientTierProps): Promise<ISetClientTireResponse> {
  const query = `
    mutation setClientTier ($userUuid: String!, $clientTier: ClientTier) {
      setClientTier (userUuid: $userUuid, clientTier: $clientTier)
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { clientTier, userUuid })) as ISetClientTireResponse
}
