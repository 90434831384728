import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IUpdatePitchesAsLoggedInProps {
  pitchUuids: string[]
  genres?: string[]
  toChannels?: string[]
}

export type IUpdatePitchesAsLoggedInResponse = IGenericResponse<'updatePitchesAsLoggedIn', boolean>

export async function updatePitchesAsLoggedIn(
  props: IUpdatePitchesAsLoggedInProps
): Promise<IUpdatePitchesAsLoggedInResponse> {
  const variables = {
    updatePitchesData: props,
  }
  const query = `
   mutation updatePitchesAsLoggedIn($updatePitchesData: GenresChannelsPitchInput!) {
    updatePitchesAsLoggedIn(updatePitchesData: $updatePitchesData)
  }
  `

  return clientWrapper(SERVICE_NAME.ASSETS, query, variables) as Promise<IUpdatePitchesAsLoggedInResponse>
}
