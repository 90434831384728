import { IGenericResponse } from '../../api.types'

import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { extendedUserFragment } from '../fragments'

export interface IEditUserNameAddressProps {
  data: {
    firstName?: string
    lastName?: string
    displayName?: string
    billingName?: string
    addressLine1?: string
    addressLine2?: string
    postCode?: string
    countryCode?: string
  }
}

export type IEditUserNameAddressResponse = IGenericResponse<'editUserNameAddress', IUserBasic>

export async function editUserNameAddress(
  props: IEditUserNameAddressProps,
  fragment: string = extendedUserFragment
): Promise<IEditUserNameAddressResponse> {
  const query = `
    mutation editUserNameAddress (  $data: EditUserNameAddress!) {
      editUserNameAddress (  data: $data) {
        ${fragment}
      }
    }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, props) as Promise<IEditUserNameAddressResponse>
}
