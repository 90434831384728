import React from 'react'

import { SvgIconProps } from './iconProps'

const IconPandora: React.FC<SvgIconProps> = ({ className = '', size = '1em', fill = 'none' }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3V29H11.9985C12.3113 29 12.6113 28.8762 12.8325 28.6557C13.0537 28.4353 13.178 28.1363 13.178 27.8246V22.8304H14.9303C23.4964 22.8304 27 18.0182 27 12.3914C26.9989 5.44508 21.7027 3 16.7185 3H5Z"
      fill="#009DF0"
    />
  </svg>
)

export default IconPandora
